import BACK_ARROW from "./svg/back_arrow.svg";
import DELETE_IC from "./svg/delete_ic.svg";
import CLOSE_IC_18 from "./svg/close_ic_18.svg";
import OFFER_TAG_IC from "./svg/local_offer_ic.svg";
import DOWN_ARROW_IC from "./svg/down_arror_ic.svg";
import BOX_IMG from "./svg/box_img.svg";
import CLOSE_BLACK_IC from "./svg/close_black_ic.svg";
import CHECK_CIRCLE_IC from "./svg/check_circle_ic.svg";
import FB_IC from "./svg/fb_ic.svg";
import YOUTUBE_IC from "./svg/youtube_ic.svg";
import INSTAGRAM_IC from "./svg/insta_ic.svg";
import X_IC from "./svg/x_ic.svg";
import APP_STORE_IC from "./svg/app_store_ic.png";
import PLAY_STORE_IC from "./svg/play_store_ic.png";
import CONTACT_US_BG from "./svg/contact_us_bg.png";
import PDF_IC from "./svg/pdf_ic.svg";
import CIRCLE_CLOSE_IC from "./svg/circle_close.svg";
import SEARCH_IC from "./svg/search_ic.svg";
import LIKE__IC from "./svg/favorite_ic.svg";
import PERSON_IC from "./svg/person_ic.svg";
import BAG_IC from "./svg/shopping_bag_ic.svg";
import MENU_IC from "./svg/menu_ic.svg";
import CLOSE_IC from "./svg/close_ic.svg";
import ARROW_FORWARD_IC from "./svg/arrow_forward_ic.svg";
import ARROW_BACK_IC from "./svg/arrow_back_ic.svg";
import ARROW_KEYWORD_UP from "./svg/keyboard_arrow_up_ic.svg";
import ARROW_KEYWORD_DOWN from "./svg/keyboard_arrow_down_ic.svg";
import ARROW_KEYWORD_LEFT from "./svg/keyboard_arrow_left_ic.svg";
import ARROW_KEYWORD_RIGHT from "./svg/keyboard_arrow_right_ic.svg";
import IOS_SHARE_IC from "./svg/ios_share_ic.svg";
import UPLOAD_IC from "./svg/upload_ic.svg";
import DEPLOY_IC from "./svg/deployed_ic.svg";
import PACE_IC from "./svg/pace_ic.svg";
import ADD_HOME_IC from "./svg/add_home_ic.svg";
import CALL_IC from "./svg/call_ic.svg";
import MAIL_IC from "./svg/mail_ic.svg";
import ARROW_BACK_BLACK_IC from "./svg/arrow_back_black_ic.svg";
import CONTENT_COPY_IC from "./svg/content_copy_ic.svg";
import ARROW_FORWARD_BLACK_IC from "./svg/arrow_forward_black_ic.svg";
import ADD_IC from "./svg/add_ic.svg";
import SCHEDULE_IC from "./svg/schedule_ic.svg";
import CREDIT_CARD_IC from "./svg/credit_card_ic.svg";
import CURRENCY_IC from "./svg/currency_rupee_ic.svg";
import RETAILER_BANNER from "./svg/retailer_banner.png";
import MOBILE_RETAILER_BANNER from "./svg/mobile_retailer_baner.png";
import LIKED_IC from "./svg/like_ic.svg";
import UNLIKE_IC from "./svg/favorite_ic.svg";
import NETRE_LOGO_PATTERN from "./svg/netre_logo_pattern.svg";
import PAYMENT_FAIL_ICON from "./svg/payment_fail_icon.png";
import PAGE_NOT_FOUND from "./svg/404errorwires.jpg";
import FN_WHATSAPP_IC from "./svg/fn-whatsapp.svg";
import FN_FB_IC from "./svg/facebook.svg";
import FN_X_IC from "./svg/fn-twitter.svg";
import COMING_SOON_BANNER from "./jpg/COMING SOON LANDING PAGE.jpg";
import EDIT_IC from "./svg/EDIT_IC.svg"
import PLUSHVIE_LOGO_WHITE from "./png/Plushvie Logo White-07.png";

//daga images

import mainBgImg from "./png/bridalblock_mainbg.png";
import kamarbandhImg from "./png/bridalblock_kamarbandh.png";
import bangalesImg from "./png/bridalblock_bangales.png";
import earingsImg from "./png/bridalblock_earrings.png";
import necklaceImg from "./png/bridalblock_necklace.png";
import curatedBlockImg1 from "./png/curatedblock_img-1.png";
import curatedBlockImg2 from "./png/curatedblock_img-2.png";
import curatedBlockImg3 from "./png/curatedblock_img-3.png";
import curatedBlockImg4 from "./png/curatedblock_img-4.png";
import locationIcon from "./svg/location.svg";
import videoShoppingIcon from "./svg/video-shopping.svg";
import contactUsIcon from "./svg/contact-us.svg";
import DGlogo from "./svg/DGLogo.svg";
import RightArrow from "./svg/R_arrow.svg";
import plushvie_logo from "./svg/Plushvie Logo.svg";
import instagram from "./svg/instagram_footer.svg";
import facebook from "./svg/facebook_footer.svg";
import twitter from "./svg/twitter_footer.svg";
import youtube from "./svg/youtube_footer.svg";
import cart_icon from "./svg/cart_icon.svg";
import wishlist_icon from "./svg/wishlist_icon.svg";
import search_icon from "./svg/search_icon.svg";
import homeBannerDesktop from "./jpg/home_banner.jpg";
import shopByCategoryBlockImg1 from "./jpg/category-img-1.jpg";
import shopByCategoryBlockImg2 from "./jpg/category-img-2.jpg";
import shopByCategoryBlockImg3 from "./jpg/category-img-3.jpg";
import shopByCategoryBlockImg4 from "./jpg/category-img-4.jpg";
import whatpeoplesays_1 from "./png/whatpeoplesays_1.png";
import whatpeoplesays_2 from "./png/whatpeoplesays_2.png";
import whatpeoplesays_3 from "./png/whatpeoplesays_3.png";
import whatpeoplesays_4 from "./png/whatpeoplesays_4.png";
import shopByCollectionImg1 from "./jpg/collection-img-1.jpg";
import shopByCollectionImg2 from "./jpg/collection-img-2.jpg";
import shopByCollectionImg3 from "./jpg/collection-img-3.jpg";
import shopByCollectionImg4 from "./jpg/collection-img-4.jpg";
import userIcon from "./svg/user_icon.svg";
import daga_viedo_poster from "./png/poster_daga_video.png";
import daga_video from "./video/daga_video.mp4";
import comingsoonbanner from "./png/home_banner.png";
import comingsoonImg from "./png/coming-soon-daga.png";
import followusImg from "./png/follow-us-on-daga.png";
import tryonvideo from "./video/Daga Gems Tryon Video.mp4";
import plushvie_logo_gold from "./png/Plushvie Logo_gold.png";
import plushvie_logo_blue from "./png/Plushvie Logo Blue.png";
import MEMBER_1 from "./jpg/kamal_chand_jain.jpg";
import MEMBER_2 from "./jpg/chairmain_naresh_chand.jpg";
import MEMBER_3 from "./jpg/dhilip.jpg";
import MEMBER_4 from "./jpg/vishal.jpg";
import MEMBER_5 from "./jpg/kunal.jpg";
import MEMBER_6 from "./jpg/shreyans.jpg";
import ABOUT_US_1 from "./png/about-us-1.png";
import ABOUT_US_2 from "./png/about-us-2.png";
import ABOUT_US_3 from "./png/about-us-3.png";
import ABOUT_US_4 from "./png/about-us-4.png";
import ABOUT_US_BANNER from "./jpg/about us.jpg";
import ABOUT_US_BANNER_MOBILE from "./jpg/about us mobile.jpg";
import ABOUT_US_BANNER_2 from "./png/about-us-vision-misson.png";
import dagacomingsoon from "./jpg/coming soon banner.jpg";
import menu_icon from "./svg/menu_icon.svg";
import close_square_icon from "./svg/close_square_icon.svg";
import dagacontactus_icon from "./svg/contact-us-daga.svg";
import linkedin_icon from "./svg/linkedin_icon.svg";

const MOBILE_NUMBER = "9342419919";
const EMAIL_ADDRESS = "info@dagagems.com";
const ADDRESS_LINK = "https://g.co/kgs/JVqhKBx";
const ADDRESS_TEXT ="No. 934, 3rd Floor, Shop No 5, RJ's Sree Purandara Bhavan, Sree Dharamaraya swamy temple street, Dharmaraya Swamy Temple Rd, Nagarathpete, Bengaluru, Karnataka 560002"
const INSTAGRAM_LINK= "https://www.instagram.com/daga_gems_/";
const FACEBOOK_LINK = "https://www.facebook.com/dagagemstvm";
const LINKEDIN_LINK = "https://www.linkedin.com/company/daga-gems-and-jewellery-pvt-ltd/";
const YOUTUBE_LINK = "";
const TWITTER_LINK = "";

export {
  MOBILE_NUMBER,
  EMAIL_ADDRESS,
  ADDRESS_LINK,
  ADDRESS_TEXT,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  YOUTUBE_LINK,
  TWITTER_LINK,
  LINKEDIN_LINK,
  BACK_ARROW,
  DELETE_IC,
  OFFER_TAG_IC,
  CLOSE_IC_18,
  DOWN_ARROW_IC,
  BOX_IMG,
  CLOSE_BLACK_IC,
  CHECK_CIRCLE_IC,
  FB_IC,
  YOUTUBE_IC,
  INSTAGRAM_IC,
  X_IC,
  APP_STORE_IC,
  PLAY_STORE_IC,
  CONTACT_US_BG,
  PDF_IC,
  CIRCLE_CLOSE_IC,
  SEARCH_IC,
  LIKE__IC,
  PERSON_IC,
  BAG_IC,
  MENU_IC,
  CLOSE_IC,
  ARROW_BACK_IC,
  ARROW_FORWARD_IC,
  ARROW_KEYWORD_DOWN,
  ARROW_KEYWORD_UP,
  IOS_SHARE_IC,
  ARROW_KEYWORD_LEFT,
  ARROW_KEYWORD_RIGHT,
  UPLOAD_IC,
  DEPLOY_IC,
  PACE_IC,
  ADD_HOME_IC,
  CALL_IC,
  MAIL_IC,
  ARROW_BACK_BLACK_IC,
  CONTENT_COPY_IC,
  ARROW_FORWARD_BLACK_IC,
  ADD_IC,
  SCHEDULE_IC,
  CREDIT_CARD_IC,
  CURRENCY_IC,
  RETAILER_BANNER,
  MOBILE_RETAILER_BANNER,
  LIKED_IC,
  UNLIKE_IC,
  NETRE_LOGO_PATTERN,
  PAYMENT_FAIL_ICON,
  PAGE_NOT_FOUND,
  FN_WHATSAPP_IC,
  FN_FB_IC,
  FN_X_IC,
  EDIT_IC,
  COMING_SOON_BANNER,
  PLUSHVIE_LOGO_WHITE,
  // daga images
  mainBgImg,
  kamarbandhImg,
  bangalesImg,
  earingsImg,
  necklaceImg,
  curatedBlockImg1,
  curatedBlockImg2,
  curatedBlockImg3,
  curatedBlockImg4,
  locationIcon,
  videoShoppingIcon,
  contactUsIcon,
  DGlogo,
  RightArrow,
  homeBannerDesktop,
  shopByCategoryBlockImg1,
  shopByCategoryBlockImg2,
  shopByCategoryBlockImg3,
  shopByCategoryBlockImg4,
  whatpeoplesays_1,
  whatpeoplesays_2,
  whatpeoplesays_3,
  whatpeoplesays_4,
  plushvie_logo,
  shopByCollectionImg1,
  shopByCollectionImg2,
  shopByCollectionImg3,
  shopByCollectionImg4,
  instagram,
  facebook,
  twitter,
  youtube,
  cart_icon,
  wishlist_icon,
  search_icon,
  userIcon,
  daga_video,
  daga_viedo_poster,
  comingsoonbanner,
  comingsoonImg,
  followusImg,
  tryonvideo,
  plushvie_logo_gold,
  plushvie_logo_blue,
  dagacomingsoon,
  MEMBER_1,
  MEMBER_2,
  MEMBER_3,
  MEMBER_4,
  MEMBER_5,
  MEMBER_6,
  ABOUT_US_1,
  ABOUT_US_2,
  ABOUT_US_3,
  ABOUT_US_4,
  ABOUT_US_BANNER,
  ABOUT_US_BANNER_MOBILE,
  ABOUT_US_BANNER_2,
  menu_icon,
  close_square_icon,
  dagacontactus_icon,
  linkedin_icon,

};
