import React, { useLayoutEffect, useRef } from "react";
import {
  ABOUT_US_1,
  ABOUT_US_2,
  ABOUT_US_3,
  ABOUT_US_4,
  ABOUT_US_BANNER,
  ABOUT_US_BANNER_2,
  ABOUT_US_BANNER_MOBILE,
  homeBannerDesktop,
  mainBgImg,
  MEMBER_1,
  MEMBER_2,
  MEMBER_3,
  MEMBER_4,
  MEMBER_5,
  MEMBER_6,
} from "../../../assets";
import { Breakpoint } from "react-socks";

const AboutUs = () => {
  const imgRef = useRef();  
  useLayoutEffect(() => {
      if(imgRef.current){
        imgRef.current.scrollIntoView({ top:200, behavior: "smooth" });
      }
  }, [imgRef.current]);

  return (
    <div>
      <div className="w-full h-[80vh] object-cover" ref={imgRef}>
        <Breakpoint large up className="w-full h-full object-cover">
        <img
          src={ABOUT_US_BANNER}
          className="h-full w-full object-cover object-bottom"
          alt="Banner About Us"
        />
        </Breakpoint>
        <Breakpoint medium down className="w-full h-full object-cover">
        <img
          src={ABOUT_US_BANNER_MOBILE}
          className="h-full w-full object-cover object-top"
          alt="Banner About Us"
        />
        </Breakpoint>
      </div>
      <div className="text_light_2 relative p-8 sm:p-24">
        <div className="horizontail-line-top h-[1px] w-full border-t-[0.4px] border-t-primary absolute top:8 sm:top-24 left-0 "></div>
        <div className="vertical-line-left h-full w-[1px] border-l-[0.4px] border-l-primary absolute top-0 left-8 sm:left-24 "></div>
        <div className="vertical-line-rigth h-full w-[1px] border-l-[0.4px] border-l-primary absolute top-0 right-8 sm:right-24 "></div>
        <div className="text-primary mt-24 text-2xl sm:text-4xl text-center leading-9 sm:leading-[3.5rem]">
          BUILD, MAKE AND
          <br />
          CRAFTSMANSHIP DESIGN
        </div>
        <div className="text-black px-4 sm:px-[15%] my-12 text-xl text-center leading-9 whitespace-pre-wrap whitespace-break-spaces">
        DAGA Gems and jewellery is an institution that believes in satisfying every jewellery requirement of any woman,through their traditional yet contemporary craftsmanship, originality,customized designs and art.

With above 35 years of experience in customizing jewellery.
        </div>
        <div className="inline-flex flex-col sm:flex-row w-full pb-10 relative z-[2]">
          <div className="w-full sm:w-[50%] inline-flex gap-10 pr-0 sm:pr-10">
            <div className="w-[50%] flex flex-col justify-end">
              <div className=" aspect-[4/5] w-full">
                <img
                  src={ABOUT_US_1}
                  alt="banner"
                  className="h-full w-full object-cover"
                />
              </div>
            </div>
            <div className="w-[50%] pb-0 sm:pb-10">
              <div className=" w-full aspect-[2/3]">
                <img
                  src={ABOUT_US_2}
                  alt="banner"
                  className="h-full w-full object-cover"
                />
              </div>
            </div>
          </div>
          <div className="w-full sm:w-[50%] inline-flex gap-10">
            <div className="w-[60%] flex flex-col justify-center">
              <div className=" aspect-[5/4] w-full">
                <img
                  src={ABOUT_US_3}
                  alt="banner"
                  className="h-full w-full object-cover"
                />
              </div>
            </div>
            <div className="w-[40%] pt-6">
              <div className=" aspect-[2/3] w-full">
                <img
                  src={ABOUT_US_4}
                  alt="banner"
                  className="h-full w-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-bg_1 h-[25%] w-full absolute bottom-0 left-0 z-[1]"></div>
      </div>
      {/* vission mission card */}
      <div className="bg-bg_1 p-8 sm:p-14 relative z-[1] pt-24">
        <div className="bg-primary rounded-xl p-10 inline-flex flex-col lg:flex-row w-full">
          <div className="w-full lg:w-[40%]  flex justify-center">
            {/* <div className="rounded-xl border p-[1px] border-dashed border-bg_1 aspect-[2/3] w-[60%] scale-125 -rotate-6 -translate-y-[20%]">
              <div className="h-full w-full rounded-xl">
                <img
                  src={ABOUT_US_BANNER_2}
                  alt="banner"
                  className="h-full w-full object-cover rounded-xl"
                />
              </div>
            </div> */}
             <div className="rounded-xl p-[1px] aspect-[2/3] w-[90%] sm:w-[60%] scale-125 -translate-y-[20%]">
              <div className="h-full w-full rounded-xl">
                <img
                  src={ABOUT_US_BANNER_2}
                  alt="banner"
                  className="h-full w-full object-contain rounded-xl"
                />
              </div>
            </div> 
          </div>
          <div className="w-full lg:w-[60%] ps-0  sm:ps-[10%] flex flex-col justify-around">
            <div>
              <div className="text-bg_1 opacity-50 text-2xl my-2">
                OUR MISSION
              </div>
              <div className="opacity-50 border border-t-bg_1 h-[1px] w-[200px] ms-10 mt-2 mb-3"></div>
              <div className="text-bg_1 ps-10">
                Our mission is to offer high-quality, traditional temple jewelry
                that embodies the essence of Indian craftsmanship. We are
                committed to fostering long-term relationships with our
                customers by consistently delivering unparalleled products,
                excellent customer service, and a dedication to preserving and
                promoting the cultural significance of our jewelry.
              </div>
            </div>
            <div>
              <div className="text-bg_1 opacity-50 text-2xl my-2">
                OUR VISION
              </div>
              <div className="opacity-50 border border-t-bg_1 h-[1px] w-[200px] ms-10 mt-2 mb-3"></div>
              <div className="text-bg_1 ps-10">
                To be the leading wholesaler of authentic temple jewelry,
                providing exquisite craftsmanship that celebrates the rich
                cultural heritage of India, and delivering exceptional value to
                our customers worldwide.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 py-32 px-8">
        <div className=""></div>
        <div className="-translate-y-[25%]">
          <MemberCard
            name={"Kamal Chand Jain"}
            position={"Founder"}
            img={MEMBER_1}
          />
        </div>
        <div className=""></div>
        <div className="-translate-y-[25%]">
          <MemberCard
            name={"Naresh Chand"}
            position={"Chairman"}
            img={MEMBER_2}
          />
        </div>
        <div className="">
          <div className="flex flex-col justify-center items-center gap-4 relative top-[100%] -translate-y-1/2">
            <div className="bg-primary text-bg_1 px-7 py-1 text-base lg:text-xl rounded-3xl inline-block w-fit">
              6
            </div>
            <div className="text-center text-primary lg:text-2xl">
              Our Team Members
            </div>
          </div>
          <div className="h-full pb-16 -translate-y-1/2 flex justify-center">
            <div className=" h-full border-l border-text_dark_4"></div>
          </div>
        </div>
        <div className="-translate-y-[25%]">
          <MemberCard
            name={"Dhilip Chand"}
            position={"Director"}
            img={MEMBER_3}
          />
        </div>
        <div className="translate-y-[25%]">
          <MemberCard
            name={"Vishal Chand"}
            position={"Managing Director"}
            img={MEMBER_4}
          />
        </div>
        <div className="py-8 flex justify-center">
          <div className="translate-y-[25%] h-full border-l border-text_dark_4"></div>
        </div>
        <div className="translate-y-[25%]">
          <MemberCard
            name={"Kunal"}
            position={"Managing Director"}
            img={MEMBER_5}
          />
        </div>
        <div className=""></div>
        <div className="translate-y-[25%]">
          <MemberCard
            name={"Shreyans Daga"}
            position={"Managing Director"}
            img={MEMBER_6}
          />
        </div>
      </div>
    </div>
  );
};
export default AboutUs;

const MemberCard = ({ img, name, position }) => {
  return (
    <div className=" ">
      <div className="text-center">
        <div className="my-1 text-sm lg:text-2xl">{name}</div>
        <div className="my-1 text-[10px] lg:text-sm text-text_dark_4">{position}</div>
      </div>
      <div className="my-1 lg:w-full sm:h-[150px] flex justify-center">
        <img
          src={img}
          alt="
        "
          className=" bg-white h-full aspect-square border border-primary rounded-full object-cover object-top"
        />
      </div>
    </div>
  );
};
