import { homeBannerDesktop } from "../../../assets";

const MainBanner = () => {
    return (
      <div className="w-full h-dvh object-cover">
        <img
          src={homeBannerDesktop}
          alt="Bridal Jewelry"
          className="h-full w-full object-cover "
        />
      </div>
    );
  };
  export default MainBanner;