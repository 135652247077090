import React, { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'

function DagaProductCard({
  img,
  price,
  title
}) {
  return (
    <div className='bg-white p-4'>
        <div className='aspect-square object-cover'>
          {img?<img className='h-full w-full object-contain' src={img} alt="product" />
            :<Skeleton className='h-full'/>}
        </div>
        <div className='my-1 mt-3 text-text_dark_2 text-base'>{price?<>&#8377;&nbsp;{price}</>:<Skeleton className='h-full'/>}</div>
        <div className='my-1 mb-3 text-text_dark_3 text-sm w-[calc(100%)] overflow-hidden whitespace-nowrap text-ellipsis text capitalize'>{title?<>{title}</>:<Skeleton className='h-full'/>}</div>
    </div>
  )
}

export default DagaProductCard