import React, { useEffect } from "react";
import MainBanner from "./DagaHome/MainBanner";
import ShopByCategoryBlock from "./DagaHome/ShopByCategoryBlock";
import CuratedBlock from "./DagaHome/CuratedBlock";
import BridalBlock from "./DagaHome/BridalBlock";
import NewArrivalsBlock from "./DagaHome/NewArrivalsBlock";
import ShopByCollectionBlock from "./DagaHome/ShopByCollectionBlock";
import TopSellingBlock from "./DagaHome/TopSellingBlock";
import WhatPeopleSaysBlock from "./DagaHome/WhatPeopleSaysBlock";
import GetInTouch from "./DagaHome/GetInTouch";
import ComingSoonBlock from "./DagaHome/ComingSoonBlock";
import { useLocation } from "react-router-dom";
import TryonVideoBlock from './DagaHome/TryonVideoBlock'

function DagaHome() {
  const { hash } = useLocation();
  const comingSoonRef = React.useRef(null);
  useEffect(() => {
    if (hash === "#coming-soon") {
      comingSoonRef?.current.scrollIntoView({ top:0, behavior: "smooth" });
    }
  }, [hash]);
  return (
    <div>
      <MainBanner />
      <ShopByCategoryBlock />
        <TryonVideoBlock />
      <CuratedBlock />
      <ShopByCollectionBlock />
      <div id="coming-soon" ref={comingSoonRef}>
      <ComingSoonBlock />
      </div>
      {/* <BridalBlock/> */}
      {/* <NewArrivalsBlock/> */}
      {/* <TopSellingBlock/> */}
      {/* <WhatPeopleSaysBlock/> */}
    </div>
  );
}

export default DagaHome;
