import React from "react";
import {
  curatedBlockImg1,
  curatedBlockImg2,
  curatedBlockImg3,
  curatedBlockImg4,
  RightArrow,
} from "../../../assets";

const CuratedBlock = () => {
  return (
    <div className="p-4 pb-16 bg-[#FCF5F0]">
      <div className="text-primary text-4xl text-center font-[Cormorant] my-2 mt-10">
        SPECIALLY CURATED FOR YOU
      </div>
      <div className="text-text_dark_2 text-xl text-center font-[Cormorant] my-2 mb-10">
        Embrace the spirit of season with our timeless pieces
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div className="bg-gradient-to-tr from-white via-white to-[#FCF5F0] rounded-xl group">
          <div className="relative">
            <img
              src={curatedBlockImg3}
              alt="bangale-1"
              className="w-full h-auto pt-8 px-6"
            />
            <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center mb-2">
              <div className="bg-white bg-opacity-40 backdrop-blur-md rounded-full px-4 py-[3px]">
                <p className="text-white text-[8px] lg:text-base">
                  250+ Designs
                </p>
              </div>
            </div>
          </div>
          <div className="pb-4 pt-4 flex flex-row flex-grow justify-center items-center mt-8">
            <button className="text-[#1C1919] py-2 pl-2 pr-2 text-[10px] sm:pl-8 sm:pr-8 sm:text-[16px] md:pl-4 md:pr-4 md:text-[10px] lg:pl-6 lg:pr-6 lg:text-[13px] xl:text-[16px]  border-[1px] border-[#1C1919] rounded-full font-medium transition-all duration-500 lg:group-hover:pr-16 lg:group-hover:bg-primary lg:group-hover:text-[#FCF5F0] ">
              MANJARI JWELLERY
            </button>
            <img
              src={RightArrow}
              className="p-1 h-[32px] w-[32px] sm:h-[41px] sm:w-[41px] md:h-[32px] md:w-[32px] lg:h-[38px] lg:w-[38px] xl:h-[41px] xl:w-[41px] border-[1px] border-[#1C1919] rounded-full text-sm font-medium transition-all duration-500 lg:group-hover:translate-x-[-40px] lg:group-hover:bg-[#FCF5F0] lg:group-hover:text-primary lg:group-hover:scale-90"
            />
          </div>
        </div>
        <div className="bg-gradient-to-tr from-white via-white to-[#FCF5F0] rounded-xl group">
          <div className="relative">
            <img
              src={curatedBlockImg4}
              alt="bangale-1"
              className="w-full h-auto pt-8 px-6"
            />
            <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center mb-2">
              <div className="bg-white bg-opacity-40 backdrop-blur-md rounded-full px-4 py-[3px]">
                <p className="text-white text-[8px] lg:text-base">
                  175+ Designs
                </p>
              </div>
            </div>
          </div>
          <div className=" pb-4 pt-4 flex flex-row flex-grow justify-center items-center mt-8">
            <button className="text-[#1C1919] py-2 pl-2 pr-2 text-[10px] sm:pl-8 sm:pr-8 sm:text-[16px] md:pl-4 md:pr-4 md:text-[10px] lg:pl-6 lg:pr-6 lg:text-[13px] xl:text-[16px]  border-[1px] border-[#1C1919] rounded-full font-medium transition-all duration-500 lg:group-hover:pr-16 lg:group-hover:bg-primary lg:group-hover:text-[#FCF5F0]">
              NUPUR JWELLERY
            </button>
            <img
              src={RightArrow}
              className="p-1 h-[32px] w-[32px] sm:h-[41px] sm:w-[41px] md:h-[32px] md:w-[32px] lg:h-[38px] lg:w-[38px] xl:h-[41px] xl:w-[41px] border-[1px] border-[#1C1919] rounded-full text-sm font-medium transition-all duration-500 lg:group-hover:translate-x-[-40px] lg:group-hover:bg-[#FCF5F0] lg:group-hover:text-primary lg:group-hover:scale-90"
            />
          </div>
        </div>
        <div className="bg-gradient-to-tr from-white via-white to-[#FCF5F0] rounded-xl group">
          <div className="relative">
            <img
              src={curatedBlockImg2}
              alt="bangale-1"
              className="w-full h-auto pt-8 px-6"
            />
            <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center mb-2">
              <div className="bg-white bg-opacity-40 backdrop-blur-md rounded-full px-4 py-[3px]">
                <p className="text-white text-[8px] lg:text-base">
                  120+ Designs
                </p>
              </div>
            </div>
          </div>
          <div className=" pb-4 pt-4 flex flex-row flex-grow justify-center items-center mt-8">
            <button className="text-[#1C1919] py-2 pl-2 pr-2 text-[10px] sm:pl-8 sm:pr-8 sm:text-[16px] md:pl-4 md:pr-4 md:text-[10px] lg:pl-6 lg:pr-6 lg:text-[13px] xl:text-[16px]  border-[1px] border-[#1C1919] rounded-full font-medium transition-all duration-500 lg:group-hover:pr-16 lg:group-hover:bg-primary lg:group-hover:text-[#FCF5F0]">
              GOLD JWELLERY
            </button>
            <img
              src={RightArrow}
              className="p-1 h-[32px] w-[32px] sm:h-[41px] sm:w-[41px] md:h-[32px] md:w-[32px] lg:h-[38px] lg:w-[38px] xl:h-[41px] xl:w-[41px] border-[1px] border-[#1C1919] rounded-full text-sm font-medium transition-all duration-500 lg:group-hover:translate-x-[-40px] lg:group-hover:bg-[#FCF5F0] lg:group-hover:text-primary lg:group-hover:scale-90"
            />
          </div>
        </div>
        <div className="bg-gradient-to-tr from-[white] via-white to-[#FCF5F0] rounded-xl  group">
          <div className="relative">
            <img
              src={curatedBlockImg1}
              alt="bangale-1"
              className="w-full h-auto pt-8 px-6"
            />
            <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center mb-2">
              <div className="bg-white bg-opacity-40 backdrop-blur-md rounded-full px-4 py-[3px]">
                <p className="text-white text-[8px] lg:text-base">
                  200+ Designs
                </p>
              </div>
            </div>
          </div>
          <div className="pb-4 pt-4 flex flex-row flex-grow justify-center items-center mt-8">
            <button className="text-[#1C1919] py-2 pl-2 pr-2 text-[10px] sm:pl-8 sm:pr-8 sm:text-[16px] md:pl-4 md:pr-4 md:text-[10px] lg:pl-6 lg:pr-6 lg:text-[13px] xl:text-[16px]  border-[1px] border-[#1C1919] rounded-full font-medium transition-all duration-500 lg:group-hover:pr-16 lg:group-hover:bg-primary lg:group-hover:text-[#FCF5F0]">
              ANTIQUE CHAIN
            </button>
            <img
              src={RightArrow}
              className="p-1 h-[32px] w-[32px] sm:h-[41px] sm:w-[41px] md:h-[32px] md:w-[32px] lg:h-[38px] lg:w-[38px] xl:h-[41px] xl:w-[41px] border-[1px] border-[#1C1919] rounded-full text-sm font-medium transition-all duration-500 lg:group-hover:translate-x-[-40px] lg:group-hover:bg-[#FCF5F0] lg:group-hover:text-primary lg:group-hover:scale-90"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CuratedBlock;
