import React, { useState } from "react";
import "./Sidebar.scss";
import { CLOSE_BLACK_IC } from "../../assets";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = ({ sidebarToggle, setSidebarToggle, headerLabelsData }) => {
  const navigate = useNavigate();
  const wishlistData = useSelector((state) => state.wishlist.data);
  const [showBrowse, setShowBrowse] = useState(false);

  return (
    <div className="sidebar_main z-999999">
      <div className="container-fluid">
        <div className={`sidebar ${sidebarToggle ? "active" : ""}`}>
          <div className="sidebar_body">
            <div className="logo_div">
              <img src="/Netre-logo.svg" alt="" className="logo" />
              <img
                src={CLOSE_BLACK_IC}
                alt=""
                className="close"
                onClick={() => setSidebarToggle(false)}
              />
            </div>
            <div
              onClick={() => {
                navigate(`/favourites`);
                setSidebarToggle(false);
                document.body.style.overflow = "auto";
                document.body.style.height = "auto";
              }}
              className="items mont-regular"
            >
              Wish list ({" "}
              {wishlistData?.length > 9 ? "9+" : wishlistData?.length} )
            </div>
            <div
              onClick={() => {
                navigate(`/aboutus`);
                setSidebarToggle(false);
                document.body.style.overflow = "auto";
                document.body.style.height = "auto";
              }}
              className="items mont-regular color2"
            >
              About Us
            </div>
            <div
              className="w-full h-fit relative"
              onMouseLeave={() => setShowBrowse(false)}
              onMouseEnter={() => setShowBrowse(true)}
            >
              <div className="items mont-regular">Browse Collection</div>
              {showBrowse && (
                <div className="cursor-pointer bg-white flex flex-col px-8 pb-4">
                  {[
                    { title: "Optical Frames", id: "668540397db56839146d6f82" },
                    { title: "Sunglasses", id: "668540567db56839146d6f8e" },
                    {
                      title: "Reading Glasses",
                      id: "668540397db56839146d6f82",
                    },
                  ]?.map((item, index) => {
                    return (
                      <span
                        onClick={() => {
                          navigate(`/categories/${item?.id}`);
                          setShowBrowse(false);
                          setSidebarToggle(false);
                          document.body.style.overflow = "auto";
                          document.body.style.height = "auto";
                        }}
                        className="cursor-pointer text-nowrap my-2"
                      >
                        {item?.title}
                      </span>
                    );
                  })}
                </div>
              )}
            </div>

            {/* {headerLabelsData?.labels?.homepage_category_to_show?.map(
              (item, index) => {
                return (
                  <div
                    onClick={() => {
                      navigate(`/categories/${item?.id}`);
                      setSidebarToggle(false);
                      document.body.style.overflow = "auto";
                      document.body.style.height = "auto";
                    }}
                    key={index}
                    className="items mont-regular"
                  >
                    {item?.name}
                  </div>
                );
              }
            )} */}
            <div
              onClick={() => {
                navigate(`/search`);
                setSidebarToggle(false);
                document.body.style.overflow = "auto";
                document.body.style.height = "auto";
              }}
              className="items mont-regular color-2"
            >
              Offers %
            </div>
            <div
              onClick={() => {
                navigate(`/become-retailer`);
                setSidebarToggle(false);
                document.body.style.overflow = "auto";
                document.body.style.height = "auto";
              }}
              className="items mont-regular color2"
            >
              For Business
            </div>
          </div>
        </div>
        <div
          className={`sidebar_overlay ${
            sidebarToggle === true ? "active" : ""
          }`}
          onClick={() => {
            setSidebarToggle(false);
            document.body.style.overflow = "auto";
            document.body.style.height = "auto";
          }}
        ></div>
      </div>
    </div>
  );
};

export default Sidebar;
