import "./favorite.scss";
import "../Category/Category.scss";
import Breadcrumbs from "../../Utilities/BreadCrumbs/Breadcrumbs";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ProductCard } from "../Category/Category";
import { fakeData } from "../../../json";
import Skeleton from "react-loading-skeleton";

export default function Favorites(props) {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "My Favorite", link: "/favourites" },
  ];
  const wishlistData = useSelector((state) => state.wishlist.data);
  const count = wishlistData.length;

  return (
    <div className="Favorites_Container">
      <div className="w-full px-5">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </div>
      <h2 className="main_title">My Favourites&nbsp;-&nbsp;{count}</h2>
      {!wishlistData?.length > 0 ? (
        <div className="no_product_found">No Favourites</div>
      ) : (
        <div className="cards gap-6">
          {wishlistData.map((item) => (
            <ProductCard
              key={item._id}
              product={item}
              route={"/virtual-tryon"}
              storeType={"Virtual"}
              {...props}
            />
          ))}
        </div>
      )}
    </div>
  );
}
