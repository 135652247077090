import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  shopByCategoryBlockImg1,
  shopByCategoryBlockImg2,
  shopByCategoryBlockImg3,
  shopByCategoryBlockImg4,
} from "../../../assets";
// import RightArrow from "../Master/Right_arrow";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RightArrow from "../../Master/RightArrow";

gsap.registerPlugin(ScrollTrigger);
function ShopByCategoryBlock() {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const divRefs = useMemo(() => [ref1, ref2, ref3, ref4], []);
  const [divWidths, setDivWidths] = useState([0, 0, 0, 0]);
  const [hovered, setHovered] = useState([false, false, false, false]);
  const [animation_1_Complete, setAnimationComplete] = useState(false);
  // const [animation_1_InProgress, setAnimation_1_InProgress] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const newDivWidths = divRefs.map((ref) =>
        ref.current ? ref.current.offsetWidth : 0
      );
      setDivWidths(newDivWidths);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [divRefs]);

  const handleMouseEnter = (index) => {
    if (animation_1_Complete) {
      const newHovered = [false, false, false, false];
      newHovered[index] = true;
      setHovered(newHovered);
      divRefs.forEach((ref, i) => {
        if (i !== index) {
          ref.current.style.transform = `translateX(${
            index < i ? "calc(100% / 5)" : "calc(-100% / 5)"
          })`;
          ref.current.style.transition = "transform 0.5s ease";
        }
      });

      if (index === 0) {
        divRefs.forEach((ref, i) => {
          if (i === index) {
            ref.current.style.transform = "translateX(calc(100% / 5))";
            ref.current.style.transition = "transform 0.5s ease";
          }
          if (i !== index) {
            ref.current.style.transform = "translateX(calc(100% / 2.5))";
            ref.current.style.transition = "transform 0.5s ease";
          }
        });
      }
      if (index === 3) {
        divRefs.forEach((ref, i) => {
          if (i === index) {
            ref.current.style.transform = "translateX(calc(-100% / 5))";
            ref.current.style.transition = "transform 0.5s ease";
          }
          if (i !== index) {
            ref.current.style.transform = "translateX(calc(-100% / 2.5))";
            ref.current.style.transition = "transform 0.5s ease";
          }
        });
      }
    }
  };

  const handleMouseLeave = (index) => {
    const newHovered = [...hovered];
    newHovered[index] = false;
    setHovered(newHovered);
    divRefs.forEach((ref, i) => {
      ref.current.style.transform = "translateX(0)";
    });
  };

  const transformDistances = divWidths.map((width) => width / 2 - 75);

  const container = useRef();
  const mediaQuery = gsap.matchMedia();
  useGSAP(
    () => {
      mediaQuery.add("(min-width: 1024px)", () => {

        // if (mediaQuery.matches) {
        ScrollTrigger.create({
          trigger: container.current,
          start: "top center",
          end: "center center",
          animation: gsap
            .timeline({
              paused: true,
              onComplete: () => setAnimationComplete(true),
            })
            .from(".shopby-cat-first", {
              x: "-150%",
            }),
          scrub: 0.5,
        });
        ScrollTrigger.create({
          trigger: container.current,
          start: "top center",
          end: "center center",
          animation: gsap
            .timeline({
              paused: true,
            })
            .from(".shopby-cat-second", {
              x: "-150%",
            }),
          scrub: 0.5,
        });

        ScrollTrigger.create({
          trigger: container.current,
          start: "top center",
          end: "center center",
          animation: gsap
            .timeline({
              paused: true,
            })
            .from(".shopby-cat-third", {
              x: "150%",
            }),
          scrub: 0.5,
        });

        ScrollTrigger.create({
          trigger: container.current,
          start: "top center",
          end: "center center",
          animation: gsap
            .timeline({
              paused: true,
            })
            .from(".shopby-cat-forth", {
              x: "150%",
            }),
          scrub: 0.5,
        });
      }); // media query end
    },
    { scope: container }
  ); // <-- magic

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "60px",
  };
  return (
    <div className="bg-text_light_2 my-20 overflow-x-hidden" ref={container}>
      <div className="text-primary text-4xl text-center font-[Cormorant] my-2 mt-20">
        OUR CATEGORIES
      </div>
      <div className="text-text_dark_2 text-xl text-center font-[Cormorant] my-2 mb-20">
        Browse our curated categories
      </div>
      {/* grid with 4 column  */}
      <div className="hidden lg:grid grid-cols-1 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 px-6 gap-6 overflow-x-auto no-scrollbar">
        <div
          key={0}
          ref={divRefs[0]}
          className="relative rounded-2xl w-full text-center shopby-cat-first group"
          onMouseEnter={() => handleMouseEnter(0)}
          onMouseLeave={() => handleMouseLeave(0)}
        >
          <div className="relative w-full aspect-[4/5]">
            <div
              className={`absolute inset-0 w-full aspect-[4/5] ${
                animation_1_Complete  ? "group-hover:-rotate-12" : ""
              } duration-500 transition-transform origin-bottom-left scale-y-90 px-2`}
            >
              <img
                src={shopByCategoryBlockImg4}
                alt="earings"
                className="w-full h-full object-cover rounded-xl my-auto"
              />
            </div>
            <div
              className={`absolute inset-0 w-full aspect-[4/5] ${
                animation_1_Complete  ? "group-hover:rotate-12" : ""
              } duration-500 transition-transform origin-bottom-right scale-y-90 px-2`}
            >
              <img
                src={shopByCategoryBlockImg3}
                alt="earings"
                className="w-full h-full object-cover rounded-xl my-auto"
              />
            </div>
            <div className="absolute inset-0 w-full aspect-[4/5]">
              <img
                src={shopByCategoryBlockImg1}
                alt="earings"
                className="w-full h-full object-cover rounded-xl my-auto"
              />
            </div>
          </div>
          <div className="absolute bottom-5 left-5 right-5 flex items-center justify-between transition-all duration-500 ">
            <p
              className={`text-white text-center px-4 py-[2px] bg-black bg-opacity-60 backdrop-blur-md font-normal text-lg transition-all duration-500 transform rounded-full w-[160px] ${
                animation_1_Complete 
                  ? "group-hover:bg-white group-hover:text-primary group-hover:pr-16 group-hover:py-[6px]"
                  : ""
              }`}
              style={{
                transform: hovered[0]
                  ? `translateX(${transformDistances[0] - 20}px)`
                  : "translateX(0)",
                transition: "transform 0.5s ease",
              }}
            >
              <span className=" ">ODIYANAM</span>
            </p>
            <span
              className={`bg-black bg-opacity-60 backdrop-blur-md rounded-full p-2 transition-all duration-500 transform ${
                animation_1_Complete  ? "group-hover:bg-primary" : ""
              } `}
              style={{
                transform: hovered[0]
                  ? `translateX(${-transformDistances[0] + 28}px)`
                  : "translateX(0)",
                transition: "transform 0.5s ease",
              }}
            >
              <RightArrow color="#F8F4F1" height="20" width="20" />
            </span>
          </div>
        </div>
        <div
          key={1}
          ref={divRefs[1]}
          className="relative rounded-2xl w-full text-center shopby-cat-second group"
          onMouseEnter={() => handleMouseEnter(1)}
          onMouseLeave={() => handleMouseLeave(1)}
        >
          <div className="relative w-full aspect-[4/5]">
            <div
              className={`absolute inset-0 w-full aspect-[4/5] ${
                animation_1_Complete  ? "group-hover:-rotate-12" : ""
              } duration-500 transition-transform origin-bottom-left scale-y-90 px-2`}
            >
              <img
                src={shopByCategoryBlockImg3}
                alt="earings"
                className="w-full h-full object-cover rounded-xl my-auto"
              />
            </div>
            <div
              className={`absolute inset-0 w-full aspect-[4/5] ${
                animation_1_Complete  ? "group-hover:rotate-12" : ""
              } duration-500 transition-transform origin-bottom-right scale-y-90 px-2`}
            >
              <img
                src={shopByCategoryBlockImg4}
                alt="earings"
                className="w-full h-full object-cover rounded-xl my-auto"
              />
            </div>
            <div className="absolute inset-0 w-full aspect-[4/5]">
              <img
                src={shopByCategoryBlockImg2}
                alt="bangales"
                className="w-full h-full object-cover rounded-xl my-auto "
              />
            </div>
          </div>
          <div className="absolute bottom-5 left-5 right-5 flex items-center justify-between transition-all duration-500 ">
            <p
              className={`text-white text-center px-4 py-[2px] bg-black bg-opacity-60 backdrop-blur-md font-normal text-lg transition-transform duration-500 transform rounded-full w-[135px] ${
                animation_1_Complete 
                  ? "group-hover:bg-white group-hover:text-primary group-hover:pr-16 group-hover:py-[6px]"
                  : ""
              }`}
              style={{
                transform: hovered[1]
                  ? `translateX(${transformDistances[1] - 20}px)`
                  : "translateX(0)",
                transition: "transform 0.5s ease",
              }}
            >
              <span className=" ">HARAM</span>
            </p>
            <span
              className={`bg-black bg-opacity-60 backdrop-blur-md rounded-full p-2 transition-all duration-500 transform ${
                animation_1_Complete  ? "group-hover:bg-primary" : ""
              } `}
              style={{
                transform: hovered[1]
                  ? `translateX(${-transformDistances[1] + 3}px)`
                  : "translateX(0)",
                transition: "transform 0.5s ease",
              }}
            >
              <RightArrow color="#F8F4F1" height="20" width="20" />
            </span>
          </div>
        </div>
        <div
          key={2}
          ref={divRefs[2]}
          className="relative rounded-2xl w-full text-center shopby-cat-third group"
          onMouseEnter={() => handleMouseEnter(2)}
          onMouseLeave={() => handleMouseLeave(2)}
        >
          <div className="relative w-full aspect-[4/5]">
            <div
              className={`absolute inset-0 w-full aspect-[4/5] ${
                animation_1_Complete  ? "group-hover:-rotate-12" : ""
              } duration-500 transition-transform origin-bottom-left scale-y-90 px-2`}
            >
              <img
                src={shopByCategoryBlockImg1}
                alt="earings"
                className="w-full h-full object-cover rounded-xl my-auto"
              />
            </div>
            <div
              className={`absolute inset-0 w-full aspect-[4/5] ${
                animation_1_Complete  ? "group-hover:rotate-12" : ""
              } duration-500 transition-transform origin-bottom-right scale-y-90 px-2`}
            >
              <img
                src={shopByCategoryBlockImg2}
                alt="earings"
                className="w-full h-full object-cover rounded-xl my-auto"
              />
            </div>
            <div className="absolute inset-0 w-full aspect-[4/5]">
              <img
                src={shopByCategoryBlockImg3}
                alt="necklace"
                className="w-full h-full object-cover rounded-xl my-auto "
              />
            </div>
          </div>

          <div className="absolute bottom-5 left-5 right-5 flex items-center justify-between transition-all duration-500 ">
            <p
              className={`text-white text-center px-4 py-[2px] bg-black bg-opacity-60 backdrop-blur-md font-normal text-lg transition-transform duration-500 transform rounded-full w-[155px] ${
                animation_1_Complete 
                  ? "group-hover:bg-white group-hover:text-primary group-hover:pr-16 group-hover:py-[6px]"
                  : ""
              }`}
              style={{
                transform: hovered[2]
                  ? `translateX(${transformDistances[2] - 20}px)`
                  : "translateX(0)",
                transition: "transform 0.5s ease",
              }}
            >
              <span className=" ">NECKLACE</span>
            </p>
            <span
              className={`bg-black bg-opacity-60 backdrop-blur-md rounded-full p-2 transition-all duration-500 transform ${
                animation_1_Complete  ? "group-hover:bg-primary" : ""
              } `}
              style={{
                transform: hovered[2]
                  ? `translateX(${-transformDistances[2] + 23}px)`
                  : "translateX(0)",
                transition: "transform 0.5s ease",
              }}
            >
              <RightArrow color="#F8F4F1" height="20" width="20" />
            </span>
          </div>
        </div>
        <div
          key={3}
          ref={divRefs[3]}
          className="relative rounded-2xl w-full text-center shopby-cat-forth group"
          onMouseEnter={() => handleMouseEnter(3)}
          onMouseLeave={() => handleMouseLeave(3)}
        >
          <div className="relative w-full aspect-[4/5]">
            <div
              className={`absolute inset-0 w-full aspect-[4/5] ${
                animation_1_Complete  ? "group-hover:-rotate-12" : ""
              } duration-500 transition-transform origin-bottom-left scale-y-90 px-2`}
            >
              <img
                src={shopByCategoryBlockImg2}
                alt="earings"
                className="w-full h-full object-cover rounded-xl my-auto"
              />
            </div>
            <div
              className={`absolute inset-0 w-full aspect-[4/5] ${
                animation_1_Complete  ? "group-hover:rotate-12" : ""
              } duration-500 transition-transform origin-bottom-right scale-y-90 px-2`}
            >
              <img
                src={shopByCategoryBlockImg1}
                alt="earings"
                className="w-full h-full object-cover rounded-xl my-auto"
              />
            </div>
            <div className="absolute inset-0 w-full aspect-[4/5]">
              <img
                src={shopByCategoryBlockImg4}
                alt="KAMARBANDH"
                className="w-full h-full object-cover rounded-xl my-auto "
              />
            </div>
          </div>
          <div className="absolute bottom-5 left-5 right-5 flex items-center justify-between transition-all duration-500 ">
            <p
              className={`text-white text-center px-4 py-[2px] bg-black bg-opacity-60 backdrop-blur-md font-normal text-lg transition-transform duration-500 transform rounded-full w-[150px] ${
                animation_1_Complete 
                  ? "group-hover:bg-white group-hover:text-primary group-hover:pr-16 group-hover:py-[6px]"
                  : ""
              }`}
              style={{
                transform: hovered[3]
                  ? `translateX(${transformDistances[3] - 20}px)`
                  : "translateX(0)",
                transition: "transform 0.5s ease",
              }}
            >
              <span className=" ">BRACELET</span>
            </p>
            <span
              className={`bg-black bg-opacity-60 backdrop-blur-md rounded-full p-2 transition-all duration-500 transform ${
                animation_1_Complete  ? "group-hover:bg-primary" : ""
              } `}
              style={{
                transform: hovered[3]
                  ? `translateX(${-transformDistances[3] + 18}px)`
                  : "translateX(0)",
                transition: "transform 0.5s ease",
              }}
            >
              <RightArrow color="#F8F4F1" height="20" width="20" />
            </span>
          </div>
        </div>
      </div>

      {/* ---------------------------------------------------------------------------------------------------------
    ------------------------------------------------------------------------------------------------------------- */}

      <div className="px-4 lg:hidden">
        <Slider {...settings}>
          <div className="px-2">
            <div className="relative rounded-2xl w-full text-center group">
              <div className="w-full aspect-[4/5]">
                <img
                  src={shopByCategoryBlockImg1}
                  alt="earings"
                  className="w-full h-full object-cover rounded-xl my-auto"
                />
              </div>
              <div className="absolute bottom-5 left-5 right-5 flex items-center justify-between transition-all duration-500 lg:group-hover:bg-opacity-80">
                <p className="text-white text-center px-4 py-[2px] bg-black bg-opacity-60 backdrop-blur-md font-normal text-lg transition-all duration-500 transform lg:group-hover:translate-x-20 lg:group-hover:py-2 lg:group-hover:pr-[50px] rounded-full w-[100px] lg:group-hover:text-primary lg:group-hover:bg-secondary">
                  <span className=" text-[12px]">ODIYANAM</span>
                </p>
                <span className="bg-black bg-opacity-60 backdrop-blur-md rounded-full p-2 transition-transform duration-500 transform lg:group-hover:-translate-x-[83px] lg:group-hover:bg-primary">
                  <RightArrow color="#F8F4F1" height="20" width="20" />
                </span>
              </div>
            </div>
          </div>
          <div className="px-2">
            <div className="relative rounded-2xl w-full text-center group">
              <div className="w-full aspect-[4/5]">
                <img
                  src={shopByCategoryBlockImg2}
                  alt="bangales"
                  className="w-full h-full object-cover rounded-xl my-auto"
                />
              </div>
              <div className="absolute bottom-5 left-5 right-5 flex items-center justify-between">
                <p className="text-white text-center px-4 py-[2px] bg-black bg-opacity-60 backdrop-blur-md font-normal text-lg transition-all duration-500 transform lg:group-hover:translate-x-20 lg:group-hover:py-2 lg:group-hover:pr-[50px] rounded-full w-[100px] lg:group-hover:text-primary lg:group-hover:bg-secondary">
                  <span className=" text-[12px]">HARAM</span>
                </p>
                <span className="bg-black bg-opacity-60 backdrop-blur-md rounded-full p-2 transition-transform duration-500 transform lg:group-hover:-translate-x-[83px] lg:group-hover:bg-primary">
                  <RightArrow color="#F8F4F1" height="20" width="20" />
                </span>
              </div>
            </div>
          </div>
          <div className="px-2">
            <div className="relative rounded-2xl w-full text-center group">
              <div className="w-full aspect-[4/5]">
                <img
                  src={shopByCategoryBlockImg3}
                  alt="necklace"
                  className="w-full h-full object-cover rounded-xl my-auto"
                />
              </div>
              <div className="absolute bottom-5 left-5 right-5 flex items-center justify-between">
                <p className="text-white text-center px-4 py-[2px] bg-black bg-opacity-60 backdrop-blur-md font-normal text-lg transition-all duration-500 transform lg:group-hover:translate-x-20 lg:group-hover:py-2 lg:group-hover:pr-[50px] rounded-full w-[100px] lg:group-hover:text-primary lg:group-hover:bg-secondary">
                  <span className=" text-[12px]">NECKLACE</span>
                </p>
                <span className="bg-black bg-opacity-60 backdrop-blur-md rounded-full p-2 transition-transform duration-500 transform lg:group-hover:-translate-x-[83px] lg:group-hover:bg-primary">
                  <RightArrow color="#F8F4F1" height="20" width="20" />
                </span>
              </div>
            </div>
          </div>
          <div className="px-2">
            <div className="relative rounded-2xl w-full text-center group">
              <div className="w-full aspect-[4/5]">
                <img
                  src={shopByCategoryBlockImg4}
                  alt="KAMARBANDH"
                  className="w-full h-full object-cover rounded-xl my-auto"
                />
              </div>
              <div className="absolute bottom-5 left-5 right-5 flex items-center justify-between">
                <p className="text-white text-center px-4 py-[2px] bg-black bg-opacity-60 backdrop-blur-md font-normal text-lg transition-all duration-500 transform lg:group-hover:translate-x-20 lg:group-hover:py-2 lg:group-hover:pr-[50px] rounded-full w-[100px] lg:group-hover:text-primary lg:group-hover:bg-secondary">
                  <span className=" text-[12px]">BRACELET</span>
                </p>
                <span className="bg-black bg-opacity-60 backdrop-blur-md rounded-full p-2 transition-transform duration-500 transform lg:group-hover:-translate-x-[83px] lg:group-hover:bg-primary">
                  <RightArrow color="#F8F4F1" height="20" width="20" />
                </span>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default ShopByCategoryBlock;
