import React, { useEffect, useState } from "react";
import "./LoginSignup.scss";
import { useNavigate } from "react-router-dom";
import { Login } from "./Login";
import { Signup } from "./Signup";
import useGetUser from "../hooks/useGetUser";
import useWindowSize from "../hooks/useWindowSize";

function LoginSignup() {
  const user = useGetUser();
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const [selectedTab, setSelectedTab] = useState("login");

  useEffect(() => {
    if (user?.customer) {
      navigate("/shopping-bag");
    }
  }, [user]);

  return (
    <div className="loginSignup">
      <div className="w-full flex flex-col items-center">
        <div className="lg:hidden emd:flex small:flex items-center justify-center mb-2">
          <span
            onClick={() => setSelectedTab("login")}
            className={` ${
              selectedTab === "login"
                ? "text-primary underline underline-offset-8"
                : "text-secondary"
            } select-none cursor-pointer text-xl`}
          >
            Login
          </span>
          <span
            onClick={() => setSelectedTab("signup")}
            className={` ${
              selectedTab === "signup"
                ? "text-primary underline underline-offset-8"
                : "text-secondary"
            } select-none cursor-pointer ms-4 text-xl`}
          >
            Signup
          </span>
        </div>
        <div className="main-box w-full lg:flex emd:hidden small:hidden justify-center items-start">
          <div className="login-box lg_login">
            <Login />
          </div>
          <div className="signup-box">
            <Signup />
          </div>
        </div>
        <div className="main-box w-full lg:hidden emd:flex small:flex justify-center items-start">
          {selectedTab === "login" ? (
            <div className="login-box">
              <Login />
            </div>
          ) : (
            <div className="signup-box">
              <Signup />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginSignup;
