import React from "react";
import { videoShoppingIcon, contactUsIcon, locationIcon, ADDRESS_TEXT, ADDRESS_LINK } from "../../../assets";
import { Link } from "react-router-dom";


const GetInTouch = () => {
  return (
    <div className="bg-[#F8F4F1]">
      <div className="">
        <div className="text-primary text-[22px] text-center font-[Cormorant] sm:text-[45px] pt-4 px-8 tracking-[1.15px]">
          GET IN TOUCH WITH US
        </div>
        <div className="text-[#1C1919] text-[14px] text-center font-[Lato] font-medium sm:text-[25px] px-12">
         for a complete jewellery shopping experience!
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 px-8 gap-4 py-[30px] sm:py-[60px] ">
        <div className="relative group flex flex-col">
          <div className="absolute inset-0 bg-primary opacity-0 lg:group-hover:opacity-100 transform lg:group-hover:rotate-3 transition duration-300 translate-x-[-7px] rounded-xl"></div>
          <div className="relative bg-gradient-to-tr from-white via-white to-[#FCF5F0] rounded-xl shadow-md p-6 flex flex-col justify-between flex-1 overflow-hidden">
            <div className="relative z-10 flex-1">
              <h2 className="text-[#1C1919] font-[Cormorant] text-[27px] mb-2">
                Video Shopping
              </h2>
              <p className="text-[#645E5E] xl:pr-16">
              Experience seamless video shopping with a personal touch—watch, explore, and instantly purchase your favorite products in real-time. 
              </p>
            </div>
            <div className="relative z-10 flex justify-end items-center mt-4">
              <Link to={"/#coming-soon"} 
                className="bg-transparent border-[1px] border-primary text-primary px-5 py-[7px] rounded-full inline-block w-42"
              >
                BOOK NOW
              </Link >
              <img
                src={videoShoppingIcon}
                alt="video shopping"
                className="w-[40px] h-[40px] border-[1px] border-primary rounded-full p-2 "
              />
            </div>
          </div>
        </div>
        <div className="relative group flex flex-col">
          <div className="absolute inset-0 bg-primary opacity-0 lg:group-hover:opacity-100 transform lg:group-hover:rotate-3 transition duration-300 translate-x-[-7px] rounded-xl"></div>
          <div className="relative bg-gradient-to-tr from-white via-white to-[#FCF5F0] rounded-xl shadow-md p-6 flex flex-col justify-between flex-1 overflow-hidden">
            <div className="relative z-10 flex-1">
              <h2 className="text-[#1C1919] font-[Cormorant] text-[27px] mb-2">
                Our Store
              </h2>
              <p className="text-[#645E5E] xl:pr-16">
                {ADDRESS_TEXT}
              </p>
            </div>
            <div className="relative z-10 flex justify-end items-center mt-4">
              <Link
                to={ADDRESS_LINK}
                target="_blank"
                className="bg-transparent border-[1px] border-primary text-primary px-6 py-[7px] rounded-full inline-block w-32"
              >
                FIND US
              </Link>
              <img
                src={locationIcon}
                alt="our store"
                className="w-[40px] h-[40px] border-[1px] border-primary rounded-full p-2 "
              />
            </div>
          </div>
        </div>
        <div className="relative group flex flex-col">
          <div className="absolute inset-0 bg-primary opacity-0 lg:group-hover:opacity-100 transform lg:group-hover:rotate-3 transition duration-300 translate-x-[-7px] rounded-xl"></div>
          <div className="relative bg-gradient-to-tr from-white via-white to-[#FCF5F0] rounded-xl shadow-md p-6 flex flex-col justify-between flex-1 overflow-hidden">
            <div className="relative z-10 flex-1">
              <h2 className="text-[#1C1919] font-[Cormorant] text-[27px] mb-2">
                Contact Us
              </h2>
              <p className="text-[#645E5E] xl:pr-16">
                We're dedicated to providing you with exceptional support and a great experience!
              </p>
            </div>
            <div className="relative z-10 flex justify-end items-center mt-4">
              <Link
                to={"/contact-us"}
                className="bg-transparent border-[1px] border-primary text-primary px-6 py-[7px] rounded-full inline-block w-32"
              >
                GET NOW
              </Link>
              <img
                src={contactUsIcon}
                alt="contact us"
                className="w-[40px] h-[40px] border-[1px] border-primary rounded-full p-2 "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
