import { Draggable, MotionPathPlugin, ScrollTrigger, gsap } from "gsap/all";
import { useEffect, useState } from "react";

import BestSellingFrame from "./BestSellingFrame/BestSellingFrame";
import BrowseFaceShape from "./BrowseFaceShape/BrowseFaceShape";
import BrowseGlasses from "./BlueRim/BrowseGlasses/BrowseGlasses";
import Flexibleframes from "./FlexibleFrames/Flexibleframes";
import Footer from "../Footer/Footer";
import Header from "../header/Header";
import LenseOptions from "./LenseOptions/LenseOptions";
import MainBanner from "./MaineBanner";
import NetreBlurim from "./NetreBluerim/NetreBlurim";
import NetreFrame from "./NetreFrames/NetreFrame";
import PremiumWear from "./PremiumWear/PremiumWear";
import React from "react";
import Tryon1Block from "./Tryon1Block/TryOn";
import WhatWeOffer from "./WhatWeOffer/WhatWeOffer";
import { useGSAP } from "@gsap/react";
import { useGetHomeDataQuery } from "../../redux/slices/homeSlice";
import { useSelector } from "react-redux";
import Tryon1BlockNew from "./Tryon1Block/Tryon1BlockNew";

// import Footer from "./b2b/Footer/Footer";

// import {useGetHomePageDataQuery} from "./redux/slices/homeSlice";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(MotionPathPlugin);

function Home() {
  const {
    data: homeData,
    isSuccess: homeDataIsSuccess,
    isLoading: homeDataIsLoading,
    isError: homeDataIsError,
    error: homeDataError,
  } = useGetHomeDataQuery();

  return (
    <>
      <MainBanner homeData={homeData?.homeData} />
      <BrowseGlasses homeData={homeData?.homeData} />
      <Tryon1BlockNew homeData={homeData?.homeData} />
      {/* <Tryon1Block homeData={homeData?.homeData} /> */}
      <Flexibleframes homeData={homeData?.homeData} />
      <PremiumWear homeData={homeData?.homeData} />
      {/* <NetreFrame /> */}
      <BrowseFaceShape homeData={homeData?.homeData} />
      <BestSellingFrame homeData={homeData?.homeData} />
      <NetreBlurim homeData={homeData?.homeData} />
      <WhatWeOffer homeData={homeData?.homeData} />
      <LenseOptions homeData={homeData?.homeData} />
      {/* <Bluerim/> */}
    </>
  );
}

export default Home;
