import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAddToCustomerBagMutation,
  useAddToWishlistMutation,
  useGetProductsMutationMutation,
  useGetProductsQuery,
  useRemoveFromCustomerBagMutation,
  useRemoveFromWishlistMutation,
} from "../../../redux/slices/homeSlice";
import { Bounce, toast } from "react-toastify";
import "./Category.scss";
import { useDispatch, useSelector } from "react-redux";
import useGetUser from "../../../hooks/useGetUser";
import { addToMyBag } from "../../../redux/slices/myBagSlice";
import LOADER from "../../Master/LOADER";
import { FilterSidePanel } from "./FilterSidePanel";
import Skeleton from "react-loading-skeleton";
import { fakeData } from "../../../json";
import LazyImageLoader from "../../Master/LazyImageLoader";
import MODEL from "../../Master/MODEL";
import { CLOSE_BLACK_IC, homeBannerDesktop, mainBgImg } from "../../../assets";
import BTN from "../../Master/BTN";
function Category() {
  const params = useParams();
  const location = useLocation();
  const category = params?.name;
  const [showFilter, setShowFilter] = useState(false);
  const showFilterHandler = () => {
    setShowFilter(!showFilter);
  };
  const [sortBy, setSortBy] = useState({});
  const [totalDocs, setTotalDocs] = useState(0);
  const [attributeFilters, setAttributeFilters] = useState({});
  return (
    <div className="categories">
      {!location?.pathname?.includes("search") && (
        <div className="best-selling-frames-banner">
          <LazyImageLoader
            imgSrc={"/best-selling-frame.jpeg"}
            imgAlt="Best Selling Frames"
            imgWidth="100%"
            imgObjectFit={"cover"}
          />
          <div className="overlay font-sabon">{/* Bestseller Glasses */}</div>
        </div>
      )}
      <div className="d-flex j-sb filter-nav">
        <div className=""></div>
        <div className="sort-filter-btn text-primary" onClick={showFilterHandler}>
          SORT AND FILTER
        </div>
      </div>
      <FilterSidePanel
        showFilter={showFilter}
        showFilterHandler={showFilterHandler}
        category={category}
        setSortBy={setSortBy}
        totalDocs={totalDocs}
        setAttributeFilters={setAttributeFilters}
      />
      <div className="category-grid">
        <VirtualProducts
          category={category}
          sortBy={sortBy}
          setTotalDocs={setTotalDocs}
          attributeFilters={attributeFilters}
        />
      </div>
    </div>
  );
}

export const ProductCard = (props) => {
  const user = useGetUser();
  const { pathname } = useLocation();

  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    if (user?.customer) {
      setLoggedIn(true);
    }
  }, [user]);
  const [wishListed, setWishListed] = useState(false);
  const wishlistData = useSelector((state) => state.wishlist.data);

  useEffect(() => {
    if (wishlistData) {
      if (props?.product._id) {
        const found = wishlistData.find(
          (element) => element._id === props.product._id
        );
        if (found) {
          setWishListed(true);
        } else {
          setWishListed(false);
        }
      }
    }
  }, [wishlistData, props]);

  const [addToWishlist] = useAddToWishlistMutation();
  const [removeFromWishlist] = useRemoveFromWishlistMutation();
  const handleWishlist = () => {
    if (!wishListed) {
      if (!loggedIn) {
        toast.error("Please log in to add into Wishlist");
        return;
      }
      if (!props.product._id) {
        return;
      }
      addToWishlist({
        body: {
          product_id: props.product._id,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res.data) {
          setWishListed(true);
          toast.success("Added to Wishlist");
        }
        if (res.error) {
          toast.error(res.error.data.message);
        }
      });
    } else {
      removeFromWishlist({
        body: {
          product_id: props.product._id,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res.data) {
          setWishListed(false);
          toast.success("Removed from Wishlist");
        }
        if (res.error) {
          toast.error(res.error.data.message);
        }
      });
    }
  };
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const stock = 10;

  const redirctUrl = `/products/${props?.product?._id}`;
  var image = null;

  image = props.product?.image[0] ? props.product?.image[0] : null;
  if (image) {
    var transformedImage = image.replace(
      "upload/",
      "upload/if_w_gt_400/w_400/if_end/"
    );
  }
  //on click on sku copy the sku to clipboard
  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(props.product.sku);
    e.target.innerText = "Copied!";
    setTimeout(() => {
      e.target.innerText = "SKU : " + props.product.sku;
    }, 1000);
  };

  // ====================== ADD TO BAG HANDLE ================================

  const [addToCustomerBag] = useAddToCustomerBagMutation();

  const addToMyBagHandel = () => {
    if (loggedIn) {
      addToCustomerBag({
        body: {
          products: [
            {
              product_id: props?.product?._id,
              quantity: 1,
              variant: [],
              lens: [],
            },
          ],
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`${props?.product?.name} added to bag successfully.`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    } else {
      dispatch(addToMyBag(props?.product));
      toast.success(`${props?.product?.name} added to bag successfully.`);
    }
  };

  // ====================== New arrival ================================
  const [showNewArrival, setShowNewArrival] = useState(false);
  const interval = 1000 * 60 * 60 * 24 * 30; // 30 days
  useEffect(() => {
    if (props?.product?.createdAt) {
      const productDate = new Date(props?.product?.createdAt);
      const currentDate = new Date();
      const diff = currentDate - productDate;
      if (diff < interval) {
        setShowNewArrival(true);
      }
    }
  }, [props?.product?.createdAt]);

  // =================== ADD CART HANDLE =================
  const [lensAddModelToggle, setLensAddModelToggle] = useState(false);

  const addCartHandle = (isAddLens) => {
    if (isAddLens) {
      navigate(user?.customer ? "/lens" : "/login", {
        state: { id: props?.product?._id },
      });
    } else {
      addToMyBagHandel();
    }
    setLensAddModelToggle(false);
  };

  return (
    <div
      className="card lg:p-5 emd:p-5 small:p-4 bg-text_light_3 "
      style={{
        outline:
          props?.product?._id &&
          props?.newFrame?._id &&
          props?.product?._id === props?.newFrame?._id
            ? "1px solid var(--color2)"
            : "unset",
      }}
    >
      <div className="top relative">
        <div
          style={{ visibility: showNewArrival ? "visible" : "hidden" }}
          className="new-arrival-label mont-bold absolute lg:-top-1.5 lg:-left-7 emd:-top-1.5 emd:-left-7 small:-top-0 small:-left-5 bg-primary  text-white px-4 py-1"
        >
          NEW ARRIVAL
        </div>

        {wishListed ? (
          <svg
            onClick={handleWishlist}
            className="pointer filled min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px]"
            viewBox="0 -960 960 960"
            fill="#5D0D19"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="min-w-fit max-w-fit min-h-fit max-h-fit"
              d="M 480 -173.85 L 449.69 -201.23 C 384.41 -260.87 330.41 -311.92 287.69 -354.38 C 244.977 -396.847 211.26 -434.297 186.54 -466.73 C 161.82 -499.163 144.55 -528.51 134.73 -554.77 C 124.91 -581.023 120 -607.433 120 -634 C 120 -684.873 137.283 -727.59 171.85 -762.15 C 206.41 -796.717 249.127 -814 300 -814 C 335.18 -814 368.18 -805 399 -787 C 429.82 -769 456.82 -742.82 480 -708.46 C 503.18 -742.82 530.18 -769 561 -787 C 591.82 -805 624.82 -814 660 -814 C 710.873 -814 753.59 -796.717 788.15 -762.15 C 822.717 -727.59 840 -684.873 840 -634 C 840 -607.433 835.09 -581.023 825.27 -554.77 C 815.45 -528.51 798.18 -499.163 773.46 -466.73 C 748.74 -434.297 715.15 -396.847 672.69 -354.38 C 630.23 -311.92 576.103 -260.87 510.31 -201.23 L 480 -173.85 Z M 480 -501 Z"
              transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)"
            />
          </svg>
        ) : (
          <svg
            onClick={handleWishlist}
            className="pointer min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px]"
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#5D0D19"
          >
            <path
              className="min-w-fit max-w-fit min-h-fit max-h-fit"
              d="m480-173.85-30.31-27.38q-97.92-89.46-162-153.15-64.07-63.7-101.15-112.35-37.08-48.65-51.81-88.04Q120-594.15 120-634q0-76.31 51.85-128.15Q223.69-814 300-814q52.77 0 99 27t81 78.54Q514.77-760 561-787q46.23-27 99-27 76.31 0 128.15 51.85Q840-710.31 840-634q0 39.85-14.73 79.23-14.73 39.39-51.81 88.04-37.08 48.65-100.77 112.35Q609-290.69 510.31-201.23L480-173.85Zm0-54.15q96-86.77 158-148.65 62-61.89 98-107.39t50-80.61q14-35.12 14-69.35 0-60-40-100t-100-40q-47.77 0-88.15 27.27-40.39 27.27-72.31 82.11h-39.08q-32.69-55.61-72.69-82.5Q347.77-774 300-774q-59.23 0-99.62 40Q160-694 160-634q0 34.23 14 69.35 14 35.11 50 80.61t98 107q62 61.5 158 149.04Zm0-273Z"
            />
          </svg>
        )}
        {props.product?.quantity > 0 && (
          <div
            className={
              location.pathname === "/inventory" &&
              props.storeType === "In Store"
                ? "count"
                : "count hide"
            }
          >
            {props.product?.quantity}
          </div>
        )}
      </div>
      <div
        className="pointer"
        key={props?.product?._id}
        onClick={() =>
          !pathname?.includes("exchange-order") ? navigate(redirctUrl) : null
        }
      >
        <LazyImageLoader
          imgSrc={transformedImage ? transformedImage : ""}
          imgAlt="product image"
          imgClassName="aspect-square max-h-[50vh] "
          imgHeight={"unset"}
        />
      </div>
      <div className="bottom">
        {/* <div>₹ {props?.product?.prices?.price}</div>
        {props?.product?.name && (
          <div
            className={
              location.pathname === "/inventory" &&
              props.storeType === "Virtual"
                ? "title qty-present"
                : "title"
            }
          >
            {props.product?.name}
          </div>
        )} */}
        <div className='text_dark_2 text-lg'>{props?.product?.prices?.price?<>&#8377;&nbsp;{props?.product?.prices?.price}</>:<Skeleton className='h-full'/>}</div>
        <div className='text-text_dark_3 text-lg w-[calc(100%)] overflow-hidden whitespace-nowrap text-ellipsis text capitalize'>{props.product?.name?<>{props.product?.name}</>:<Skeleton className='h-full'/>}</div>
        {/* <div
          className={
            location.pathname === "/inventory" ? "sku show qty-present" : "sku"
          }
          onClick={copyToClipboard}
        >
          SKU : {props?.product?.sku}
        </div> */}

        {/* {location.pathname.includes("exchange-order") ? (
          <span
            className="add_to_bag_text"
            onClick={() => {
              props.setNewFrame(null);
              props.setNewFrame(props?.product);
            }}
          >
            {props?.product?._id &&
            props?.newFrame?._id &&
            props?.product?._id === props?.newFrame?._id
              ? "Selected For Exchanged"
              : "Exchange Now"}
          </span>
        ) : (
          <span
            className="add_to_bag_text"
            onClick={() => setLensAddModelToggle(true)}
          >
            Add to bag
          </span>
        )} */}
      </div>
      {props.product?.quantity === 0 ||
        props.product?.quantity === "0" ||
        (Number(props.product?.quantity) <= 0 && (
          <div
            className="sold-out"
            onClick={() =>
              !pathname?.includes("exchange-order")
                ? navigate(redirctUrl)
                : null
            }
          >
            <div className="div">SOLD OUT</div>
          </div>
        ))}
      {Number(stock) <= 0 && (
        <div
          className="sold-out"
          onClick={() =>
            !pathname?.includes("exchange-order") ? navigate(redirctUrl) : null
          }
        >
          <div className="div">SOLD OUT</div>
        </div>
      )}
      {Number(stock) > 0 &&
        location.pathname === "/inventory" &&
        (props.operation === "Add" ||
          props.operation === "Return" ||
          props.operation === "Exchange") && (
          <div className="quantity">
            <div className="label">Qty</div>
          </div>
        )}

      {/* =================== ADD LENS OR NOT MODEL =============== */}
      <MODEL
        setShowModel={setLensAddModelToggle}
        showModel={lensAddModelToggle}
        isDialogue={true}
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full flex justify-between bg-gray px-5 py-5">
            <span className=" text-xl mont-bold">Select Your Lens</span>
            <img
              src={CLOSE_BLACK_IC}
              alt=""
              className="cursor-pointer"
              onClick={() => setLensAddModelToggle(false)}
            />
          </div>
          <div className="flex flex-col w-full justify-center items-center my-5 px-5">
            <BTN
              onP={() => addCartHandle(true)}
              title="Add Lens"
              className={"w-full mont-bold text-lg py-1 px-3"}
            />
            <div className="w-full flex items-center justify-between py-5">
              <div className="border-b border-dashed border-light_secondary w-[40%]"></div>
              <span>OR</span>
              <div className="border-b border-dashed border-light_secondary w-[40%]"></div>
            </div>
            <span
              className="text-primary cursor-pointer text-center"
              onClick={() => addCartHandle(false)}
            >
              Buy Only Frame Without Lens
            </span>
          </div>
        </div>
      </MODEL>
    </div>
  );
};

export default Category;

export function VirtualProducts(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const params = useParams();
  const [newData, setNewData] = useState([]);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [paramVal, setParamVal] = useState("");
  // const {
  //   data: productsData,
  //   isLoading,
  //   isFetching,
  //   isSuccess,
  //   isError,
  //   error,
  // } = useGetProductsQuery({
  //   page: page,
  //   queryParams: params?.name
  //     ? `category=${props.category}&price=${props?.sortBy?.value}`
  //     : `${
  //         params?.searchProductParams
  //           ? `title=${params?.searchProductParams}&price=${props?.sortBy?.value}`
  //           : ""
  //       }`,
  // });
  const [productsData, setProductsData] = useState();
  const [
    getProductsMutation,
    { data, isLoading, isFetching, isSuccess, isError, error },
  ] = useGetProductsMutationMutation();

  useEffect(() => {
    // console.log(props.category, params.searchProductParams, props.sortBy.value)
    // if (props.category || params?.searchProductParams) {
    getProductsMutation({
      body: {
        // attributes: {
        //   "Size": "50",
        //   "Shape": "Wayfarer",
        //   "Color Name": "C7 Clear Blue"
        // },
        attributes: props.attributeFilters,
      },
      page: page,
      queryParams: params?.name
        ? `category=${props.category}&price=${props?.sortBy?.value}`
        : `${
            params?.searchProductParams
              ? `title=${params?.searchProductParams}&price=${props?.sortBy?.value}`
              : ""
          }`,
    }).then((res) => {
      if (res.data) {
        // setNewData([]);
        setProductsData(res.data);
        setProducts([...res.data.products]);
        // setProducts((prev) => [...prev, ...res.data.products]);
      }
      if (res.error) {
        console.log(res.error);
      }
    });
    // }
  }, [
    props.category,
    props?.sortBy?.value,
    page,
    params?.searchProductParams,
    props.attributeFilters,
  ]);

  useEffect(() => {
    setParamVal((prev) => prev);
    if (paramVal !== params.searchProductParams) {
      // setProducts([]);
      setPage(1);
    }
  }, [params?.searchProductParams, props.category, props?.sortBy?.value]);

  useEffect(() => {
    if (isError) {
      console.log(error);
      toast.error(error?.data?.message);
    }
  }, [isError]);

  const totalProducts = productsData?.totalDoc || 0;
  // set props.setTotalDocs to totalDocs
  useEffect(() => {
    props.setTotalDocs(totalProducts);
  }, [totalProducts]);
  useEffect(() => {
    if (productsData) {
      setNewData(productsData);
    }
  }, [productsData]);

  // useEffect(() => {
  //   if (newData) {
  //     setProducts([...products, ...newData.products]);
  //   }
  // }, [newData]);

  useEffect(() => {
    const footer = document.querySelector(".Footer_Container");
    const handleScroll = () => {
      // exclude footer height
      // if (!isSuccess) return;
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - footer.clientHeight
        // && !isFetching
      ) {
        if (products.length < totalProducts) {
          setPage((prevPage) => prevPage + 1);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isFetching, isSuccess]);

  return (
    <div>
      {!isSuccess && (
        <div className="cards gap-6">
          {fakeData?.map((item, index) => {
            return <Skeleton key={index} className="card" />;
          })}
        </div>
      )}
      {!products?.length > 0 ? (
        <div className="no_product_found">No Product Found</div>
      ) : (
        <div className="cards grid gap-6">
          {/* {isLoading && <div>Loading..</div>} */}
          {isSuccess &&
            products &&
            products.map((item, index) => (
              <ProductCard
                key={index}
                product={item}
                route={"/virtual-tryon"}
                storeType={"Virtual"}
                {...props}
              />
            ))}
          {products.length > 3 && (
            <Link to="/" className="card category-banner-1">
              <div className="img-box">
                <LazyImageLoader
                  imgSrc={mainBgImg}
                  imgAlt="Best Selling Frames"
                  imgObjectFit={"cover"}
                />
              </div>
            </Link>
          )}
          {products.length > 16 && (
            <Link to="/" className="card category-banner-2">
              <div className="img-box">
                <LazyImageLoader
                  imgSrc={homeBannerDesktop}
                  imgAlt="Category Banner"
                  imgObjectFit={"cover"}
                />
              </div>
            </Link>
          )}
        </div>
      )}
    </div>
  );
}
