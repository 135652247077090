import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// import "./MyBag.scss";
// import { Link, useNavigate } from "react-router-dom";
// import { BACK_ARROW } from "../../assets";
// import MyBagCard from "./MyBagCard";
// import { useDispatch, useSelector } from "react-redux";
// import myBagSlice from "../../redux/slices/myBagSlice";
// import { myBagToggle } from "../../redux/slices/myBagToggleSlice";
// import useGetUser from "../../hooks/useGetUser";
import "./MyBag.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useGetUser from "../../../hooks/useGetUser";
import { myBagToggle } from "../../../redux/slices/myBagToggleSlice";
import { BACK_ARROW } from "../../../assets";
import MyBagCard from "./MyBagCard";
import { toast } from "react-toastify";
import MyBagCardNew from "./MyBagCardNew";

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

const MyBag = ({ setLensStep }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useGetUser();
  const myBagData = useSelector((state) => state?.myBagData?.items);
  const isBagToggled = useSelector((state) => state?.myBagToggle);
 
  // const subTotal = myBagData?.reduce(
  //   (acc, item) =>
  //     acc +
  //     item?.prices?.price * item?.quantity +
  //     (item?.lensDetails?.price || 0),
  //   0
  // );

  const subTotal = () => {
    return myBagData?.reduce((total, item) => {
      // Calculate the product price based on quantity
      const productPrice = item?.prices?.price * item?.quantity;

      // Calculate the total lens price for this product (sum of all lenses)
      const totalLensPrice = item?.lensDetails?.reduce((lensTotal, lens) => {
        return (lensTotal + lens?.price) || 0;
      }, 0);

      // Add the total lens price to the product price
      const itemTotal = productPrice + (totalLensPrice || 0);

      return total + itemTotal;
    }, 0);
  };

  const bodyRef = useRef();

  

  const isNotGo = () => {
    return myBagData?.some((item) =>
      item?.quantity < item?.lensDetails?.length ? true : false
    );
  };

  
  const goCheckOutHandle = () => {
    if (isNotGo()) {
      toast.error("Your lens quantities are more then Frames quantities!", {
        autoClose: 4000,
      });
      return;
    }
    navigate(user?.customer ? "/shopping-tray" : "/signup", {
      state: { isNewUser: user?.customer ? false : true },
    });
    dispatch(myBagToggle());
    document.body.style.overflow = "auto";
    document.body.style.height = "auto";
  };

  return (
    <div className="my_bag_main z-999999">
      <div className="container-fluid">
        {/* <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-md">
          <div className="container-fluid p-2">
            <a className="navbar-brand text-primary mr-0">Company Logo</a>
            <div className="form-inline ml-auto">
              <div
                className="btn btn-primary"
                onClick={() => setIsOpen(!isOpen)}
              >
                <i className="fa fa-bars"></i>
              </div>
            </div>
          </div>
        </nav> */}
        <div className={`my_bag ${isBagToggled === true ? "active" : ""}`}>
          {user?.customer ? (
            <div className="my_bag_header">
              <span className="my_bag_header_text">
                My Bag <span> - {myBagData?.length}</span>
              </span>
            </div>
          ) : (
            <div className="my_bag_header">
              <span
                onClick={() => {
                  navigate("/signup");
                  dispatch(myBagToggle());
                  document.body.style.overflow = "auto";
                  document.body.style.height = "auto";
                }}
                className="my_bag_header_link"
              >
                Sign in
              </span>
              <span className="my_bag_header_text">
                to speed up the checkout process
              </span>
            </div>
          )}
          <div ref={bodyRef} className="my_bag_body">
            {myBagData?.length > 0 ? (
              myBagData?.map((item, index) => {
                return (
                  <MyBagCardNew
                    key={index}
                    data={item}
                    setLensStep={setLensStep}
                  />
                );
              })
            ) : (
              <div className="no_data_found_main">No items in bag !</div>
            )}
            {/* {myBagData?.length > 0 ? (
              myBagData?.map((item, index) => {
                return (
                  <MyBagCard
                    key={index}
                    data={item}
                    setLensStep={setLensStep}
                  />
                );
              })
            ) : (
              <div className="no_data_found_main">No items in bag !</div>
            )} */}
          </div>
          {myBagData?.length !== 0 && (
            <div className="my_bag_bottom_main">
              <div
                onClick={() => {
                  navigate("shopping-bag");
                  dispatch(myBagToggle());
                  document.body.style.overflow = "auto";
                  document.body.style.height = "auto";
                }}
                className="view_bag_btn"
              >
                <span>View bag </span>
                <img src={BACK_ARROW} alt="" className="w-6" />
              </div>
              <div className="checkout_btn" onClick={() => goCheckOutHandle()}>
                <span>Estimated Total: ₹{subTotal()}</span>
                <span>CHECKOUT</span>
              </div>
            </div>
          )}
        </div>
        <div
          className={`my_bag_overlay ${isBagToggled === true ? "active" : ""}`}
          onClick={() => {
            dispatch(myBagToggle());
            document.body.style.overflow = "auto";
            document.body.style.height = "auto";
            bodyRef.current.scrollTop = 0;
          }}
        ></div>
      </div>
    </div>
  );
};

export default MyBag;
