import React, { useEffect, useState } from 'react'
import { RightArrow } from '../../../assets';
import DagaProductCard from '../../Master/DagaProductCard';
import { ProductCard } from '../../Pages/Category/Category';
import { toast } from 'react-toastify';
import { useGetProductsMutationMutation } from '../../../redux/slices/homeSlice';

function TopSellingBlock() {
    const [products, setProducts] = React.useState([])
    const [isLoaded, setIsLoaded] = React.useState(false)
    useEffect(() => {
      fetch('https://fakestoreapi.com/products/category/jewelery')
              .then(res=>res.json())
              .then(json=>{setProducts(json); setIsLoaded(true); console.log(json)})
    }
    , [])
    const [page, setPage] = useState(1);
    const [getProductsMutation] = useGetProductsMutationMutation();
    // useEffect(() => {
    //   getProductsMutation({
    //     body: {
          
    //     },
    //     page: page,
    //     limit: 10,
    //     queryParams: "limit=10&page=1",
    //   }).then((res) => {
    //     if (res.data) {
    //       if (res.data) {
    //         setProducts(res?.data.products);
    //         setIsLoaded(true);
    //         console.log(res?.data.products);
    //       }
    //     } else {
    //       toast.error(`${res?.error?.data?.message}`);
    //     }
    //   });
    // }, []);
    const ref =  React.useRef(null)
    return (
      <div className='bg-bg_1 py-10'>
        <div className="text-primary text-4xl text-center font-[Cormorant] my-2 ">TOP SELLING PRODUCTS</div>
        <div className="text-text_dark_2 text-xl text-center font-[Cormorant] my-2 mb-10">get in touch with us for a complete jewellery shopping experience!</div>
        <div className='p-4'>
          <div ref={ref} className='grid auto-cols-[calc(100% - 4rem)] grid-flow-col gap-4 overflow-scroll no-scrollbar'>
            {!isLoaded && [{},{},{},{}].map((item, index)=>(
                <div className='w-[40vw] sm:w-[23.5vw]' key={index}>
                  <DagaProductCard key={index}/>
                  </div>
              ))}
            {[...products].map((item,index) => (
              <div className='w-[40vw] sm:w-[23.5vw]' key={index}>
              {/* <ProductCard 
              product={item} 
                  /> */}
                  <DagaProductCard
                    key={index}
                    img={item.image}
                    price={item.price}
                    title={item.title}
                    />

                  </div>
              ))  
            }
          </div>
          <div className='flex gap-4 justify-center mt-16'>
            <img
              src={RightArrow}
              alt="our store"
              className="border-[1px] border-primary rounded-full p-2 px-5 text-primary cursor-pointer rotate-180"
              onClick={() => {
                ref.current.scroll({
                  left: ref.current.scrollLeft - 500,
                  behavior: 'smooth'
                });
              }}
              />
              <img
                src={RightArrow}
                alt="our store"
                className="border-[1px] border-primary rounded-full p-2 px-5 text-primary cursor-pointer"
                onClick={() => {
                  ref.current.scroll({
                    left: ref.current.scrollLeft + 500,
                    behavior: 'smooth'
                  });
                }}
                />
          </div>
        </div>
      </div>
    )
}

export default TopSellingBlock