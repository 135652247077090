import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { Bounce, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAddToCustomerBagMutation,
  useGenerateOTPforCustomerSignupMutation,
  useGetCustomerBagDataQuery,
} from "../redux/slices/homeSlice";
import { useDispatch, useSelector } from "react-redux";
import { EMAIL, EMAIL_ADDRESS, MOBILE_NUMBER } from "../assets";

export const Login = () => {
  const myBagData = useSelector((state) => state?.myBagData?.items);
  const [addToCustomerBag] = useAddToCustomerBagMutation();

  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location?.state);
  const loginformik = useFormik({
    initialValues: {
      phoneNo: "",
      otp: "",
    },
    onSubmit: async function (values) {
      try {
        const response = await axios(
          process.env.REACT_APP_BASE_URL + `/customer/login`,
          {
            method: "POST",
            data: {
              phone: values.phoneNo,
              otp: values.otp,
            },
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );
        localStorage.setItem("user", response.data.data.token);

        // ====================== ADD TO BAG HANDLE ================================

        if (myBagData && myBagData?.length > 0) {
          myBagData.forEach((item) => {
            addToCustomerBag({
              body: {
                products: [
                  {
                    product_id: item?._id,
                    quantity: 1,
                    variant: [],
                    lens: [],
                  },
                ],
              },
              user: response.data.data.token,
            }).then((res) => {
              if (res?.data) {
                // toast.success(`${item?.name} added to bag successfully.`);
              } else {
                // toast.error(`${res?.error?.data?.message}`);
              }
            });
          });
        }

        navigate(
          location?.state?.isNewUser === true
            ? "/shopping-bag"
            : location?.state?.id
            ? "/lens"
            : "/profile-details#your-account",
          { state: { id: location?.state?.id ? location?.state?.id : null } }
        );
        toast.success(response.data.message);
      } catch (err) {
        console.log(err);
        toast.error(err.response.data.message);
      }
    },
    validate: (values) => {
      let errors = {};
      if (!values.phoneNo) {
        errors.phoneNo = "Phone number is Required!";
      } else if (!/^[0-9]+$/i.test(values.phoneNo)) {
        errors.phoneNo = "Invalid Phone Number";
      } else if (values.phoneNo.length !== 10) {
        errors.phoneNo = "Phone Number must be 10 digits";
      }

      if (!values.otp) {
        errors.otp = "OTP Required!";
      } else if (!/^[0-9]+$/i.test(values.otp)) {
        errors.otp = "Invalid OTP";
      } else if (values.otp.length !== 3) {
        errors.otp = "OTP must be 3 digits";
      }
      return errors;
    },
  });

  const [
    generateOTPforCustomerSignup,
    {
      data: otpData,
      isLoading: isLoadingOTP,
      isSuccess: isSuccessOTP,
      isError: isErrorOTP,
      error: errorOTP,
    },
  ] = useGenerateOTPforCustomerSignupMutation();
  const generateOtp = (phone) => {
    if (!phone) {
      toast.error("Phone number is Required!");
    }
    if (loginformik.errors.phoneNo) {
      return;
    }
    const body = {
      phone: phone,
    };
    generateOTPforCustomerSignup({ body }).then((res) => {
      // toast success
      if (res.data) {
        toast.success(res.data.message);
      }
      console.log(res.data);
    });
  };
  return (
    <div className="login-form">
      <h1 className="login-title font-sabon">Login</h1>
      <p className="login-text">
        If you are a returning customer please <br /> enter your Phone and
        password
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className="w-full"
      >
        <div className="phone-input-container w-full">
          <input
            type="text"
            placeholder="Ph No."
            name="phoneNo"
            onChange={loginformik.handleChange}
            onBlur={loginformik.handleBlur}
            value={loginformik.values.phoneNo}
            className="lg:w-[80%] emd:w-full small:w-full"
          />
          <div
            className="send-otp-btn-login"
            onClick={() => {
              generateOtp(loginformik.values.phoneNo);
            }}
          >
            SEND
          </div>
        </div>
        {loginformik.errors.phoneNo && loginformik.touched.phoneNo ? (
          <div className="error-msg">{loginformik.errors.phoneNo}</div>
        ) : null}
        <br />
        <div>
          <input
            type="text"
            className="lg:w-[80%] emd:w-full small:w-full"
            placeholder="OTP"
            name="otp"
            onChange={loginformik.handleChange}
            onBlur={loginformik.handleBlur}
            value={loginformik.values.otp}
          />
        </div>
        {loginformik.errors.otp && loginformik.touched.otp ? (
          <div className="error-msg">{loginformik.errors.otp}</div>
        ) : null}
        <br />
        <button
          type="submit"
          className="login-btn login-btn-container lg:w-[80%] emd:w-full small:w-full"
          onClick={loginformik.handleSubmit}
        >
          LOGIN
        </button>
        <br />
      </form>
      <div className="or-container">
        <p className="or-break-line-text">or</p>
      </div>
      <div className="mobile-or-signup">
        <h1 className="mobile-signup-link">Sign Up</h1>
      </div>
      <div className="need-assistance">
        <p className="need-assistance-title">Need Assistance?</p>
        <p
          className="contactus cursor-pointer"
          onClick={() => navigate("/contact-us")}
        >
          Contact Us
        </p>
        <div className="phone-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height={18}
            width={18}
            viewBox="0 -960 960 960"
          >
            <path d="M757.231-160q-101.154 0-207.385-50.654-106.231-50.653-197.385-142.192-91.154-91.539-141.807-197.385Q160-656.077 160-757.231q0-18.33 12-30.549Q184-800 202-800h98.923q16.308 0 28.462 10.269t16.461 26.346L365.692-668q2.77 16.769-1 29.308-3.769 12.538-13.307 20.538l-87.77 81.846q24.616 44.693 54.424 83.039 29.807 38.346 63.576 72.654 34.847 34.846 75 64.807 40.154 29.962 88.154 56.577l85.539-87.077q9.769-10.538 21.961-13.884 12.192-3.347 26.962-1.347l84.154 17.231q16.308 4 26.461 16.423Q800-315.462 800-299.385V-202q0 18-12.22 30-12.219 12-30.549 12ZM244.846-573.846l76.769-70.616q3.846-3.077 5-8.461 1.154-5.385-.384-10l-17.77-84.769q-1.538-6.154-5.384-9.231t-10-3.077H211q-4.615 0-7.692 3.077t-3.077 7.692q1.154 41 12.846 85.616 11.692 44.615 31.769 89.769Zm338 333.384q40.539 20.077 86.423 29.693 45.885 9.615 79.962 10.307 4.615 0 7.692-3.076 3.077-3.077 3.077-7.693v-80.307q0-6.154-3.077-10-3.077-3.847-9.231-5.385l-74-15.154q-4.615-1.538-8.077-.384-3.461 1.153-7.307 5l-75.462 76.999Zm-338-333.384Zm338 333.384Z"></path>
          </svg>
          <p>{MOBILE_NUMBER}</p>
        </div>
        <div className="email-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height={18}
            width={18}
            viewBox="0 -960 960 960"
          >
            <path d="M184.615-200Q157-200 138.5-218.5 120-237 120-264.615v-430.77Q120-723 138.5-741.5 157-760 184.615-760h590.77Q803-760 821.5-741.5 840-723 840-695.385v430.77Q840-237 821.5-218.5 803-200 775.385-200h-590.77ZM480-475.384 160-684.615v420q0 10.769 6.923 17.692T184.615-240h590.77q10.769 0 17.692-6.923T800-264.615v-420L480-475.384ZM480-520l307.692-200H172.308L480-520ZM160-684.615V-720v455.385q0 10.769 6.923 17.692T184.615-240H160v-444.615Z"></path>
          </svg>
          <a href={`mailto:${EMAIL_ADDRESS}`} className="email-link">{EMAIL_ADDRESS}</a>
        </div>
      </div>
    </div>
  );
};
