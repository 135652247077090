import React, { useState } from "react";
// import "./footer.scss";
import {
  APP_STORE_IC,
  DOWN_ARROW_IC,
  facebook,
  FACEBOOK_LINK,
  instagram,
  // FB_IC,
  INSTAGRAM_IC,
  INSTAGRAM_LINK,
  linkedin_icon,
  LINKEDIN_LINK,
  PLAY_STORE_IC,
  plushvie_logo,
  plushvie_logo_gold,
  PLUSHVIE_LOGO_WHITE,
  RightArrow,
  twitter,
  youtube,
  // X_IC,
  // YOUTUBE_IC,
} from "../../assets";
import { Link, useNavigate } from "react-router-dom";
import INPUT from "../Master/INPUT";
import {
  useGetAppDownloadDataQuery,
  useSubscribeMutation,
} from "../../redux/slices/homeSlice";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import BTN from "../Master/BTN";
import GetInTouch from "../home/DagaHome/GetInTouch";

export default function Footer(props) {
  const [isActive, setIsActive] = useState("");
  const navigate = useNavigate();

  const [subscribe] = useSubscribeMutation();

  const {
    data: appDownloadData,
    isSuccess: appDownloadDataIsSuccess,
    isLoading: appDownloadDataIsLoading,
    isError: appDownloadDataIsError,
    error: appDownloadDataError,
  } = useGetAppDownloadDataQuery();

  const subscribeFormik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async function (values) {
      subscribe({
        body: {
          email: values?.email,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          subscribeFormik?.resetForm();
          toast.success(res?.data.message);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    },
    validate: (values) => {
      let errors = {};
      if (!values?.email) {
        errors.email = "Email id is Required!";
      } else if (!isValidEmail(values?.email)) {
        errors.email = "Email is not valid!";
      }
      return errors;
    },
  });

  return (
    <div className="Footer_Container">
      <DagaFooter />
    </div>
  )

  // =================================== OLD Footer ===================================

  // return (
  //   <>
  //     <div className="Footer_Container lg:block emd:hidden small:hidden">
  //       <div className="Section-1">
  //         <div className="Netre_Logo">
  //           <img src="/Netre-logo.svg" alt="logo" height="100%" width="100%" />
  //         </div>
  //         <div className="Netra_Mail">
  //           <div className="Mail_sym">
  //             <div
  //               className="material-symbols-outlined"
  //               style={{ fontSize: "18px", textAlign: "end" }}
  //             >
  //               Mail
  //             </div>
  //           </div>

  //           <a href="mailto:info@netre.in" className="email-link">
  //             info@netre.com
  //           </a>
  //         </div>
  //       </div>
  //       <div className="Section-2">
  //         <div className="footer-item">
  //           <div className="item_title">Netre</div>
  //           <div className="Item_content">
  //             <Link to={"/aboutus"} className="text-secondary text-nowrap">
  //               About Brand
  //             </Link>
  //             {/* <Link to={""} className="text-secondary text-nowrap">
  //               Find Retailer
  //             </Link> */}
  //             <Link to={"/contact-us"} className="text-secondary text-nowrap">
  //               Contact Us
  //             </Link>
  //             <Link to={"/"} className="text-secondary text-nowrap">
  //               Store Locator
  //             </Link>
  //             {/* <Link to={""} className="text-secondary text-nowrap">
  //               Virtual Tryon
  //             </Link> */}
  //             {/* <Link to={""} className="text-secondary text-nowrap">
  //               Reviews
  //             </Link> */}
  //           </div>
  //         </div>
  //         <div className="footer-item">
  //           <div className="item_title">MORE INFO</div>
  //           <div className="Item_content">
  //             <Link
  //               to={"/return-policy"}
  //               className="text-secondary text-nowrap"
  //             >
  //               Return Policy
  //             </Link>
  //             <Link
  //               to={"/exchange-policy"}
  //               className="text-secondary text-nowrap"
  //             >
  //               Exchange Policy
  //             </Link>
  //             <Link
  //               to={"/privacy-policy"}
  //               className="text-secondary text-nowrap"
  //             >
  //               Privacy Policy
  //             </Link>
  //             <Link
  //               to={"/terms-condition"}
  //               className="text-secondary text-nowrap"
  //             >
  //               Terms & Condition
  //             </Link>
  //           </div>
  //         </div>
  //         <div className="footer-item">
  //           <div className="item_title">SOCIAL MEDIA</div>
  //           <div className="Item_content">
  //             <Link
  //               to={
  //                 "https://www.instagram.com/netre.in_?igsh=MTh6OHB3amQwMDc2Zg=="
  //               }
  //               target="_blank"
  //               className="text-secondary text-nowrap"
  //             >
  //               Instagram
  //             </Link>
  //             {/* <Link to={""} className="text-secondary text-nowrap">
  //               Facebook
  //             </Link>
  //             <Link to={""} className="text-secondary text-nowrap">
  //               Twitter
  //             </Link>
  //             <Link to={""} className="text-secondary text-nowrap">
  //               Youtube
  //             </Link> */}
  //           </div>
  //         </div>
  //         <div className="footer-item">
  //           {/* <div className="item_title">Install APP</div> */}
  //           <div className="Item_content">
  //             {/* <Link to={appDownloadData?.b2c_playstore} target="_blank">
  //               <img src={PLAY_STORE_IC} alt="" className="w-24" />
  //             </Link>
  //             <Link to={appDownloadData?.b2c_appstore} target="_blank">
  //               <img src={APP_STORE_IC} alt="" className="w-24" />
  //             </Link> */}
  //             {/* <div className="Social_btn_container">
  //               <div className="social_btn">
  //                 <div
  //                   className="material-symbols-outlined"
  //                   style={{ fontSize: "18px", textAlign: "end" }}
  //                 >
  //                   <svg
  //                     xmlns="http://www.w3.org/2000/svg"
  //                     height="24px"
  //                     viewBox="0 -960 960 960"
  //                     width="24px"
  //                     fill="#444444"
  //                   >
  //                     <path d="M320-200v-560l440 280-440 280Zm80-280Zm0 134 210-134-210-134v268Z" />
  //                   </svg>
  //                 </div>
  //               </div>
  //               <div className="Social_tags">
  //                 <div className="tagline">Get It On</div>
  //                 <div className="social_platform">Google Play</div>
  //               </div>
  //             </div> */}
  //             {/* <div className="Social_btn_container">
  //               <div className="social_btn">
  //                 <div
  //                   className="material-symbols-outlined"
  //                   style={{ fontSize: "18px", textAlign: "end" }}
  //                 >
  //                   <svg
  //                     xmlns="http://www.w3.org/2000/svg"
  //                     height="24px"
  //                     viewBox="0 -960 960 960"
  //                     width="24px"
  //                     fill="#444444"
  //                   >
  //                     <path d="M320-200v-560l440 280-440 280Zm80-280Zm0 134 210-134-210-134v268Z" />
  //                   </svg>
  //                 </div>
  //               </div>
  //               <div className="Social_tags">
  //                 <div className="tagline" style={{ opacity: "100%" }}>
  //                   Download on the
  //                 </div>
  //                 <div className="social_platform">App Store</div>
  //               </div>
  //             </div> */}
  //           </div>
  //         </div>
  //         <div className="footer-item">
  //           <div className="item_title">Signup</div>
  //           <div className="Item_content">
  //             <div className="signup_para">
  //               Subscribe To Get Special Offers, And Once-In- A-Lifetime Deals.
  //             </div>
  //           </div>
  //           <div className="w-full flex flex-col mt-3 pr-8">
  //             <INPUT
  //               placeHolder={"| Your Email"}
  //               className={"px-2"}
  //               lComp={
  //                 <div className="material-symbols-outlined clear_ic text-2xl me-1 text-secondary">
  //                   mail
  //                 </div>
  //               }
  //               rComp={
  //                 <span
  //                   className="text-base"
  //                   onClick={subscribeFormik.handleSubmit}
  //                 >
  //                   SUBMIT
  //                 </span>
  //               }
  //               name="email"
  //               onChange={subscribeFormik.handleChange}
  //               val={subscribeFormik.values.email}
  //               onBlur={subscribeFormik.handleBlur}
  //               errorMsg={subscribeFormik.errors.email}
  //               touched={subscribeFormik.touched.email}
  //             />
  //           </div>
  //           <div className="w-full pr-8">
  //             <div
  //               onClick={() => navigate("/become-retailer")}
  //               className="uppercase text-primary text-center py-2 w-full border border-primary cursor-pointer"
  //             >
  //               Become authorized retailer
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="Hr"></div>
  //       <div className="CopyRight flex items-center">
  //         Copyright @netre 2024 .Website designed & developed by{" "}
  //         <Link to={"https://plushvie.in/"} target="_blank">
  //           <img
  //             src="https://showcase.plushvie.in/assets/Plushvie%20Logo-03-978edc36.svg"
  //             alt=""
  //             className="w-16 ms-2 mb-1"
  //           />
  //         </Link>
  //       </div>
  //     </div>
  //     <div className="w-full lg:hidden emd:flex small:flex flex-col bg-[#F7F7F8] p-5">
  //       <div className="mt-10 flex flex-col">
  //         <img src="/Netre-logo.svg" alt="" className="w-28" />
  //         <div className="mt-5 flex items-center">
  //           <div className="material-symbols-outlined clear_ic text-2xl text-secondary">
  //             mail
  //           </div>
  //           <span className="text-secondary mt-1 ms-2">
  //             <a href="mailto:info@netre.in" className="email-link">
  //               info@netre.com
  //             </a>
  //           </span>
  //         </div>
  //       </div>
  //       <div className="mt-10 w-full flex flex-col">
  //         <div className="w-full flex flex-col pb-5 border-b border-secondary">
  //           <div
  //             onClick={() => setIsActive(isActive !== "netre" ? "netre" : "")}
  //             className="w-full flex items-center justify-between"
  //           >
  //             <span className="text-secondary text-xl">NETRE</span>
  //             <span>
  //               <img
  //                 src={DOWN_ARROW_IC}
  //                 className={`${
  //                   isActive === "netre" ? "ac_tr_arrow_ic" : ""
  //                 } ac_arrow_ic text-secondary`}
  //                 alt=""
  //               />
  //             </span>
  //           </div>
  //           {isActive === "netre" && (
  //             <div className="w-full mt-5 grid small:grid-cols-2 emd:grid-cols-3 gap-x-1 gap-y-3">
  //               <Link to={"/aboutus"} className="text-secondary text-nowrap">
  //                 About Brand
  //               </Link>
  //               {/* <Link to={""} className="text-secondary text-nowrap">
  //                 Find Retailer
  //               </Link> */}
  //               <Link to={"/contact-us"} className="text-secondary text-nowrap">
  //                 Contact Us
  //               </Link>
  //               <Link to={"/"} className="text-secondary text-nowrap">
  //                 Store Locator
  //               </Link>
  //               {/* <Link to={""} className="text-secondary text-nowrap">
  //                 Virtual Tryon
  //               </Link> */}
  //               {/* <Link to={""} className="text-secondary text-nowrap">
  //                 Reviews
  //               </Link> */}
  //             </div>
  //           )}
  //         </div>
  //         <div className="w-full flex flex-col pb-5 mt-8 border-b border-secondary">
  //           <div
  //             onClick={() =>
  //               setIsActive(isActive !== "more_info" ? "more_info" : "")
  //             }
  //             className="w-full flex items-center justify-between"
  //           >
  //             <span className="text-secondary text-xl">MORE INFO</span>
  //             <span>
  //               <img
  //                 src={DOWN_ARROW_IC}
  //                 className={`${
  //                   isActive === "more_info" ? "ac_tr_arrow_ic" : ""
  //                 } ac_arrow_ic text-secondary`}
  //                 alt=""
  //               />
  //             </span>
  //           </div>
  //           {isActive === "more_info" && (
  //             <div className="w-full mt-5 grid small:grid-cols-2 emd:grid-cols-3 gap-x-1 gap-y-3">
  //               <Link
  //                 to={"/return-policy"}
  //                 className="text-secondary text-nowrap"
  //               >
  //                 Return Policy
  //               </Link>
  //               <Link
  //                 to={"/exchange-policy"}
  //                 className="text-secondary text-nowrap"
  //               >
  //                 Exchange Policy
  //               </Link>
  //               <Link
  //                 to={"/privacy-policy"}
  //                 className="text-secondary text-nowrap"
  //               >
  //                 Privacy Policy
  //               </Link>
  //               <Link
  //                 to={"/terms-condition"}
  //                 className="text-secondary text-nowrap"
  //               >
  //                 Terms & Condition
  //               </Link>
  //             </div>
  //           )}
  //         </div>
  //         <div className="w-full flex flex-col items-center justify-center pb-5 mt-8">
  //           <span className="text-secondary text-xl">SOCIAL MEDIA</span>
  //           <div className="w-full flex items-center justify-center mt-3">
  //             <img src={INSTAGRAM_IC} alt="" />
  //             {/* <img src={FB_IC} alt="" />
  //             <img src={X_IC} alt="" className="ms-3" />
  //             <img src={YOUTUBE_IC} alt="" className="ms-3" /> */}
  //           </div>
  //         </div>
  //         {/* <div className="w-full flex flex-col items-center justify-center pb-5 mt-8">
  //           <span className="text-secondary text-xl">INSTALL APP</span>
  //           <div className="w-full flex items-center justify-center mt-3">
  //             <Link to={appDownloadData?.b2c_playstore} target="_blank">
  //               <img src={PLAY_STORE_IC} alt="" className="w-24" />
  //             </Link>
  //             <Link to={appDownloadData?.b2c_appstore} target="_blank">
  //               <img src={APP_STORE_IC} alt="" className="ms-3 w-24" />
  //             </Link>
  //           </div>
  //         </div> */}
  //         <div className="w-full flex flex-col items-center justify-center pb-5 mt-10">
  //           <span className="text-secondary text-2xl">
  //             JOIN THE WORLD OF NETRE
  //           </span>
  //           <div className="w-full flex items-center justify-center mt-3">
  //             Subscribe to get special of fers, new releases, and discounts
  //           </div>
  //           <div className="w-full flex flex-col mt-3">
  //             <INPUT
  //               placeHolder={"| Your Email"}
  //               className={"px-2"}
  //               lComp={
  //                 <div className="material-symbols-outlined clear_ic text-2xl me-1 text-secondary">
  //                   mail
  //                 </div>
  //               }
  //               rComp={
  //                 <span
  //                   className="text-base"
  //                   onClick={subscribeFormik.handleSubmit}
  //                 >
  //                   SUBMIT
  //                 </span>
  //               }
  //               name="email"
  //               onChange={subscribeFormik.handleChange}
  //               val={subscribeFormik.values.email}
  //               onBlur={subscribeFormik.handleBlur}
  //               errorMsg={subscribeFormik.errors.email}
  //               touched={subscribeFormik.touched.email}
  //             />
  //           </div>
  //           <div className="w-full mt-2">
  //             <div
  //               onClick={() => navigate("/become-retailer")}
  //               className="uppercase text-primary text-center py-2 w-full border border-primary cursor-pointer"
  //             >
  //               Become authorized retailer
  //             </div>
  //           </div>
  //         </div>
  //         <div className="w-full text-sm mt-10 text-center justify-center items-center pt-5 border-t border-secondary">
  //           <div className="w-full text-center items-center lg:block emd:flex small:flex justify-center flex-col">
  //             <span>
  //               Copyright @netre 2024. Website designed & developed by
  //             </span>
  //             <Link to={"https://plushvie.in/"} target="_blank">
  //               <img
  //                 src="https://showcase.plushvie.in/assets/Plushvie%20Logo-03-978edc36.svg"
  //                 alt=""
  //                 className="min-w-16 max-w-16 h-[18px] lg:mb-1 emd:mb-0 small:mb-0 lg:mt-0 emd:mt-1 small:mt-1"
  //               />
  //             </Link>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
}
const year = new Date().getFullYear();
const DagaFooter = () => {
  const [infoOpen, setInfoOpen] = useState(false);
  const [careOpen, setCareOpen] = useState(false);

  const toggleInfoOpen = () => {
    setInfoOpen(!infoOpen);
    if (!infoOpen) {
      setCareOpen(false);
    }
  };

  const toggleCareOpen = () => {
    setCareOpen(!careOpen);
    if (!careOpen) {
      setInfoOpen(false);
    }
  };

  const [subscribe] = useSubscribeMutation();
  const footerformik = useFormik({
    initialValues: {
      email: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      return errors;
    },
    onSubmit: async function (values) {
      subscribe({
        body: {
          email: values?.email,
        },
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          footerformik?.resetForm();
          toast.success(res?.data.message);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    },
  });

  return (
    <div className="">
      <GetInTouch />
      <div className="flex flex-col sm:flex-row justify-between py-16 px-8 lg:px-28">
        <div className="order-2 sm:order-1 flex flex-col gap-3">
          <div className="flex justify-between items-center" onClick={toggleInfoOpen}>
            <p className="md:text-[22px] lg:text-[30px] font-[Cormorant] text-primary tracking-[1.98px]">
              Information
            </p>
            <button
              className="sm:hidden uppercase text-center text-[#666565]"
            >
              <svg
                className={`w-[20px] h-[20px] transform transition-transform duration-500 ${infoOpen ? "rotate-180" : "rotate-0"
                  }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
          </div>
          <div className="sm:hidden mb-8">
            {infoOpen && (
              <div className="flex flex-col border-b-2">
                <Link className="uppercase text-[#666565] mb-3" to="/aboutus">
                  about
                </Link>
                <Link className="uppercase text-[#666565] mb-3" to="/#coming-soon">
                  careers
                </Link>
                <Link className="uppercase text-[#666565] mb-3" to="/contact-us">
                  contact us
                </Link>
                <Link className="uppercase text-[#666565] mb-3" to="/#coming-soon">
                  sustainbility
                </Link>
                <Link className="uppercase text-[#666565] mb-3" to="/#coming-soon">
                  our store
                </Link>
                <Link className="uppercase text-[#666565] mb-5" to="/#coming-soon">
                  blog
                </Link>
              </div>
            )}
          </div>
          <div className="hidden sm:flex flex-col gap-4">
            <Link
              className="uppercase text-center text-[#666565] sm:text-[12px] md:text-[14px] lg:text-[20px]"
              to="/aboutus"
            >
              about
            </Link>
            <Link
              className="uppercase text-center text-[#666565] sm:text-[12px] md:text-[14px] lg:text-[20px]"
              to="/#coming-soon"
            >
              careers
            </Link>
            <Link
              className="uppercase text-center text-[#666565] sm:text-[12px] md:text-[14px] lg:text-[20px]"
              to="/contact-us"
            >
              contact us
            </Link>
            <Link
              className="uppercase text-center text-[#666565] sm:text-[12px] md:text-[14px] lg:text-[20px]"
              to="/#coming-soon"
            >
              sustainbility
            </Link>
            <Link
              className="uppercase text-center text-[#666565] sm:text-[12px] md:text-[14px] lg:text-[20px]"
              to="/#coming-soon"
            >
              our store
            </Link>
            <Link
              className="uppercase text-center text-[#666565] sm:text-[12px] md:text-[14px] lg:text-[20px]"
              to="/#coming-soon"
            >
              blog
            </Link>
          </div>
        </div>

        <div className="order-1 sm:order-2 flex flex-col text-center">
          <p className="md:text-[22px] lg:text-[30px] font-[Cormorant] text-primary mb-9 tracking-[1.32px] md:tracking-[1.98px]">
            Sign Up To Our Newsletter
          </p>
          <input
            type="email"
            name="email"
            onChange={footerformik.handleChange}
            value={footerformik.values.email}
            onBlur={footerformik.handleBlur}
            placeholder="Enter your email"
            className="w-[90%] self-center sm:text-[21px] border-b-2 border-gray-300 focus:border-primary outline-none text-center font-[lato] text-[#767676] mb-6"
          />
          {footerformik.touched.email && footerformik.errors.email ? (
            <div className="text-red-500">{footerformik.errors.email}</div>
          ) : null}
          <div className="flex justify-center items-center mb-9 max-w-fit mx-auto" onClick={footerformik.handleSubmit}>
            <button
              href="#"
              className="text-[14px] sm:text-[16px] bg-transparent border-[1px] font-[Cormorant] border-primary text-primary px-10 py-[7px] rounded-full inline-block"

            >
              SUBMIT
            </button>
            <img

              src={RightArrow}
              alt="our store"
              className="border-[1px] border-primary rounded-full p-2 text-primary"
            />
          </div>
          <div className="text-[#3F1322] font-[Lato] text-[10px] sm:text-[13px] md:text-[16px] lg:text-[20px] text-center mb-8">
            By Signing Up To Our Newsletter, You <br />
            Agree To Receive Marketing Emails From Daga Gems.
          </div>
        </div>

        <div className="order-3 sm:order-3 flex flex-col gap-3">
          <div className="flex justify-between items-center" onClick={toggleCareOpen}>
            <p className="md:text-[22px] lg:text-[28px] font-[Cormorant] text-center text-primary tracking-[1.98px]">
              Customer Care
            </p>
            <button
              className="sm:hidden uppercase text-center text-[#666565]"
            >
              <svg
                className={`w-[20px] h-[20px] transform transition-transform duration-500 ${careOpen ? "rotate-180" : "rotate-0"
                  }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
          </div>
          <div className="sm:hidden">
            {careOpen && (
              <div className="flex flex-col border-b-2">
                <Link className="uppercase text-[#666565] mb-3" to="/aboutus">
                  about
                </Link>
                <Link className="uppercase text-[#666565] mb-3" to="/#coming-soon">
                  careers
                </Link>
                <Link className="uppercase text-[#666565] mb-3" to="/contact-us">
                  contact us
                </Link>
                <Link className="uppercase text-[#666565] mb-3" to="/#coming-soon">
                  sustainbility
                </Link>
                <Link className="uppercase text-[#666565] mb-3" to="/#coming-soon">
                  our store
                </Link>
                <Link className="uppercase text-[#666565] mb-5" to="/#coming-soon">
                  blog
                </Link>
              </div>
            )}
          </div>
          <div className="hidden sm:flex flex-col gap-4">
            <Link
              className="uppercase text-center text-[#666565] sm:text-[12px] md:text-[14px] lg:text-[20px]"
              to="/aboutus"
            >
              about
            </Link>
            <Link
              className="uppercase text-center text-[#666565] sm:text-[12px] md:text-[14px] lg:text-[20px]"
              to="/#coming-soon"
            >
              careers
            </Link>
            <Link
              className="uppercase text-center text-[#666565] sm:text-[12px] md:text-[14px] lg:text-[20px]"
              to="/contact-us"
            >
              contact us
            </Link>
            <Link
              className="uppercase text-center text-[#666565] sm:text-[12px] md:text-[14px] lg:text-[20px]"
              to="/#coming-soon"
            >
              sustainbility
            </Link>
            <Link
              className="uppercase text-center text-[#666565] sm:text-[12px] md:text-[14px] lg:text-[20px]"
              to="/#coming-soon"
            >
              our store
            </Link>
            <Link
              className="uppercase text-center text-[#666565] sm:text-[12px] md:text-[14px] lg:text-[20px]"
              to="/#coming-soon"
            >
              blog
            </Link>
          </div>
        </div>
      </div>
      <div className="lg:bg-primary flex flex-col lg:grid lg:grid-cols-[minmax(0,_1fr)_minmax(0,_1fr)_minmax(0,_1fr)] items-center lg:p-4 gap-0 lg:gap-4">
        <div className="order-2 lg:order-none flex flex-row justify-center lg:justify-start px-2 pt-3 lg:pt-0 lg:px-0 text-center sm:text-left font-[Montserrat] w-full bg-primary text-bg_1 text-[16px] tracking-[0.51px] sm:col-start-1 sm:col-end-2">
          <span className="text-bg_1 opacity-65">@COPYRIGHT DAGA GEMS@{year}</span>
        </div>
        <div className="order-3 bg-primary lg:order-none flex justify-center pb-2 lg:pb-0 items-center sm:col-start-2 sm:col-end-3 text-[15px] w-full">
          <span className="text-bg_1 opacity-65">Designed and Developed by</span>
          <a href="https://plushvie.in/" target="_blank" className="inline-block">
            <img
              src={plushvie_logo_gold}
              alt="plushvie_logo"
              className="w-24 ms-1 -translate-y-[15%]"
            />
          </a>
        </div>
        <div className="order-1 lg:order-none flex flex-row w-full gap-4 sm:w-auto justify-center sm:justify-end mb-2 lg:mb-0 font-[Montserrat] sm:col-start-3 sm:col-end-4">
          <Link to={INSTAGRAM_LINK} target="_blank">
            <img src={instagram} alt="instagram" className="text-primary" />
          </Link>
          <Link to={FACEBOOK_LINK} target="_blank">
            <img src={facebook} alt="facebook" className="text-primary" />
          </Link>
          <Link to={LINKEDIN_LINK} target="_blank">
            <img src={linkedin_icon} alt="facebook" className="text-primary" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export function isValidEmail(email) {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}
