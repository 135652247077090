import React, { useLayoutEffect, useRef } from "react";
import {
  shopByCollectionImg1,
  shopByCollectionImg2,
  shopByCollectionImg3,
  shopByCollectionImg4,
  curatedBlockImg1,
  curatedBlockImg2,
  curatedBlockImg3,
  curatedBlockImg4,
  RightArrow,
} from "../../../assets";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
function ShopByCollectionBlock() {
  const container = useRef();
  const mediaQuery = gsap.matchMedia();
  // useGSAP(() => {
  useLayoutEffect(() => {
    mediaQuery.add("(min-width: 1024px)", () => {
      ScrollTrigger.create({
        trigger: ".shop-by-collection",
        start: "top top",
        end: "bottom+=1100 bottom",
        scrub: 0.5,
        pin: true,
      });
      ScrollTrigger.create({
        trigger: ".shop-by-collection",
        start: "center center",
        end: "bottom center",
        animation: gsap
          .timeline({
            paused: true,
          })
          .from(".text-1", {
            scale: 0.3,
            opacity: 0,
          })
          .to(".text-1", {
            top: "20%",
            scale: 0.8,
          }),
        scrub: 0.5,
      });
      ScrollTrigger.create({
        trigger: ".shop-by-collection",
        start: "center center",
        end: "bottom+=500 center",
        animation: gsap
          .timeline({
            paused: true,
          })
          .from(".scroll-down-circle", {
            y: 100,
            opacity: 0,
          })
          .to(".scroll-down-circle", {
            opacity: 0,
          })
          .from(".shop-by-cards", {
            y: "150%",
          })
          .to(".shop-by-card-1", {
            //empty to sync with the timeline
            // rotate: 90,
          }),
        scrub: 0.5,
      });
      ScrollTrigger.create({
        trigger: ".shop-by-collection",
        start: "center center",
        end: "bottom+=500 center",
        animation: gsap
          .timeline({
            paused: true,
          })
          .from(".shop-by-card-1", {
            rotate: -20,
            x: "160%",
          })
          .from(".shop-by-card-1", {
            rotate: -20,
            x: "160%",
          })
          .from(".shop-by-card-1", {
            rotate: -20,
            x: "160%",
          })
          .to(".shop-by-card-1", {
            rotate: 0,
            x: "0%",
          }),
        scrub: 0.5,
      });
      ScrollTrigger.create({
        trigger: ".shop-by-collection",
        start: "center center",
        end: "bottom+=500 center",
        animation: gsap
          .timeline({
            paused: true,
          })
          .from(".shop-by-card-2", {
            rotate: -10,
            x: "60%",
          })
          .from(".shop-by-card-2", {
            rotate: -10,
            x: "60%",
          })
          .from(".shop-by-card-2", {
            rotate: -10,
            x: "60%",
          })
          .to(".shop-by-card-2", {
            rotate: 0,
            x: "0%",
          }),
        scrub: 0.5,
      });
      ScrollTrigger.create({
        trigger: ".shop-by-collection",
        start: "center center",
        end: "bottom+=500 center",
        animation: gsap
          .timeline({
            paused: true,
          })
          .from(".shop-by-card-3", {
            rotate: 0,
            x: "-50%",
          })
          .from(".shop-by-card-3", {
            rotate: 0,
            x: "-50%",
          })
          .from(".shop-by-card-3", {
            rotate: 0,
            x: "-50%",
          })
          .to(".shop-by-card-3", {
            rotate: 0,
            x: "0%",
          }),
        scrub: 0.5,
      });
      ScrollTrigger.create({
        trigger: ".shop-by-collection",
        start: "center center",
        end: "bottom+=500 center",
        animation: gsap
          .timeline({
            paused: true,
          })
          .from(".shop-by-card-4", {
            rotate: 10,
            x: "-150%",
          })
          .from(".shop-by-card-4", {
            rotate: 10,
            x: "-150%",
          })
          .from(".shop-by-card-4", {
            rotate: 10,
            x: "-150%",
          })
          .to(".shop-by-card-4", {
            rotate: 0,
            x: "0%",
          }),
        scrub: 0.5,
      });
    }); //match media query end
  }, []);
  // });
  return (
    <div>
      <div
        className="h-screen bg-primary py-10 px-4 shop-by-collection relative hidden lg:block"
        ref={container}
      >
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-1">
          <div className="text-bg_1 text-3xl text-center font-[Cormorant] my-4">
            BROWSE OUR BEST
          </div>
          <div className="text-bg_1 text-3xl text-center font-[Cormorant] my-4 mb-14">
            CURATED COLLLECTION
          </div>
        </div>
        <div
          className="scroll-down-circle text-white border border-white inline-flex flex-col justify-between items-center rounded-full aspect-square w-[100px]
        absolute left-1/2 bottom-6 -translate-x-1/2"
        >
          <div className="h-[15px] border-r-[1px] "></div>
          <div className="inline-flex flex-col justify-between items-center text-xs">
            <div>SCROLL</div>
            <div>DOWN</div>
          </div>
          <div className="h-[15px] border-r-[1px] "></div>
        </div>
        <div className="shop-by-cards absolute top-[60%] left-1/2 -translate-x-1/2 -translate-y-1/2 w-full p-8">
          <div className=" flex gap-6">
            <div className="relative z-[1] origin-bottom bg-gradient-to-tr from-white to-[#ffffff9e] rounded-xl group shop-by-card-1">
              <div className="relative p-4">
                <div className="aspect-[1/1] object-cover">
                  <img
                    src={shopByCollectionImg1}
                    alt="bangale-1"
                    className="w-full h-full object-cover rounded-xl"
                  />
                </div>
              </div>
              <div className="mb-4 flex flex-row flex-grow justify-center items-center ">
                <button className="text-[#1C1919] py-2 pl-3 pr-3 text-[10px] sm:pl-8 sm:pr-8 sm:text-[16px] md:pl-4 md:pr-4 md:text-[10px] lg:pl-4 lg:pr-4 lg:text-[10px] xl:pl-5 xl:pr-5 xl:text-[13px] border-[1px] border-[#1C1919] rounded-full font-medium transition-all duration-500 lg:group-hover:pr-16 lg:group-hover:bg-primary lg:group-hover:text-[#FCF5F0] ">
                ZIRCON COLLECTION
                </button>
                <img
                  src={RightArrow}
                  className="p-1 h-[32px] w-[32px] sm:h-[41px] sm:w-[41px] md:h-[32px] md:w-[32px] lg:h-[36px] lg:w-[36px] xl:h-[40px] xl:w-[40px] border-[1px] border-[#1C1919] rounded-full text-sm font-medium transition-all duration-500 group-hover:translate-x-[-40px] lg:group-hover:translate-x-[-35px] xl:group-hover:translate-x-[-40px] lg:group-hover:bg-[#FCF5F0] lg:group-hover:text-primary lg:group-hover:scale-90"
                />
              </div>
            </div>
            <div className="relative z-[2] origin-bottom bg-gradient-to-tr from-white to-[#ffffff9e] rounded-xl group shop-by-card-2">
              <div className="relative p-4">
                <div className="aspect-[1/1] object-cover">
                  <img
                    src={shopByCollectionImg2}
                    alt="bangale-1"
                    className="w-full h-full object-cover rounded-xl"
                  />
                </div>
              </div>
              <div className="mb-4 flex flex-row flex-grow justify-center items-center ">
                <button className="text-[#1C1919] py-2 pl-3 pr-3 text-[10px] sm:pl-8 sm:pr-8 sm:text-[16px] md:pl-4 md:pr-4 md:text-[10px] lg:pl-4 lg:pr-4 lg:text-[10px] xl:pl-5 xl:pr-5 xl:text-[13px]  border-[1px] border-[#1C1919] rounded-full font-medium transition-all duration-500 lg:group-hover:pr-16 lg:group-hover:bg-primary lg:group-hover:text-[#FCF5F0] ">
                KUNDAN COLLECTION
                </button>
                <img
                  src={RightArrow}
                  className="p-1 h-[32px] w-[32px] sm:h-[41px] sm:w-[41px] md:h-[32px] md:w-[32px] lg:h-[36px] lg:w-[36px] xl:h-[40px] xl:w-[40px] border-[1px] border-[#1C1919] rounded-full text-sm font-medium transition-all duration-500 group-hover:translate-x-[-40px] lg:group-hover:translate-x-[-35px] xl:group-hover:translate-x-[-40px] lg:group-hover:bg-[#FCF5F0] lg:group-hover:text-primary lg:group-hover:scale-90"
                />
              </div>
            </div>
            <div className="relative z-[3] origin-bottom bg-gradient-to-tr from-white to-[#ffffff9e] rounded-xl group shop-by-card-3">
              <div className="relative p-4">
                <div className="aspect-[1/1] object-cover">
                  <img
                    src={shopByCollectionImg3}
                    alt="bangale-1"
                    className="w-full h-full object-cover rounded-xl"
                  />
                </div>
              </div>
              <div className="mb-4 flex flex-row flex-grow justify-center items-center ">
                <button className="text-[#1C1919] py-2 pl-3 pr-3 text-[10px] sm:pl-8 sm:pr-8 sm:text-[16px] md:pl-4 md:pr-4 md:text-[10px] lg:pl-4 lg:pr-4 lg:text-[10px] xl:pl-5 xl:pr-5 xl:text-[13px]  border-[1px] border-[#1C1919] rounded-full font-medium transition-all duration-500 lg:group-hover:pr-16 lg:group-hover:bg-primary lg:group-hover:text-[#FCF5F0] ">
                DIAMOND COLLECTION
                </button>
                <img
                  src={RightArrow}
                  className="p-1 h-[32px] w-[32px] sm:h-[41px] sm:w-[41px] md:h-[32px] md:w-[32px] lg:h-[36px] lg:w-[36px] xl:h-[40px] xl:w-[40px] border-[1px] border-[#1C1919] rounded-full text-sm font-medium transition-all duration-500 group-hover:translate-x-[-40px] lg:group-hover:translate-x-[-35px] xl:group-hover:translate-x-[-40px] lg:group-hover:bg-[#FCF5F0] lg:group-hover:text-primary lg:group-hover:scale-90"
                />
              </div>
            </div>
            <div className="relative z-[4] origin-bottom bg-gradient-to-tr from-white to-[#ffffff9e] rounded-xl group shop-by-card-4">
              <div className="relative p-4">
                <div className="aspect-[1/1] object-cover">
                  <img
                    src={shopByCollectionImg4}
                    alt="bangale-1"
                    className="w-full h-full object-cover rounded-xl"
                  />
                </div>
              </div>
              <div className="mb-4 flex flex-row flex-grow justify-center items-center ">
                <button className="text-[#1C1919] py-2 pl-2 pr-2 text-[8px] sm:pl-8 sm:pr-8 sm:text-[16px] md:pl-4 md:pr-4 md:text-[10px] lg:pl-4 lg:pr-4 lg:text-[10px] xl:pl-5 xl:pr-5 xl:text-[13px]  border-[1px] border-[#1C1919] rounded-full font-medium transition-all duration-500 lg:group-hover:pr-16 lg:group-hover:bg-primary lg:group-hover:text-[#FCF5F0] ">
                ANTIQUE COLLECTION
                </button>
                <img
                  src={RightArrow}
                  className="p-1 h-[32px] w-[32px] sm:h-[41px] sm:w-[41px] md:h-[32px] md:w-[32px] lg:h-[36px] lg:w-[36px] xl:h-[40px] xl:w-[40px] border-[1px] border-[#1C1919] rounded-full text-sm font-medium transition-all duration-500 group-hover:translate-x-[-40px] lg:group-hover:translate-x-[-35px] xl:group-hover:translate-x-[-40px] lg:group-hover:bg-[#FCF5F0] lg:group-hover:text-primary lg:group-hover:scale-90"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="lg:hidden bg-primary">
        <div className="p-4 pb-16">
          <div className="text-bg_1 text-4xl text-center font-[Cormorant] my-2 mt-10">
            OUR COLLECTIONS
          </div>
          <div className="text-bg_1 text-xl text-center font-[Cormorant] my-2 mb-10">
            DAY TO DAY WEAR UNIQUENESS
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <div className="bg-gradient-to-tr from-white via-white to-[#FCF5F0] rounded-xl group">
              <div className="relative">
                <img
                  src={shopByCollectionImg1}
                  alt="bangale-1"
                  className="w-full h-auto pt-2 px-2"
                />
              </div>
              <div className="pb-4 pt-4 flex flex-row flex-grow justify-center items-center ">
                <button className="text-[#1C1919] py-2 pl-2 pr-2 text-[8px] sm:pl-8 sm:pr-8 sm:text-[14px] md:pl-3 md:pr-3 md:text-[9px] lg:pl-6 lg:pr-6 lg:text-[14px] xl:text-[16px]  border-[1px] border-[#1C1919] rounded-full font-medium transition-all duration-500 lg:group-hover:pr-16 lg:group-hover:bg-primary lg:group-hover:text-[#FCF5F0] ">
                  ZIRKON COLLECTION
                </button>
                <img
                  src={RightArrow}
                  className="p-1 h-[32px] w-[32px] sm:h-[41px] sm:w-[41px] md:h-[32px] md:w-[32px] lg:h-[38px] lg:w-[38px] xl:h-[41px] xl:w-[41px] border-[1px] border-[#1C1919] rounded-full text-sm font-medium transition-all duration-500 lg:group-hover:translate-x-[-40px] lg:group-hover:bg-[#FCF5F0] lg:group-hover:text-primary lg:group-hover:scale-90"
                />
              </div>
            </div>
            <div className="bg-gradient-to-tr from-white via-white to-[#FCF5F0] rounded-xl group">
              <div className="relative">
                <img
                  src={shopByCollectionImg2}
                  alt="bangale-1"
                  className="w-full h-auto pt-2 px-2"
                />
              </div>
              <div className=" pb-4 pt-4 flex flex-row flex-grow justify-center items-center ">
                <button className="text-[#1C1919] py-2 pl-2 pr-2 text-[8px] sm:pl-8 sm:pr-8 sm:text-[14px] md:pl-3 md:pr-3 md:text-[9px] lg:pl-6 lg:pr-6 lg:text-[14px] xl:text-[16px]  border-[1px] border-[#1C1919] rounded-full font-medium transition-all duration-500 lg:group-hover:pr-16 lg:group-hover:bg-primary lg:group-hover:text-[#FCF5F0]">
                  KUNDAN COLLECTION
                </button>
                <img
                  src={RightArrow}
                  className="p-1 h-[32px] w-[32px] sm:h-[41px] sm:w-[41px] md:h-[32px] md:w-[32px] lg:h-[38px] lg:w-[38px] xl:h-[41px] xl:w-[41px] border-[1px] border-[#1C1919] rounded-full text-sm font-medium transition-all duration-500 lg:group-hover:translate-x-[-40px] lg:group-hover:bg-[#FCF5F0] lg:group-hover:text-primary lg:group-hover:scale-90"
                />
              </div>
            </div>
            <div className="bg-gradient-to-tr from-white via-white to-[#FCF5F0] rounded-xl group">
              <div className="relative">
                <img
                  src={shopByCollectionImg3}
                  alt="bangale-1"
                  className="w-full h-auto pt-2 px-2"
                />
              </div>
              <div className=" pb-4 pt-4 flex flex-row flex-grow justify-center items-center ">
                <button className="text-[#1C1919] py-2 pl-2 pr-2 text-[8px] sm:pl-8 sm:pr-8 sm:text-[14px] md:pl-3 md:pr-3 md:text-[9px] lg:pl-6 lg:pr-6 lg:text-[14px] xl:text-[16px]  border-[1px] border-[#1C1919] rounded-full font-medium transition-all duration-500 lg:group-hover:pr-16 lg:group-hover:bg-primary lg:group-hover:text-[#FCF5F0]">
                  DIAMOND COLLECTION
                </button>
                <img
                  src={RightArrow}
                  className="p-1 h-[32px] w-[32px] sm:h-[41px] sm:w-[41px] md:h-[32px] md:w-[32px] lg:h-[38px] lg:w-[38px] xl:h-[41px] xl:w-[41px] border-[1px] border-[#1C1919] rounded-full text-sm font-medium transition-all duration-500 lg:group-hover:translate-x-[-40px] lg:group-hover:bg-[#FCF5F0] lg:group-hover:text-primary lg:group-hover:scale-90"
                />
              </div>
            </div>
            <div className="bg-gradient-to-tr from-[white] via-white to-[#FCF5F0] rounded-xl  group">
              <div className="relative">
                <img
                  src={shopByCollectionImg4}
                  alt="bangale-1"
                  className="w-full h-auto pt-2 px-2"
                />
              </div>
              <div className="pb-4 pt-4 flex flex-row flex-grow justify-center items-center ">
                <button className="text-[#1C1919] py-2 pl-2 pr-2 text-[8px] sm:pl-8 sm:pr-8 sm:text-[14px] md:pl-3 md:pr-3 md:text-[9px] lg:pl-6 lg:pr-6 lg:text-[14px] xl:text-[16px]  border-[1px] border-[#1C1919] rounded-full font-medium transition-all duration-500 lg:group-hover:pr-16 lg:group-hover:bg-primary lg:group-hover:text-[#FCF5F0]">
                  ANTIQUE COLLECTION
                </button>
                <img
                  src={RightArrow}
                  className="p-1 h-[32px] w-[32px] sm:h-[41px] sm:w-[41px] md:h-[32px] md:w-[32px] lg:h-[38px] lg:w-[38px] xl:h-[41px] xl:w-[41px] border-[1px] border-[#1C1919] rounded-full text-sm font-medium transition-all duration-500 lg:group-hover:translate-x-[-40px] lg:group-hover:bg-[#FCF5F0] lg:group-hover:text-primary lg:group-hover:scale-90"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopByCollectionBlock;
