import "./App.scss";

import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import Home from "./b2b/home/Home";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { lazy } from "react";
import Header from "./b2b/header/Header";
import Footer, { isValidEmail } from "./b2b/Footer/Footer";

import Favorites from "./b2b/Pages/Favorites/Favorites";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginSignup from "./auth/LoginSignup";
import {
  useCheckPaymentStatusMutation,
  useGetCouponListQuery,
  useGetCustomerBagDataQuery,
  useGetCustomerWishlistQuery,
  useGetHeaderLabelsDataQuery,
  useSubscribeMutation,
} from "./redux/slices/homeSlice";
import { setWishlist } from "./redux/slices/wishlistSlice";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import ProductPage from "./b2b/Pages/Favorites/ProductPage/ProductPage";
import Category from "./b2b/Pages/Category/Category";
import {
  addToMyBag,
  setMyBag,
  setOrderDetails,
} from "./redux/slices/myBagSlice";
import MyBag from "./b2b/Pages/MyBag/MyBag";
import ShoppingBag from "./b2b/Pages/ShoppingBag/ShoppingBag.jsx";
import ShoppingTray from "./b2b/Pages/ShoppingTray/ShoppingTray.jsx";
import OrderPlaced from "./b2b/Pages/OrderPlaced/OrderPlaced.jsx";
import ProfileDetails from "./b2b/Pages/ProfileDetails/ProfileDetails.jsx";
import BTN from "./b2b/Master/BTN.jsx";
import INPUT from "./b2b/Master/INPUT.jsx";
import Sidebar from "./b2b/header/Sidebar.jsx";
import Lens from "./b2b/Pages/Lens/Lens.jsx";
import TrackOrder from "./b2b/Pages/ProfileDetails/TrackOrder.jsx";
import ExchangeOrder from "./b2b/Pages/ProfileDetails/ExchangeOrder.jsx";
import CancelOrder from "./b2b/Pages/ProfileDetails/CancelOrder.jsx";
import ReturnOrder from "./b2b/Pages/ProfileDetails/ReturnOrder.jsx";
import ContactUs from "./b2b/Pages/ContactUs/ContactUs.jsx";
import BecomeRetailer from "./b2b/Pages/ContactUs/BecomeRetailer.jsx";
import Retailer from "./b2b/Pages/Retailer/Retailer.jsx";
import "react-loading-skeleton/dist/skeleton.css";
import RetailerHeader from "./b2b/header/RetailerHeader.jsx";
import AboutUs from "./b2b/Pages/BrandInfo/AboutUs.jsx";
import PrivacyPolicy from "./b2b/Pages/BrandInfo/PrivacyPolicy.jsx";
import TermsCondition from "./b2b/Pages/BrandInfo/TermsCondition.jsx";
import ExchangePolicy from "./b2b/Pages/BrandInfo/ExchangePolicy.jsx";
import ReturnPolicy from "./b2b/Pages/BrandInfo/ReturnPolicy.jsx";
import OrderFailed from "./b2b/Pages/OrderPlaced/OrderFailed.jsx";
import { COMING_SOON_BANNER, PAGE_NOT_FOUND } from "./assets/index.js";
import Skeleton from "react-loading-skeleton";
import { useFormik } from "formik";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import { Breakpoint } from "react-socks";
import DagaHome from "./b2b/home/DagaHome.js";
function App() {
  const location = useLocation();
  const [sidebarToggle, setSidebarToggle] = useState(false);
  // lens screen steps
  const [lensStep, setLensStep] = useState(1);
  const dispatch = useDispatch();
  const user = localStorage.getItem("user");

  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);
  const {
    data: headerLabelsData,
    isSuccess: headerLabelsSuccess,
    isLoading: headerLabelsLoading,
    isError: headerLabelsIsError,
    error: headerLabelsError,
  } = useGetHeaderLabelsDataQuery();

  const {
    data: wishlistData,
    isSuccess: wishlistSuccess,
    isLoading: wishlistLoading,
    isError: wishlistIsError,
    error: wishlistError,
  } = useGetCustomerWishlistQuery({ user: user }, { skip: !user });

  useEffect(() => {
    if (wishlistSuccess) {
      const data = wishlistData.wishlist;
      dispatch(setWishlist(data));
    } else if (wishlistIsError) {
      dispatch(setWishlist([]));
    }
  }, [wishlistData, wishlistSuccess, wishlistIsError]);

  const {
    data: customerBagData,
    isSuccess: customerBagIsSuccess,
    isLoading: customerBagIsLoading,
    isError: customerBagIsError,
    error: customerBagError,
  } = useGetCustomerBagDataQuery({ user: user }, { skip: !user });

  const {
    data: couponData,
    isSuccess: couponDataIsSuccess,
    isLoading: couponDataIsLoading,
    isError: couponDataIsError,
    error: couponDataError,
  } = useGetCouponListQuery({ user: user, couponType: "" }, { skip: !user });

  const findAutoAppliedCouponData = (
    couponArray,
    couponCode,
    minimumAmount
  ) => {
    const data = couponArray?.find((item) => {
      return (
        minimumAmount > item?.minimumAmount &&
        item?.couponCode === couponCode &&
        item
      );
    });
    return data;
  };

  const calculateCartTotalWithoutOffer = () => {
    return customerBagData?.cart?.reduce((total, item) => {
      return total + item?.prices?.originalPrice * item?.quantity;
    }, 0);
  };

  useEffect(() => {
    if (customerBagIsSuccess) {
      const data = customerBagData.cart;
      dispatch(setMyBag(data));
      dispatch(
        setOrderDetails({
          couponCode: findAutoAppliedCouponData(
            couponData,
            customerBagData?.appliedCoupon,
            calculateCartTotalWithoutOffer()
          ),
        })
      );
    } else if (customerBagError && user !== null) {
      dispatch(setMyBag([]));
    }
  }, [customerBagData, customerBagIsSuccess, customerBagIsError, couponData]);

  const [isRetailerPage, setIsRetailerPage] = useState(false);

  useEffect(() => {
    setIsRetailerPage(false);
  }, [location.pathname]);

  const eyeFramesRef = useRef(null);
  const newArrivalsRef = useRef(null);
  const ourStoreRef = useRef(null);
  const contactUsRef = useRef(null);

  // ===================== CLEAR WISHLIST AND CART ON LOGOUT ===================

  useEffect(() => {
    if (!user) {
      dispatch(setMyBag([]));
      dispatch(setWishlist([]));
    }
  }, [user]);

  // user subscribe email

  const [subscribe] = useSubscribeMutation();
  const subscribeFormik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async function (values) {
      subscribe({
        body: {
          email: values?.email,
        },
      }).then((res) => {
        if (res?.data) {
          subscribeFormik?.resetForm();
          toast.success(res?.data.message);
        } else {
          console.log(res);
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    },
    validate: (values) => {
      let errors = {};
      if (!values?.email) {
        errors.email = "Email id is Required!";
      } else if (!isValidEmail(values?.email)) {
        errors.email = "Email is not valid!";
      }
      return errors;
    },
  });

  // ========================== count down timer ==========================
  const navigate = useNavigate();
  const finalDate = new Date("2024-09-12T17:46:00").getTime();
  const [timeLeft, setTimeLeft] = useState(finalDate - new Date().getTime());
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTimeLeft(finalDate - new Date().getTime());
  //     setDays(Math.floor(timeLeft / (1000 * 60 * 60 * 24)));
  //     setHours(Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
  //     setMinutes(Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)));
  //     setSeconds(Math.floor((timeLeft % (1000 * 60)) / 1000));
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, [timeLeft]);

  useEffect(() => {
    if (timeLeft > 0) {
      navigate("/");
    }
  },[timeLeft]);
  if (timeLeft > 0) {
  return (
    <div className="relative h-[100dvh] w-[100vw]">
      <img className="absolute top-0 left-0 h-full w-full object-cover -z-1" src={COMING_SOON_BANNER} alt="Coming Soon" />
      <div className="absolute top-2 emd:top-6 right-2 emd:right-6 text-[#fcf8c2] fix_lg:text-6xl sm:text-3xl text-4xl    text-right mont-light">GET READY TO <br/> BE NOTICED</div>
      <div className="flex flex-col gap-4 emd:gap-4 h-full w-full p-2 emd:w-1/2 justify-center items-center ms-0 emd:ms-14">
            <img
                className="h-[60px] emd:h-[70px]"
                src="/Netre-logo.svg"
                alt="Netre"
              />
              <div className="text-center text-2xl">India's Premier destination <br />for Quality Eyewear</div>
              <div className="my-2">
                <Breakpoint large up>
                  <FlipClockCountdown
                    to={finalDate}
                    labels={['DAYS', 'HOURS', 'MINUTES', 'SECONDS']}
                    labelStyle={{ fontSize: 10, fontWeight: 500, textTransform: 'uppercase', }}
                    digitBlockStyle={{ width: 40, height: 50, fontWeight: 500, fontSize: 36, backgroundColor: '#e9d193' }}
                    dividerStyle={{ color: '#ffffff66', height: 1 }}
                    showSeparators={false}
                    duration={0.5}
                    spacing={0}
                  >
                    Finished
                  </FlipClockCountdown>
                </Breakpoint>
                <Breakpoint medium down>
                  <FlipClockCountdown
                    to={finalDate}
                    labels={['DAYS', 'HOURS', 'MINUTES', 'SECONDS']}
                    labelStyle={{ fontSize: 8, fontWeight: 500, textTransform: 'uppercase', }}
                    digitBlockStyle={{ width: 32, height: 42, fontWeight: 500, fontSize: 24, backgroundColor: '#e9d193' }}
                    dividerStyle={{ color: '#ffffff66', height: 1 }}
                    showSeparators={false}
                    duration={0.5}
                    spacing={0}
                  >
                    Finished
                  </FlipClockCountdown>
                </Breakpoint>
              </div>
          <div className="text-[#fcf8c2] text-[30px] emd:text-[40px] text-center mont-regular my-2">SOMETHING AMAZING <br/> IS COMING SOON!</div>
            <input className="min-w-[290px] border placeholder-secondary placeholder:text-center border-[#ffffffcc] bg-[#ffffff66] px-6 emd:px-8 py-2 emd:py-4 rounded-lg cursor-pointer"  
            type="text" 
            placeholder="ENTER EMAIL ADDRESS"
            name="email"
            onChange={subscribeFormik.handleChange}
            value={subscribeFormik.values.email}
            onBlur={subscribeFormik.handleBlur}
            errorMsg={subscribeFormik.errors.email}
            touched={subscribeFormik.touched.email}
            />
              {subscribeFormik.errors.email && subscribeFormik.touched && !subscribeFormik.isDisabled ? (
                <span className={`text-xs text-red-600`}>{subscribeFormik.errors.email}</span>
              ) : (
                <span className={`mb-[0px]`}></span>
              )}
          <div className=" border border-[#ffffffcc] bg-[#ffffff66] px-10 emd:px-10 py-2 rounded-lg cursor-pointer"
          onClick={subscribeFormik.handleSubmit}
          >REGISTER TO GET 50% OFF <br/>
          ON YOUR FIRST PURCHASE</div>
      </div>
      {/* <div>
        <h1 className="text-7xl">{days} : {hours} : {minutes} : {seconds}</h1>
        <h1 className="text-3xl">Coming Soon</h1>
        <div className="w-full flex flex-col mt-3 pr-8">
        <INPUT
          placeHolder={"| Your Email"}
          className={"px-2"}
          lComp={
            <div className="material-symbols-outlined clear_ic text-2xl me-1 text-secondary">
              mail
            </div>
          }
          rComp={
            <span
              className="text-base"
              onClick={subscribeFormik.handleSubmit}
            >
              SUBMIT
            </span>
          }
          name="email"
          onChange={subscribeFormik.handleChange}
          val={subscribeFormik.values.email}
          onBlur={subscribeFormik.handleBlur}
          errorMsg={subscribeFormik.errors.email}
          touched={subscribeFormik.touched.email}
        />
      </div>
      </div> */}
      <ToastContainer
        position="top-center"
        autoClose={500}
        theme="dark"
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
        transition={Bounce}
        toastStyle={{
          opacity: 0.9,
        }}
      />
    </div>
  )}else{
  return (
    <div
      // className={`App ${
      //   location.pathname.includes("retailer") &&
      //   location.pathname !== "/become-retailer"
      //     ? "pt-[68px]"
      //     : "lg:pt-[140px] emd:pt-[70px] small:pt-[70px]"
      // } `}
    >
      {/* {location.pathname.includes("retailer") &&
      location.pathname !== "/become-retailer" ? (
        <RetailerHeader
          eyeFramesRef={eyeFramesRef}
          newArrivalsRef={newArrivalsRef}
          ourStoreRef={ourStoreRef}
          contactUsRef={contactUsRef}
        />
      ) : (
        <Header
          isRetailerPage={isRetailerPage}
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
          headerLabelsData={headerLabelsData}
        />
      )} */}
      <Header
          isRetailerPage={isRetailerPage}
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
          headerLabelsData={headerLabelsData}
        />
      {/* {isRetailerPage ? (
        <RetailerHeader />
      ) : (
        <Header
          isRetailerPage={isRetailerPage}
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
          headerLabelsData={headerLabelsData}
        />
      )} */}
      {/* <Header
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
          headerLabelsData={headerLabelsData}
        /> */}
      <MyBag setLensStep={setLensStep} />
      <Sidebar
        sidebarToggle={sidebarToggle}
        setSidebarToggle={setSidebarToggle}
        headerLabelsData={headerLabelsData}
      />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              {/* <Home /> */}
              <DagaHome />
            </>
          }
        />
        {/* <Route
          exact
          path="/:retailer"
          element={
            <>
              <Retailer setIsRetailerPage={setIsRetailerPage} />
            </>
          }
        /> */}
        {/* <Route
          exact
          path="/retailer/:retailerName"
          element={
            <>
              <Retailer
                setIsRetailerPage={setIsRetailerPage}
                eyeFramesRef={eyeFramesRef}
                newArrivalsRef={newArrivalsRef}
                ourStoreRef={ourStoreRef}
                contactUsRef={contactUsRef}
              />
            </>
          }
        /> */}
        <Route
          exact
          path="/products/:id"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <ProductPage />
            </Suspense>
          }
        />
        <Route
          path="/categories/:name"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <Category />
              </Suspense>
            </>
          }
        />
        <Route
          path="/search/:searchProductParams?"
          element={
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <Category />
              </Suspense>
            </>
          }
        />
        <Route
          path="/favourites"
          element={
            <>
              <Favorites />
            </>
          }
        />
        {/* <Route
          path="/shopping-bag"
          element={<ShoppingBag setLensStep={setLensStep} />}
        /> */}
        <Route path="/profile-details" element={<ProfileDetails />} />
        {/* <Route path="/order-check/:orderId" element={<OrderCheck />} />
        <Route path="/order-placed/:orderId" element={<OrderPlaced />} />
        <Route path="/order-failed/:orderId" element={<OrderFailed />} />
        <Route path="/shopping-tray" element={<ShoppingTray />} />
        <Route
          path="/track-order/:trackOrderId/:trackProductSku"
          element={<TrackOrder />}
        />
        <Route
          path="/exchange-order/:exchangeOrderId/:exchangeProductId/:exchangeProductSku"
          element={<ExchangeOrder />}
        />
        <Route
          path="/cancel-order/:cancelOrderId/:cancelProductId"
          element={<CancelOrder />}
        />
        <Route
          path="/return-order/:returnOrderId/:returnProductId/:returnProductSku"
          element={<ReturnOrder />}
        />
        <Route
          path="/shopping-tray/confirm"
          element={
            <>
              <div className="">Confirmation page</div>
            </>
          }
        /> */}

        <Route path="/login" element={<LoginSignup />} />
        <Route path="/signup" element={<LoginSignup />} />
        {/* <Route
          path="/lens"
          element={<Lens step={lensStep} setStep={setLensStep} />}
        /> */}
        <Route path="/contact-us" element={<ContactUs />} />
        {/* <Route path="/become-retailer" element={<BecomeRetailer />} /> */}
        <Route path="/aboutus" element={<AboutUs />} />
        {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/return-policy" element={<ReturnPolicy />} />
        <Route path="/exchange-policy" element={<ExchangePolicy />} />
        <Route path="/terms-condition" element={<TermsCondition />} /> */}

        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {/* {location.pathname !== "/become-retailer" &&
      location.pathname.includes("retailer") ? null : (
        <Footer />
      )} */}
      <Footer />

      <ToastContainer
        position="top-center"
        autoClose={500}
        theme="dark"
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
        transition={Bounce}
        toastStyle={{
          opacity: 0.9,
        }}
      />
    </div>
  );}
}

export default App;

function scrollToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "instant",
  });
  // scrooll .App to top
  const scrollArea = document.querySelector(".App");
  if (!scrollArea) return;
  scrollArea.scrollTo({
    top: 0,
    left: 0,
    behavior: "instant",
  });
}

export const PageNotFound = () => {
  return (
    <Link
      to="/"
      style={{
        width: "100%",
        height: "70vh",
        objectFit: "contain",
        display: "block",
      }}
    >
      <img
        style={{
          height: "100%",
          width: "100%",
          verticalAlign: "middle",
          objectFit: "contain",
        }}
        src={PAGE_NOT_FOUND}
        alt="PAGE NOT FOUND"
      />
    </Link>
  );
};

const OrderCheck = () => {
  const { orderId } = useParams();
  const [checkPaymentStatus] = useCheckPaymentStatusMutation();
  const navigate = useNavigate();
  useEffect(() => {
    if (orderId) {
      console.log(orderId);
      const body = {
        order_id: orderId,
      };
      checkPaymentStatus({ body: body })
        .then((res) => {
          if (res.data.status == "Placed") {
            navigate(`/order-placed/${orderId}`);
          } else {
            navigate(`/order-failed/${orderId}`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [orderId]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9999999,
        width: "100vw",
        maxWidth: "100%",
        height: "100dvh",
        overflow: "hidden",
      }}
    >
      <Skeleton height={"100%"} />
      <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2">
        <p>
          Please do not go back or refresh the page. Your payment is being
          processed...
        </p>
      </div>
    </div>
  );
};
