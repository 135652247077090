import React from "react";

const RightArrow = ({ color = "#1C1919", height = "20", width = "35" }) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 35 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8_2)">
          <path
            d="M24.6321 0.657325C24.7345 2.85064 25.6225 4.93393 27.1338 6.52673C28.6451 8.11953 30.679 9.11557 32.8639 9.33293C33.2526 9.37034 33.6432 9.3947 34.0336 9.41307C34.2408 9.42156 34.4331 9.37527 34.4297 9.70643C34.4297 10.0575 34.2031 9.97235 34.0183 9.9845C32.4286 10.0254 30.8751 10.4677 29.5023 11.2702C28.1294 12.0726 26.9818 13.2092 26.166 14.5743C25.4077 15.8163 24.9883 17.2354 24.9496 18.6901C24.9403 18.9074 24.7862 19.08 24.646 19.0724C24.5059 19.0648 24.3769 18.8349 24.388 18.6607C24.4581 16.8844 25.0281 15.164 26.0325 13.6973C27.0369 12.2307 28.4349 11.0773 30.0657 10.3698C30.2324 10.2879 30.4022 10.214 30.5688 10.1342C30.5748 10.1341 30.5683 10.0992 30.5601 10.0343L1.18715 10.5336C1.03815 10.5362 0.901979 10.5285 0.754149 10.541C0.526438 10.5619 0.513321 10.261 0.513321 10.261C0.513321 10.261 0.532408 9.97159 0.74468 9.98399C0.892867 9.99247 1.04468 9.97889 1.19168 9.97639L30.7027 9.4747C26.6887 7.77643 24.3158 4.99895 24.0721 0.665845C24.1437 0.595933 24.2365 0.551784 24.336 0.540323C24.4432 0.550898 24.5454 0.591067 24.6311 0.656342L24.6321 0.657325Z"
            fill={color}
            stroke={color}
            strokeWidth="0.700101"
          />
        </g>
        <defs>
          <clipPath id="clip0_8_2">
            <rect width="34.945" height="19.832" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default RightArrow;
