import React, { useEffect, useState } from "react";
import BTN from "../../Master/BTN";
import INPUT from "../../Master/INPUT";
import { useFormik } from "formik";
import MODEL from "../../Master/MODEL";
import {
  useAddAddressMutation,
  useDeleteAddressMutation,
  useDeletePrescriptionMutation,
  useGetPrescriptionListQuery,
  useGetUserProfileQuery,
  useSubscribeMutation,
  useUpdateAddressMutation,
  useUpdateUserProfileMutation,
  useAddPrescriptionMutation,
} from "../../../redux/slices/homeSlice";
import { toast } from "react-toastify";
import { isValidEmail } from "../../Footer/Footer";
import { ADD_HOME_IC, CALL_IC, DELETE_IC, EDIT_IC } from "../../../assets";

const Account = () => {
  const user = localStorage.getItem("user");
  const [selectedMyPrescription, setSelectedMyMyPrescription] = useState(null);
  const [prescriptionToggle, setAddPrescriptionToggle] = useState(false);

  // --------------------- PROFILE DATA API CALLING ----------------------

  const {
    data: customerProfileData,
    isSuccess: customerProfileDataIsSuccess,
    isLoading: customerProfileDataIsLoading,
    isError: customerProfileDataIsError,
    error: customerProfileDataError,
  } = useGetUserProfileQuery({ user: user }, { skip: !user });

  const [updateUserProfile] = useUpdateUserProfileMutation();

  const accountFormik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
    },
    onSubmit: async function (values) {
      updateUserProfile({
        body: {
          firstname: values?.first_name,
          lastname: values?.last_name,
          email: values?.email,
          phone: values?.phone,
          addresses: customerProfileData?.customer?.addresses,
          customerGroup: customerProfileData?.customer?.customerGroup,
        },
        id: customerProfileData?.customer?._id,
        user: localStorage.getItem("user"),
      }).then((res) => {
        if (res?.data) {
          toast.success(`Details saved successfully.`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    },
    validate: (values) => {
      let errors = {};
      if (!values?.first_name) {
        errors.first_name = "Full Name is Required!";
      } else if (!values?.last_name) {
        errors.last_name = "Last Name is Required!";
      } else if (!values?.phone) {
        errors.phone = "Phone Number is Required!";
      }
      return errors;
    },
  });

  useEffect(() => {
    if (customerProfileDataIsSuccess) {
      const { customer } = customerProfileData;
      accountFormik.setFieldValue("first_name", customer?.firstname);
      accountFormik.setFieldValue("last_name", customer?.lastname);
      accountFormik.setFieldValue("email", customer?.email);
      accountFormik.setFieldValue("phone", customer?.phone);
      // accountFormik.setFieldValue("coupon_id", customer?.coupon_id);
    }
  }, [customerProfileDataIsSuccess]);

  // ------------------------- ADDRESS API -------------------------------

  const [addAddressToggle, setAddAddressToggle] = useState(false);
  const [deleteAddressConf, setDeleteAddressConf] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [editAddressToggle, setEditAddressToggle] = useState(false);

  const [addAddress] = useAddAddressMutation();
  const [updateAddress] = useUpdateAddressMutation();

  const addressFormik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone_no: "",
      address_1: "",
      address_2: "",
      city: "",
      pin_code: "",
      save_as: "",
    },
    onSubmit: async function (values) {
      if (!editAddressToggle) {
        addAddress({
          body: {
            firstname: values?.first_name,
            lastname: values?.last_name,
            phone: values?.phone_no,
            address1: values?.address_1,
            address2: values?.address_2,
            city: values?.city,
            zipCode: values?.pin_code,
            country: values?.country,
            label: values?.save_as,
          },
          user: localStorage.getItem("user"),
        }).then((res) => {
          if (res?.data) {
            toast.success(`Address added successfully.`);
            closeAddAddressHandle();
          } else {
            toast.error(`${res?.error?.data?.message}`);
          }
        });
      } else {
        updateAddress({
          body: {
            firstname: values?.first_name,
            lastname: values?.last_name,
            phone: values?.phone_no,
            address1: values?.address_1,
            address2: values?.address_2,
            city: values?.city,
            zipCode: values?.pin_code,
            country: values?.country,
            label: values?.save_as,
          },
          id: selectedAddress?._id,
          user: localStorage.getItem("user"),
        }).then((res) => {
          if (res?.data) {
            toast.success(`Address updated successfully.`);
            closeAddAddressHandle();
            setSelectedAddress(null);
          } else {
            toast.error(`${res?.error?.data?.message}`);
          }
        });
      }
    },
    validate: (values) => {
      let errors = {};
      if (!values?.first_name) {
        errors.first_name = "First Name is Required!";
      } else if (!values?.last_name) {
        errors.last_name = "Last Name is Required!";
      } else if (!values?.phone_no) {
        errors.phone_no = "Phone Number is Required!";
      } else if (!/^[0-9]+$/i.test(values.phone_no)) {
        errors.phone_no = "Invalid Phone Number";
      } else if (values.phone_no.length !== 10) {
        errors.phone_no = "Phone Number must be 10 digits";
      } else if (!values?.address_1) {
        errors.address_1 = "Address 1 is Required!";
      } else if (!values?.address_2) {
        errors.address_2 = "Address 2 is Required!";
      } else if (!values?.city) {
        errors.city = "City is Required!";
      } else if (!values?.country) {
        errors.country = "Country is Required!";
      } else if (!values?.pin_code) {
        errors.pin_code = "Pin Code is Required!";
      } else if (!/^[0-9]+$/i.test(values.pin_code)) {
        errors.pin_code = "Invalid Pin Code";
      } else if (values.pin_code.length !== 6) {
        errors.pin_code = "Pin Code must be 6 digits";
      } else if (!values?.save_as) {
        errors.save_as = "Save As is Required!";
      }
      return errors;
    },
  });

  const editAddressHandle = (value) => {
    setAddAddressToggle(true);
    setEditAddressToggle(true);
    setSelectedAddress(value);
    addressFormik.setFieldValue("first_name", value?.firstname);
    addressFormik.setFieldValue("last_name", value?.lastname);
    addressFormik.setFieldValue("phone_no", value?.phone);
    addressFormik.setFieldValue("address_1", value?.address1);
    addressFormik.setFieldValue("address_2", value?.address2);
    addressFormik.setFieldValue("city", value?.city);
    addressFormik.setFieldValue("country", value?.country);
    addressFormik.setFieldValue("pin_code", value?.zipCode);
    addressFormik.setFieldValue("save_as", value?.label);
  };

  const closeAddAddressHandle = () => {
    setAddAddressToggle(false);
    setEditAddressToggle(false);
    setSelectedAddress(null);
    addressFormik.handleReset();
  };
  const [deleteAddress, { isLoading, isSuccess, isError, error }] =
    useDeleteAddressMutation();

  const deleteAddressHandle = () => {
    deleteAddress({ user, id: selectedAddress?._id }).then((res) => {
      console.log(res);
      if (res.data.message) {
        toast.success("Address deleted successfully");
        setSelectedAddress(null);
        setDeleteAddressConf(false);
      }
      if (res.data.error) {
        toast.error(res.data.error);
      }
    });
  };

  const {
    data: prescriptionListData,
    isSuccess: prescriptionListDataIsSuccess,
    isLoading: prescriptionListDataIsLoading,
    isError: prescriptionListDataIsError,
    error: prescriptionListDataError,
  } = useGetPrescriptionListQuery({ user: user }, { skip: !user });

  // ---------------------- DELETE PRESCRIPTION FROM MY ACCOUNT --------------------------

  const [deletePrescription] = useDeletePrescriptionMutation();
  const [deletePrescriptionId, setDeletePrescriptionId] = useState("");

  const deletePrescriptionHandle = () => {
    deletePrescription({
      user: localStorage.getItem("user"),
      id: deletePrescriptionId,
    }).then((res) => {
      if (res?.data?.message) {
        toast.success("Address deleted successfully");
        setDeletePrescriptionId("");
      }
      if (res.data.error) {
        toast.error(res.data.error);
      }
    });
  };

  // -------------------------------- ADD PRESCRIPTION HANDLE --------------------------------

  const [addPrescription] = useAddPrescriptionMutation();

  const prescriptionFormik = useFormik({
    initialValues: {
      name: "",
      lensType: "",
      rightEye: {
        right_sph: "",
        right_cyl: "",
        right_axis: "",
        right_pd: "",
        right_add: "",
      },
      leftEye: {
        left_sph: "",
        left_cyl: "",
        left_axis: "",
        left_pd: "",
        left_add: "",
      },
    },
    onSubmit: async function (values) {
      addPrescription({
        body: {
          name: values?.name,
          lensType: "lens",
          rightEye: {
            sphere: values?.right_sph,
            cylinder: values?.right_cyl,
            axis: values?.right_axis,
            pd: values?.right_pd,
            add: values?.right_add,
          },
          leftEye: {
            sphere: values?.left_sph,
            cylinder: values?.left_cyl,
            axis: values?.left_axis,
            pd: values?.left_pd,
            add: values?.left_add,
          },
        },
        user: localStorage.getItem("user"),
        id: selectedMyPrescription?._id ? selectedMyPrescription?._id : null,
      }).then((res) => {
        if (res?.data) {
          prescriptionFormik.resetForm();
          toast.success(`Prescription added successfully.`);
          setAddPrescriptionToggle(false);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    },
    validate: (values) => {
      let errors = {};
      if (!values?.name) {
        errors.name = "Name is Required!";
      } else if (!values?.right_sph) {
        errors.right_sph = "SPH is Required!";
      } else if (!values?.right_cyl) {
        errors.right_cyl = "CYL is Required!";
      } else if (!values?.right_axis) {
        errors.right_axis = "Axis is Required!";
      } else if (!values?.left_sph) {
        errors.left_sph = "SPH is Required!";
      } else if (!values?.left_cyl) {
        errors.left_cyl = "CYL is Required!";
      } else if (!values?.left_axis) {
        errors.left_axis = "Axis is Required!";
      }

      return errors;
    },
  });

  const selectMyPrescriptionHandle = (item) => {
    prescriptionFormik.setFieldValue("name", item?.name || "");
    prescriptionFormik.setFieldValue("lensType", item?.lensType || "");
    prescriptionFormik.setFieldValue(
      "additionalInfo",
      item?.additionalInfo || ""
    );
    prescriptionFormik.setFieldValue("right_sph", item?.rightEye?.sphere || "");
    prescriptionFormik.setFieldValue(
      "right_cyl",
      item?.rightEye?.cylinder || ""
    );
    prescriptionFormik.setFieldValue("right_axis", item?.rightEye?.axis || "");
    prescriptionFormik.setFieldValue("right_pd", item?.rightEye?.pd || "");
    prescriptionFormik.setFieldValue("right_add", item?.rightEye?.add || "");
    prescriptionFormik.setFieldValue("left_sph", item?.leftEye?.sphere || "");
    prescriptionFormik.setFieldValue("left_cyl", item?.leftEye?.cylinder || "");
    prescriptionFormik.setFieldValue("left_axis", item?.leftEye?.axis || "");
    prescriptionFormik.setFieldValue("left_pd", item?.leftEye?.pd || "");
    prescriptionFormik.setFieldValue("left_add", item?.leftEye?.add || "");
  };

  // const [isSubscribe, setIsSubscribe] = useState(false);

  // const [subscribe] = useSubscribeMutation();

  // const subscribeFormik = useFormik({
  //   initialValues: {
  //     email: "",
  //   },
  //   onSubmit: async function (values) {
  //     subscribe({
  //       body: {
  //         email: values?.email,
  //       },
  //       user: localStorage.getItem("user"),
  //     }).then((res) => {
  //       if (res?.data) {
  //         subscribeFormik?.resetForm();
  //         toast.success(res?.data.message);
  //       } else {
  //         toast.error(`${res?.error?.data?.message}`);
  //       }
  //     });
  //   },
  //   validate: (values) => {
  //     let errors = {};
  //     if (!values?.email) {
  //       errors.email = "Email id is Required!";
  //     } else if (!isValidEmail(values?.email)) {
  //       errors.email = "Email is not valid!";
  //     }
  //     return errors;
  //   },
  // });

  // useEffect(() => {
  //   if (accountFormik.values.email) {
  //     subscribeFormik?.setFieldValue("email", accountFormik.values.email);
  //   }
  // }, [accountFormik.values.email]);

  // const handleSubscription = (checked) => {
  //   if (accountFormik.values.email) {
  //     setIsSubscribe(checked);
  //     subscribeFormik.handleSubmit();
  //   }
  // };

  return (
    <div className="w-full overflow-hidden flex flex-col">
      <div className="w-full flex flex-col lg:justify-start emd:justify-center small:justify-center">
        <span className="text-primary text-3xl lg:text-start emd:text-center small:text-center">
          Hello {customerProfileData?.customer?.firstname} !
        </span>
        {/* <div className="w-full flex items-center lg:justify-start emd:justify-center small:justify-center">
          <span className="text-center lg:text-xl emd:text-sm small:text-xs text-nowrap">
            Thankyou for registering. Welcome to
          </span>
          <img
            src="./Netre-logo.svg"
            alt=""
            className="lg:w-12 emd:10 small:w-8 ms-1.5 mb-1"
          />
        </div> */}
      </div>
      <div className="w-full flex flex-col mt-5">
        <div className="lg:hidden emd:flex small:flex flex-col w-full h-46 mb-7 p-6 items-center justify-between bg-[#FCF5CC] ">
          {/* <div className="w-full mb-2 flex justify-end">
            <div className="material-symbols-outlined text-white p-1.5 rounded-full bg-[#AC772B]">
              ios_share
            </div>
          </div> */}
          <div className="flex flex-col items-start">
            <span className="text-3xl font-bold">Refer a friend</span>
            <span className="text-xl">& get 10% Off on your next order</span>
            <span className="text-sm">Terms and Conditions apply*</span>
          </div>
          <div className="border-dashed border mt-6 text-primary text-lg font-bold border-primary py-1 px-2">
            {customerProfileData?.customer?.firstname +
              "_" +
              customerProfileData?.customer?.lastname}
          </div>
        </div>
        <div className="flex items-center justify-between pb-4 border-b border-light_secondary">
          <span className="text-2xl font-bold">Your Account</span>
          <BTN
            bordered
            title="Save"
            className="w-fit py-0 px-4"
            type={"submit"}
            onP={accountFormik.handleSubmit}
          />
        </div>
        <div className="w-full mt-5 flex lg:flex-row emd:flex-col small:flex-col flex-wrap items-center justify-between">
          <INPUT
            title="First Name"
            mainClass="lg:w-[32%] emd:w-full small:w-full"
            className="w-full"
            name="first_name"
            onChange={accountFormik.handleChange}
            val={accountFormik.values.first_name}
            onBlur={accountFormik.handleBlur}
            errorMsg={accountFormik.errors.first_name}
            touched={accountFormik.touched.first_name}
          />
          <INPUT
            title="Last Name"
            mainClass="lg:w-[32%] emd:w-full small:w-full lg:mt-0 emd:mt-3 small:mt-3 "
            className="w-full"
            name="last_name"
            onChange={accountFormik.handleChange}
            val={accountFormik.values.last_name}
            onBlur={accountFormik.handleBlur}
            errorMsg={accountFormik.errors.last_name}
            touched={accountFormik.touched.last_name}
          />
          {/* <INPUT
            title="Coupon ID"
            mainClass="lg:w-[32%] emd:w-full small:w-full lg:mt-0 emd:mt-3 small:mt-3"
            className="w-full"
            name="coupon_id"
            onChange={accountFormik.handleChange}
            val={accountFormik.values.coupon_id}
            onBlur={accountFormik.handleBlur}
            errorMsg={accountFormik.errors.coupon_id}
            touched={accountFormik.touched.coupon_id}
          /> */}
          <INPUT
            title="Email Address"
            // mainClass="lg:w-[48%] emd:w-full small:w-full lg:mt-0 emd:mt-3 small:mt-3"
            mainClass="lg:w-[32%] emd:w-full small:w-full lg:mt-0 emd:mt-3 small:mt-3"
            className="w-full"
            name="email"
            type="email"
            onChange={accountFormik.handleChange}
            val={accountFormik.values.email}
            onBlur={accountFormik.handleBlur}
            errorMsg={accountFormik.errors.email}
            touched={accountFormik.touched.email}
          />
        </div>
        <div className="w-full flex emd:mt-0 small:mt-0 lg:flex-row emd:flex-col small:flex-col flex-wrap items-center justify-between">
          <INPUT
            title="Phone Number"
            // mainClass="lg:w-[48%] emd:w-full small:w-full lg:mt-0 emd:mt-3 small:mt-3"
            mainClass="lg:w-[32%] emd:w-full small:w-full lg:mt-0 emd:mt-3 small:mt-3 text-light_secondary"
            className="w-full text-light_secondary"
            name="phone"
            onChange={accountFormik.handleChange}
            val={accountFormik.values.phone}
            isDisabled={true}
            onBlur={accountFormik.handleBlur}
            errorMsg={accountFormik.errors.phone}
            touched={accountFormik.touched.phone}
          />
          {/* <div className="w-full mt-5 flex lg:items-center emd:items-start small:items-start flex-row flex-wrap">
            <input
              type="checkbox"
              id="email_services"
              className="accent-secondary w-4 h-4 cursor-pointer"
              checked={isSubscribe}
              value={isSubscribe}
              onChange={(e) => handleSubscription(e.target.checked)}
            />
            <label
              htmlFor="email_services"
              className="ms-2 select-none min-w-[90%] max-w-[90%] cursor-pointer"
            >
              I would like to receive emails from Netre about their products and
              services.
            </label>
          </div> */}
          <div className="w-full flex mt-5 lg:flex-row emd:flex-col small:flex-col flex-wrap items-center justify-between pb-5 border-b border-light_secondary">
            <div className="w-full flex items-center justify-between pb-4 border-b border-light_secondary">
              <span className="text-2xl font-bold">Shipping Address</span>
            </div>
            <div className="w-full grid lg:grid-cols-3 emd:grid-cols-2 small:grid-cols-1  auto-rows-auto gap-4 mt-5">
              <div
                onClick={() => setAddAddressToggle(true)}
                className="bg-gray cursor-pointer select-none h-52 w-full flex flex-col items-center justify-center "
              >
                <div className="material-symbols-outlined">
                  <img src={ADD_HOME_IC} alt="" />
                </div>
                <span className="mt-2">Add Address</span>
              </div>
              {customerProfileData?.customer?.addresses?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="bg-gray p-4 overflow-hidden cursor-pointer select-none h-52 w-full flex flex-col justify-between "
                  >
                    <div className="w-full h-12 overflow-hidden flex justify-between items-center text-ellipsis">
                      <span className="text-lg h-full w-full overflow-hidden font-bold text-ellipsis">
                        {item?.firstname + " " + item?.lastname}
                        {item?.label && ` (${item?.label})`}
                      </span>
                      <div
                        className="material-symbols-outlined"
                        onClick={() => {
                          setDeleteAddressConf(true);
                          setSelectedAddress(item);
                        }}
                      >
                        <img src={DELETE_IC} alt="" className="w-6" />
                      </div>
                    </div>
                    <span className="text-base h-12 overflow-hidden text-ellipsis">
                      {item?.address1} <br />
                      {item?.address2}
                    </span>
                    <div className="flex items-center">
                      <div className="material-symbols-outlined">
                        <img src={CALL_IC} alt="" className="w-5" />
                      </div>
                      <span className="ms-1">{item?.phone}</span>
                    </div>
                    <div
                      className="text-primary underline"
                      onClick={() => editAddressHandle(item)}
                    >
                      Edit
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* <div className="w-full flex mt-10 lg:flex-row emd:flex-col small:flex-col flex-wrap items-center justify-between">
            <div className="w-full flex items-center justify-between pb-4 border-b border-light_secondary">
              <span className="text-2xl font-bold">My Prescriptions</span>
            </div>
            <div className="w-full grid lg:grid-cols-3 emd:grid-cols-2 small:grid-cols-1  auto-rows-auto gap-4 mt-5">
              <div
                onClick={() => setAddPrescriptionToggle(true)}
                className="bg-gray cursor-pointer select-none w-full flex flex-col items-center justify-center "
              >
                <div className="material-symbols-outlined">+</div>
                <span className="mt-2">Add Prescription</span>
              </div>
              {prescriptionListData?.lensPrescription?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={
                      "bg-gray overflow-hidden p-4 cursor-pointer select-none w-full flex flex-col"
                    }
                  >
                    <div className="w-full flex items-center justify-between pb-2 border-b border-light_secondary">
                      <div>
                        <span className="text-base font-bold">{index + 1}</span>
                      </div>
                      <div className="flex items-center">
                        <img
                          src={EDIT_IC}
                          alt=""
                          className="w-5"
                          onClick={() => {
                            setAddPrescriptionToggle(true);
                            setSelectedMyMyPrescription(item);
                            selectMyPrescriptionHandle(item);
                          }}
                        />
                        <img
                          src={DELETE_IC}
                          alt=""
                          className="w-5 ms-3"
                          onClick={() => {
                            setDeletePrescriptionId(item?._id);
                          }}
                        />
                      </div>
                    </div>
                    <div className="xl:flex-row emd:flex-row small:flex-col fix_lg:flex-col flex items-center justify-between">
                      <div className="xl:w-[49%] emd:w-[49%] small:w-full fix_lg:w-full h-40 flex flex-col justify-evenly items-center xl:border-r emd:border-r small:border-b fix_lg:border-b xl:border-b-0 emd:border-b-0 small:border-r-0 fix_lg:border-r-0 border-light_secondary  xl:pr-3 fix_lg:pr-0 emd:pr-3 small:pr-0 xl:pb-0 emd:pb-0 small:pb-2 fix_lg:pb-2">
                        <div className="my-2 text-center font-bold underline underline-offset-4">
                          Left Eye
                        </div>
                        <div className="w-full flex items-center justify-between mt-3">
                          <span className="text-sm font-bold">Sphere :</span>
                          <span className="text-sm">
                            {item?.leftEye?.sphere}
                          </span>
                        </div>
                        <div className="w-full flex items-center justify-between">
                          <span className="text-sm font-bold">Cylinder :</span>
                          <span className="text-sm">
                            {item?.leftEye?.cylinder}
                          </span>
                        </div>
                        <div className="w-full flex items-center justify-between">
                          <span className="text-sm font-bold">Axis :</span>
                          <span className="text-sm">{item?.leftEye?.axis}</span>
                        </div>
                        {item?.leftEye?.add && (
                          <div className="w-full flex items-center justify-between">
                            <span className="text-sm font-bold">PD :</span>
                            <div className="flex items-center">
                              <span className="text-sm">
                                {item?.leftEye?.pd}
                              </span>

                            </div>
                          </div>
                        )}
                        {item?.leftEye?.add && (
                          <div className="w-full flex items-center justify-between">
                            <span className="text-sm font-bold">ADD :</span>
                            <div className="flex items-center">
                              <span className="text-sm">
                                {item?.leftEye?.add}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="xl:w-[49%] emd:w-[49%] small:w-full fix_lg:w-full h-40 lg:pl-3 fix_lg:pl-0 emd:pl-3 small:pl-0 flex flex-col justify-evenly items-center">
                        <div className="my-2 text-center font-bold underline underline-offset-4">
                          Right Eye
                        </div>
                        <div className="w-full flex items-center justify-between mt-3">
                          <span className="text-sm font-bold">Sphere :</span>
                          <span className="text-sm">
                            {item?.rightEye?.sphere}
                          </span>
                        </div>
                        <div className="w-full flex items-center justify-between">
                          <span className="text-sm font-bold">Cylinder :</span>
                          <span className="text-sm">
                            {item?.rightEye?.cylinder}
                          </span>
                        </div>
                        <div className="w-full flex items-center justify-between">
                          <span className="text-sm font-bold">Axis :</span>
                          <span className="text-sm">
                            {item?.rightEye?.axis}
                          </span>
                        </div>
                        {item?.rightEye?.pd && (
                          <div className="w-full flex items-center justify-between">
                            <span className="text-sm font-bold">PD :</span>
                            <div className="flex items-center">
                              <span className="text-sm">
                                {item?.rightEye?.pd}
                              </span>
                            </div>
                          </div>
                        )}
                        {item?.rightEye?.add && (
                          <div className="w-full flex items-center justify-between">
                            <span className="text-sm font-bold">ADD :</span>
                            <div className="flex items-center">
                              <span className="text-sm">
                                {item?.rightEye?.add}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div> */}
          {/* <div className="lg:flex emd:hidden small:hidden w-full h-46 mt-7 p-6 items-center justify-between bg-[#FCF5CC] ">
            <div className="flex flex-col items-start">
              <span className="text-3xl font-bold">Refer a friend</span>
              <span className="text-xl">& get 10% Off on your next order</span>
              <span className="text-sm">Terms and Conditions apply*</span>
            </div>
            <div className="flex flex-col items-end">
              <div className="border-dashed border text-primary text-lg font-bold border-primary py-1 px-2">
                {customerProfileData?.customer?.firstname +
                  "_" +
                  customerProfileData?.customer?.lastname}
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* ======================== ADD ADDRESS MODEL ======================== */}
      <MODEL
        showModel={addAddressToggle}
        setShowModel={() => closeAddAddressHandle()}
        title={
          editAddressToggle
            ? "Update shipping address"
            : "Add new shipping address"
        }
      >
        <div className="w-full flex flex-col items-center">
          <div className="w-full lg:grid lg:grid-cols-3 lg:gap-4 emd:flex emd:flex-col emd:items-center small:flex small:flex-col small:items-center p-4">
            <INPUT
              placeHolder="First Name"
              name="first_name"
              onChange={addressFormik.handleChange}
              val={addressFormik.values.first_name}
              onBlur={addressFormik.handleBlur}
              errorMsg={addressFormik.errors.first_name}
              touched={addressFormik.touched.first_name}
            />
            <INPUT
              placeHolder="Last Name"
              name="last_name"
              onChange={addressFormik.handleChange}
              val={addressFormik.values.last_name}
              onBlur={addressFormik.handleBlur}
              errorMsg={addressFormik.errors.last_name}
              touched={addressFormik.touched.last_name}
            />
            <INPUT
              placeHolder="Ph No."
              name="phone_no"
              onChange={addressFormik.handleChange}
              val={addressFormik.values.phone_no}
              onBlur={addressFormik.handleBlur}
              errorMsg={addressFormik.errors.phone_no}
              touched={addressFormik.touched.phone_no}
            />
            <INPUT
              placeHolder="Address 1"
              name="address_1"
              onChange={addressFormik.handleChange}
              val={addressFormik.values.address_1}
              onBlur={addressFormik.handleBlur}
              errorMsg={addressFormik.errors.address_1}
              touched={addressFormik.touched.address_1}
            />
            <INPUT
              placeHolder="Address 2"
              name="address_2"
              onChange={addressFormik.handleChange}
              val={addressFormik.values.address_2}
              onBlur={addressFormik.handleBlur}
              errorMsg={addressFormik.errors.address_2}
              touched={addressFormik.touched.address_2}
            />
            <INPUT
              placeHolder="City"
              name="city"
              onChange={addressFormik.handleChange}
              val={addressFormik.values.city}
              onBlur={addressFormik.handleBlur}
              errorMsg={addressFormik.errors.city}
              touched={addressFormik.touched.city}
            />
            <INPUT
              placeHolder="Country"
              name="country"
              onChange={addressFormik.handleChange}
              val={addressFormik.values.country}
              onBlur={addressFormik.handleBlur}
              errorMsg={addressFormik.errors.country}
              touched={addressFormik.touched.country}
            />
            <INPUT
              placeHolder="Pincode"
              name="pin_code"
              onChange={addressFormik.handleChange}
              val={addressFormik.values.pin_code}
              onBlur={addressFormik.handleBlur}
              errorMsg={addressFormik.errors.pin_code}
              touched={addressFormik.touched.pin_code}
            />
            <INPUT
              placeHolder="Save As"
              name="save_as"
              onChange={addressFormik.handleChange}
              val={addressFormik.values.save_as}
              onBlur={addressFormik.handleBlur}
              errorMsg={addressFormik.errors.save_as}
              touched={addressFormik.touched.save_as}
            />
          </div>
          <div className="w-full mb-5 flex items-center justify-center">
            <BTN
              bordered
              title="CANCEL"
              className="w-fit py-1 px-4"
              onP={() => closeAddAddressHandle()}
            />
            <BTN
              title={editAddressToggle ? "UPDATE" : "SAVE"}
              className="w-fit py-1 px-6 ms-4"
              type="submit"
              onP={addressFormik.handleSubmit}
            />
          </div>
        </div>
      </MODEL>

      {/* ======================== DELETE ADDRESS MODEL ======================== */}

      <MODEL
        isDialogue
        showModel={deleteAddressConf}
        setShowModel={setDeleteAddressConf}
      >
        <div className="w-full flex flex-col items-center justify-center p-5">
          <span className="w-full text-2xl my-10 text-center font-bold">
            Are You Sure, You want to delete this address ?
          </span>
          <div className="w-full flex items-center justify-center">
            <BTN
              title="Cancel"
              className="w-fit py-1 px-4"
              bordered
              onP={() => {
                setDeleteAddressConf(false);
                setSelectedAddress(null);
              }}
            />
            <BTN
              title="Delete"
              className="w-fit py-1 px-4 ms-5"
              onP={deleteAddressHandle}
              loading={isLoading}
            />
          </div>
        </div>
      </MODEL>

      {/* ============================== DELETE PRESCRIPTION ====================== */}
      <MODEL
        showModel={deletePrescriptionId !== ""}
        setShowModel={() => setDeletePrescriptionId("")}
        title="Delete prescription"
        isDialogue={true}
      >
        <div className="w-full p-4 flex flex-col items-center border-t border-light_secondary">
          <span className="text-lg text-center my-2">
            Are you sure, You want to delete this Prescription ?
          </span>
          <div className="flex items-center justify-evenly mt-6 w-full">
            <BTN
              title="No"
              onP={() => setDeletePrescriptionId("")}
              bordered
              className={"w-28 py-1 px-2"}
            />
            <BTN
              onP={() => deletePrescriptionHandle()}
              title="Yes"
              className={"w-28 py-1 px-2"}
            />
          </div>
        </div>
      </MODEL>
      {/* ==============================  EDIT ADD PRESCRIPTION ====================== */}

      <MODEL
        showModel={prescriptionToggle}
        setShowModel={() => setAddPrescriptionToggle(false)}
        title="Add prescription"
      >
        <div className="w-full flex flex-col">
          <div className="w-full flex items-end lg:text-xl emd:text-xl small:text-lg px-4">
            <div className="lg:text-lg emd:text-lg small:text-[10px] w-fit flex mb-4">
              NAME
            </div>
            <INPUT
              mainClass={"ms-5"}
              className={
                "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1"
              }
              placeHolder={"Name"}
              name="name"
              val={prescriptionFormik?.values?.name}
              onBlur={prescriptionFormik.handleBlur}
              touched={prescriptionFormik.touched?.name}
              onChange={(e) => {
                prescriptionFormik.handleChange(e);
              }}
              errorMsg={prescriptionFormik.errors.name}
              errorTextClass={"lg:text-sm emd:text-xs small:text-xs"}
            />
          </div>
          <div
            className={`grid-cols-6 lg:w-full mt-5 px-4 gap-y-2 lg:gap-x-6 emd:gap-x-4 small:gap-x-2 emd:w-full small:w-full grid`}
          >
            <div className="lg:text-lg emd:text-lg small:text-sm flex"></div>
            <div className="lg:text-lg emd:text-lg small:text-[10px] flex mb-4 ps-4">
              SPH
            </div>
            <div className="lg:text-lg emd:text-lg small:text-[10px] flex mb-4 ps-4">
              CYL
            </div>
            <div className="lg:text-lg emd:text-lg small:text-[10px] flex mb-4 ps-4">
              AXIS
            </div>

            <div className="lg:text-lg emd:text-lg small:text-[10px] flex mb-4 ps-4">
              ADD
            </div>

            <div className="lg:text-lg emd:text-lg small:text-[10px] flex mb-4 ps-4">
              PD
              {/* <div className="text-center select-none ms-1 text-[10px] leading-normal underline text-primary cursor-pointer">
                        I have 2 PD values
                      </div> */}
            </div>
            <div className="lg:text-lg emd:text-lg small:text-[10px] flex items-end pb-6">
              OD (Right Eye)
            </div>
            <div className="lg:text-xl emd:text-xl small:text-lg">
              <INPUT
                className={
                  "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                }
                maxLength={5}
                placeHolder={"SPH (+/- 1)"}
                name="right_sph"
                onChange={(e) => {
                  if (/^[-+]\d*(\.\d*)?$/.test(e.target?.value)) {
                    prescriptionFormik.handleChange(e);
                  } else if (e.target.value === "") {
                    prescriptionFormik.handleChange(e);
                  }
                }}
                val={prescriptionFormik.values?.right_sph}
                onBlur={prescriptionFormik.handleBlur}
                touched={prescriptionFormik?.touched?.right_sph}
                errorMsg={prescriptionFormik.errors.right_sph}
                errorTextClass={"lg:text-sm emd:text-xs small:text-xs"}
              />
            </div>
            <div className="lg:text-xl emd:text-xl small:text-lg">
              <INPUT
                className={
                  "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                }
                maxLength={5}
                placeHolder={"CYL (+/- 1)"}
                name="right_cyl"
                onChange={(e) => {
                  if (/^[-+]\d*(\.\d*)?$/.test(e.target?.value)) {
                    prescriptionFormik.handleChange(e);
                  } else if (e.target.value === "") {
                    prescriptionFormik.handleChange(e);
                  }
                }}
                val={prescriptionFormik?.values?.right_cyl}
                onBlur={prescriptionFormik.handleBlur}
                touched={prescriptionFormik?.touched?.right_cyl}
                errorMsg={prescriptionFormik.errors.right_cyl}
                errorTextClass={"lg:text-sm emd:text-xs small:text-xs"}
              />
            </div>
            <div className="lg:text-xl emd:text-xl small:text-lg">
              <INPUT
                className={
                  "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                }
                maxLength={5}
                placeHolder={"AXIS"}
                name="right_axis"
                val={prescriptionFormik?.values?.right_axis}
                onBlur={prescriptionFormik.handleBlur}
                touched={prescriptionFormik?.touched?.right_axis}
                onChange={(e) => {
                  if (/^[+]?[0-9]*$/.test(e.target?.value)) {
                    prescriptionFormik.handleChange(e);
                  } else if (e.target.value === "") {
                    prescriptionFormik.handleChange(e);
                  }
                }}
                errorMsg={prescriptionFormik.errors.right_axis}
                errorTextClass={"lg:text-sm emd:text-xs small:text-xs"}
              />
            </div>

            <div className="lg:text-xl emd:text-xl small:text-lg ">
              <INPUT
                className={
                  "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                }
                placeHolder={"ADD"}
                name="right_add"
                maxLength={5}
                val={prescriptionFormik?.values?.right_add}
                onBlur={prescriptionFormik.handleBlur}
                touched={prescriptionFormik.touched?.right_add}
                onChange={(e) => {
                  if (/^[+]?[0-9]*$/.test(e.target?.value)) {
                    prescriptionFormik.handleChange(e);
                  } else if (e.target.value === "") {
                    prescriptionFormik.handleChange(e);
                  }
                }}
                errorMsg={prescriptionFormik.errors.right_pd}
                errorTextClass={"lg:text-sm emd:text-xs small:text-xs"}
              />
            </div>

            <div className="lg:text-xl emd:text-xl small:text-lg ">
              <INPUT
                className={
                  "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                }
                maxLength={5}
                placeHolder={"PD"}
                name="right_pd"
                val={prescriptionFormik?.values?.right_pd}
                onBlur={prescriptionFormik.handleBlur}
                touched={prescriptionFormik.touched?.right_pd}
                onChange={(e) => {
                  if (/^[+]?[0-9]*$/.test(e.target?.value)) {
                    prescriptionFormik.handleChange(e);
                  } else if (e.target.value === "") {
                    prescriptionFormik.handleChange(e);
                  }
                }}
                errorMsg={prescriptionFormik.errors.right_pd}
                errorTextClass={"lg:text-sm emd:text-xs small:text-xs"}
              />
            </div>
            <div className="lg:text-lg emd:text-lg small:text-[10px] flex items-end pb-6">
              OS (Left Eye)
            </div>
            <div className="lg:text-xl emd:text-xl small:text-lg">
              <INPUT
                className={
                  "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                }
                maxLength={5}
                placeHolder={"SPH (+/- 1)"}
                name="left_sph"
                onChange={(e) => {
                  if (/^[-+]\d*(\.\d*)?$/.test(e.target?.value)) {
                    prescriptionFormik.handleChange(e);
                  } else if (e.target.value === "") {
                    prescriptionFormik.handleChange(e);
                  }
                }}
                val={prescriptionFormik.values?.left_sph}
                onBlur={prescriptionFormik.handleBlur}
                touched={prescriptionFormik.touched?.left_sph}
                errorMsg={prescriptionFormik.errors.left_sph}
                errorTextClass={"lg:text-sm emd:text-xs small:text-xs"}
              />
            </div>
            <div className="lg:text-xl emd:text-xl small:text-lg">
              <INPUT
                className={
                  "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                }
                maxLength={5}
                placeHolder={"CYL (+/- 1)"}
                name="left_cyl"
                onChange={(e) => {
                  if (/^[-+]\d*(\.\d*)?$/.test(e.target?.value)) {
                    prescriptionFormik.handleChange(e);
                  } else if (e.target.value === "") {
                    prescriptionFormik.handleChange(e);
                  }
                }}
                val={prescriptionFormik.values?.left_cyl}
                onBlur={prescriptionFormik.handleBlur}
                errorMsg={prescriptionFormik.errors.left_cyl}
                touched={prescriptionFormik.touched?.left_cyl}
              />
            </div>
            <div className="lg:text-xl emd:text-xl small:text-lg">
              <INPUT
                className={
                  "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                }
                maxLength={5}
                placeHolder={"AXIS"}
                name="left_axis"
                val={prescriptionFormik.values?.left_axis}
                onBlur={prescriptionFormik.handleBlur}
                touched={prescriptionFormik.touched?.left_axis}
                onChange={(e) => {
                  if (/^[+]?[0-9]*$/.test(e.target?.value)) {
                    prescriptionFormik.handleChange(e);
                  } else if (e.target.value === "") {
                    prescriptionFormik.handleChange(e);
                  }
                }}
                errorMsg={prescriptionFormik.errors.left_axis}
                errorTextClass={"lg:text-sm emd:text-xs small:text-xs"}
              />
            </div>

            <div className="lg:text-xl emd:text-xl small:text-lg ">
              <INPUT
                className={
                  "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                }
                maxLength={5}
                placeHolder={"ADD"}
                name="left_add"
                val={prescriptionFormik.values.left_add}
                onBlur={prescriptionFormik.handleBlur}
                touched={prescriptionFormik.touched?.left_add}
                onChange={(e) => {
                  if (/^[+]?[0-9]*$/.test(e.target?.value)) {
                    prescriptionFormik.handleChange(e);
                  } else if (e.target.value === "") {
                    prescriptionFormik.handleChange(e);
                  }
                }}
                errorMsg={prescriptionFormik.errors.left_pd}
                errorTextClass={"lg:text-sm emd:text-xs small:text-xs"}
              />
            </div>

            <div className="lg:text-xl emd:text-xl small:text-lg ">
              <INPUT
                className={
                  "lg:text-lg emd:text-base small:text-[10px] lg:px-2 emd:px-1 small:px-1 "
                }
                maxLength={5}
                placeHolder={"PD"}
                name="left_pd"
                val={prescriptionFormik.values.left_pd}
                onBlur={prescriptionFormik.handleBlur}
                touched={prescriptionFormik.touched?.left_pd}
                onChange={(e) => {
                  if (/^[+]?[0-9]*$/.test(e.target?.value)) {
                    prescriptionFormik.handleChange(e);
                  } else if (e.target.value === "") {
                    prescriptionFormik.handleChange(e);
                  }
                }}
                errorMsg={prescriptionFormik.errors.left_pd}
                errorTextClass={"lg:text-sm emd:text-xs small:text-xs"}
              />
            </div>

            {/* <div className="text-sm  flex justify-end flex-col">
                      <div className="mont-bold">I dont know PD</div>
                      <div className="mt-1 text-xs">&nbsp;</div>
                    </div> */}
          </div>
          <div className="w-full flex items-end justify-end">
            <BTN
              type={"submit"}
              title={"CONFIRM"}
              className="w-fit h-fit mb-4 mr-5 px-4 py-1"
              onP={() => prescriptionFormik.handleSubmit()}
            />
          </div>
        </div>
      </MODEL>
    </div>
  );
};

export default Account;
