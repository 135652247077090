import React, { useEffect } from "react";
import {
  comingsoonbanner,
  comingsoonImg,
  DGlogo,
  followusImg,
  instagram,
  youtube,
  facebook,
  INSTAGRAM_LINK,
  FACEBOOK_LINK,
  dagacomingsoon,
} from "../../../assets";
import { Link, useLocation } from "react-router-dom";
import INPUT from "../../Master/INPUT";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { isValidEmail } from "../../Footer/Footer";
import { useContactUsMutation, useSubscribeMutation } from "../../../redux/slices/homeSlice";
import useReCaptcha from "../../../hooks/useReCaptcha";
const ComingSoonBlock = () => {
  const isReCaptchaVerified = useReCaptcha();
  // remove coming-soon hasg from url
  const { hash } = useLocation();
  useEffect(() => {
    if (hash === "#coming-soon") {
      window.location.hash = "";
    }
  }, [hash]);
  const [comingsoon] = useContactUsMutation();
  const [subscribe] = useSubscribeMutation();
  const comingsoonFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
    },
    onSubmit: async function (values) {
      if (!isReCaptchaVerified) {
        toast.error("This activity is suspicious, please try again later");
        return;
      }
      subscribe({
        body: {
          name: values?.name,
          email: values?.email,
          phone: values?.phone,
        },
      }).then((res) => {
        if (res?.data) {
          comingsoonFormik?.resetForm();
          toast.success(`${res?.data?.message}`);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    },
    validate: (values) => {
      let errors = {};
      if (!values?.name) {
        errors.name = "Name is Required!";
      } else if (!values?.email) {
        errors.email = "email id is Required!";
      } else if (!isValidEmail(values?.email)) {
        errors.email = "Invalid Email!";
      } else if (!values?.phone) {
        errors.phone = "Phone number is Required!";
      } else if (!/^[0-9]+$/i.test(values.phone)) {
        errors.phone = "Invalid Phone Number";
      } else if (values.phone.length !== 10) {
        errors.phone = "Phone Number must be 10 digits";
      }
      return errors;
    },
  });

  return (
    <div className=" flex flex-col-reverse md:flex-row bg-bg_1 flex-grow">
      <div className=" md:w-1/2 flex flex-col items-center pt-6 pb-6 sm:pb-0 flex-grow">
        <div>
          <img
            src={DGlogo}
            alt="daga gems logo"
            className="w-20 h-28 sm:w-16 sm:h-20 md:w-20 md:h-28"
          />
        </div>
        <div className="text-text_dark_2 mt-10 sm:mt-6 md:mt-10 text-center">
          <span>OUR NEW WEBSITE</span>
          <div>
            <img src={comingsoonImg} alt="" className="w-1/4 h-1/4 mx-auto" />
          </div>
        </div>
        <div className="text-text_dark_2 text-[12px] max-w-[84%] sm:max-w-[84%] md:max-w-[50%] text-center mt-6 sm:mt-3 md:mt-6">
          Sign up to be our special customers to Receive discount on your first
          order!
        </div>
        <div className="flex flex-col gap-4 mt-5 px-4">
          <div className="flex flex-col md:flex-row : gap-4 items-center">
            <INPUT
              type="text"
              name="name"
              placeHolder="Name"
              onChange={comingsoonFormik.handleChange}
              val={comingsoonFormik.values.name}
              onBlur={comingsoonFormik.handleBlur}
              errorMsg={comingsoonFormik.errors.name}
              touched={comingsoonFormik.touched.name}
              className="border-2 border-text_dark_3 bg-bg_1 p-2 sm:p-1 md:p-2 sm:w-[100%] md:w-[100%] sm:placeholder:text-xs md:placeholder:text-base"
            />
            <INPUT
              type="text"
              name="phone"
              placeHolder="Number"
              onChange={comingsoonFormik.handleChange}
              val={comingsoonFormik.values.phone}
              onBlur={comingsoonFormik.handleBlur}
              errorMsg={comingsoonFormik.errors.phone}
              touched={comingsoonFormik.touched.phone}
              className="border-2 border-text_dark_3 bg-bg_1 p-2 sm:p-1 md:p-2 sm:w-[100%] md:w-[100%] sm:placeholder:text-xs md:placeholder:text-base"
            />
          </div>
          <div className="flex flex-col md:flex-row gap-4 flex-grow">
            <INPUT
              type="email"
              name="email"
              placeHolder="Email"
              onChange={comingsoonFormik.handleChange}
              val={comingsoonFormik.values.email}
              onBlur={comingsoonFormik.handleBlur}
              errorMsg={comingsoonFormik.errors.email}
              touched={comingsoonFormik.touched.email}
              className="border-2 border-text_dark_3 bg-bg_1 p-2 sm:p-1 md:p-2 flex-grow sm:placeholder:text-xs md:placeholder:text-base"
            />
            <button
              className="bg-black text-text_light_2 px-6 h-[44px]"
              onClick={comingsoonFormik.handleSubmit}
            >
              SUBMIT
            </button>
          </div>
        </div>
        <div className="relative flex items-center mt-14 mb-3">
          <div className=" flex justify-center">
            <img src={followusImg} alt="" className="h-1/3 w-1/3 mx-auto" />
          </div>
          <div className="absolute inset-x-0 top-11 flex justify-center w-full ">
            <Link to={INSTAGRAM_LINK} target="_blank" className="mx-2">
              <img src={instagram} alt="instagram" className="h-4 w-4" />
            </Link>
            <Link to={FACEBOOK_LINK} target="_blank" className="mx-2">
              <img src={facebook} alt="facebook" className="h-4 w-4" />
            </Link>
          </div>
        </div>
      </div>
      <div className="md:w-1/2">
        <div className="object-cover ">
          <img src={dagacomingsoon} className="object-cover h-screen w-full"></img>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonBlock;
