import React, { useEffect, useRef, useState } from "react";
import { tryonvideo } from "../../../assets";
const Player = (props) => {
  const targetId = "testDiv";
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const script = document.createElement('script');
    script.src = props.src;
    script.async = true;

    script.setAttribute("data-video",props["data-video"])
    
    document.getElementById(targetId).appendChild(script);
    return () => {
         document.getElementById(targetId).removeChild(script);
    }
  }, []);
  
  return <div id={targetId}></div>
};
{/* <Player 
     src="https://geo.dailymotion.com/player/xow6u.js"
     data-video="x95xvoe"
   /> */}
   const embedContainerStyle = {
    position: 'relative',
    paddingBottom: '56.25%',
    height: 0,
    overflow: 'hidden',
    maxWidth: '100%'
  };

  const iframeStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  };
const TryonVideoBlock = () => {
  return (
    <div className="w-full">
      <div className='embed-container' style={embedContainerStyle}>
      <iframe  style={iframeStyle}  className="w-full" src="https://player.vimeo.com/video/1011241687?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1&amp;controls=0" width="1920" height="1080" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="Daga Gems Tryon Video"></iframe>
      </div>
      {/* <Player 
     src="https://geo.dailymotion.com/player/xow6u.js"
     data-video="x95xvoe"
   /> */}
      {/* <ReactPlayer
        className=""
        url={tryonvideo}
        playsinline={true}
        loop={true}
        playing={true}
        muted={true}
        controls={false}
        width="100%"
        height="100%"
      /> */}
    </div>
  );
};

export default TryonVideoBlock;
