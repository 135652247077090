import { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"; // version : "1.10.1",

function useReCaptcha() {
  const [isVerified, setIsVerified] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
    // Execute recaptcha not yet available
      setIsVerified(false);
      return;
    }

    const token = await executeRecaptcha("yourAction");
    verifyReCaptcha(token).then((isVerified) => {
      setIsVerified(isVerified);
      return isVerified;
    });
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);
  return isVerified;
}
export default useReCaptcha;

const verifyReCaptcha = async (token) => {
  try {
    const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/dagagems/verify-captcha`,
        {
            method: "POST",
            headers: {
                "x-api-key": process.env.REACT_APP_API_KEY,
                "Content-Type": "application/json", // Add Content-Type header
            },
            body: JSON.stringify({
                token: token,
            }),
        }
    );
    const data = await response.json();
    return data.success;
  } catch (error) {
    console.error('Error verifying reCAPTCHA:', error);
    return false;
  }
};
