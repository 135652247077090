import React from "react";
import { useFormik } from "formik";
import axios from "axios";
import { Bounce, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useAddToCustomerBagMutation, useGenerateOTPforCustomerSignupMutation } from "../redux/slices/homeSlice";
import { useSelector } from "react-redux";
import useReCaptcha from "../hooks/useReCaptcha";

export const Signup = () => {
  const isReCaptchaVerified = useReCaptcha();
  const navigate = useNavigate();
  const location = useLocation();
  const myBagData = useSelector((state) => state?.myBagData?.items);
  const [addToCustomerBag] = useAddToCustomerBagMutation();

  const signupformik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNo: "",
      email: "",
      otp: "",
    },
    onSubmit: async function (values) {
      if (!isReCaptchaVerified) {
        toast.error("This activity is suspicious, please try again later");
        return;
      }
      try {
        const response = await axios(
          process.env.REACT_APP_BASE_URL + `/customer/register`,
          {
            method: "POST",
            data: {
              firstname: values.firstName,
              lastname: values.lastName,
              email: values.email,
              otp: values.otp,
              phone: values.phoneNo,
            },
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );

        localStorage.setItem("user", response.data.token);
        // ====================== ADD TO BAG HANDLE ================================

        if (myBagData && myBagData?.length > 0) {
          myBagData.forEach((item) => {
            addToCustomerBag({
              body: {
                products: [
                  {
                    product_id: item?._id,
                    quantity: 1,
                    variant: [],
                    lens: [],
                  },
                ],
              },
              user: response.data.token,
            }).then((res) => {
              if (res?.data) {
                // toast.success(`${item?.name} added to bag successfully.`);
              } else {
                // toast.error(`${res?.error?.data?.message}`);
              }
            });
          });
        }

        navigate(
          location?.state?.isNewUser === true
            ? "/shopping-bag"
            : location?.state?.id
            ? "/lens"
            : "/profile-details#your-account",
          { state: { id: location?.state?.id ? location?.state?.id : null } }
        );
        toast.success(response.data.message);
      } catch (err) {
        console.log(err);
        toast.error(err.response.data.message);
      }
      console.log({
        firstname: values.firstName,
        lastname: values.firstName,
        email: values.email,
        otp: values.otp,
        phone: values.phoneNo,
      });
    },
    validate: (values) => {
      let errors = {};
      if (!values.firstName) {
        errors.firstName = "enter first name";
      }
      if (!values.lastName) {
        errors.lastName = "enter last name";
      }
      if (!values.phoneNo) {
        errors.phoneNo = "Phone number is Required";
      } else if (!/^[0-9]+$/i.test(values.phoneNo)) {
        errors.phoneNo = "Invalid Phone Number";
      } else if (values.phoneNo.length !== 10) {
        errors.phoneNo = "Phone Number must be 10 digits";
      }
      // if (!values.email) {
      //   errors.email = "Email is Required";
      // } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(values.email)) {
      //   errors.email = "Invalid Email";
      // }
      if (!values.otp) {
        errors.otp = "Enter OTP";
      } else if (!/^[0-9]+$/i.test(values.otp)) {
        errors.otp = "Invalid OTP";
      } else if (values.otp.length !== 3) {
        errors.otp = "OTP must be 3 digits";
      }
      return errors;
    },
  });

  const [
    generateOTPforCustomerSignup,
    {
      data: otpData,
      isLoading: isLoadingOTP,
      isSuccess: isSuccessOTP,
      isError: isErrorOTP,
      error: errorOTP,
    },
  ] = useGenerateOTPforCustomerSignupMutation();
  const generateOtp = (phone) => {
    if (!phone) {
      toast.error("Phone number is Required!");
    }
    if (signupformik.errors.phoneNo) {
      return;
    }
    const body = {
      phone: phone,
    };
    generateOTPforCustomerSignup({ body }).then((res) => {
      // toast success
      if (res.data) {
        toast.success(res.data.message);
      }
      console.log(res.data);
    });
  };

  return (
    <div className="signup-form lg:pl-[20%] emd:pl-0 small:pl-0">
      <h1 className="signup-title font-sabon">Sign Up</h1>
      <p className="signup-text">To create an account, fill in the details</p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div>
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            value={signupformik.values.firstName}
            onChange={signupformik.handleChange}
            onBlur={signupformik.handleBlur}
          />
          {signupformik.errors.firstName && signupformik.touched.firstName ? (
            <div className="error-msg">{signupformik.errors.firstName}</div>
          ) : null}
        </div>
        <br />
        <div>
          <input
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={signupformik.values.lastName}
            onChange={signupformik.handleChange}
            onBlur={signupformik.handleBlur}
          />
          {signupformik.errors.lastName && signupformik.touched.lastName ? (
            <div className="error-msg">{signupformik.errors.lastName}</div>
          ) : null}
        </div>
        <br />
        <div className="phone-input-container-signup">
          <input
            type="text"
            placeholder="Ph No."
            name="phoneNo"
            value={signupformik.values.phoneNo}
            onChange={signupformik.handleChange}
            onBlur={signupformik.handleBlur}
          />
          <div
            className="send-otp-btn-signup"
            onClick={() => {
              generateOtp(signupformik.values.phoneNo);
            }}
          >
            SEND
          </div>
        </div>
        {signupformik.errors.phoneNo && signupformik.touched.phoneNo ? (
          <div className="error-msg">{signupformik.errors.phoneNo}</div>
        ) : null}
        <br />
        <div>
          <input
            type="email"
            placeholder="Email"
            name="email"
            value={signupformik.values.email}
            onChange={signupformik.handleChange}
            onBlur={signupformik.handleBlur}
          />
          {signupformik.errors.email && signupformik.touched.email ? (
            <div className="error-msg">{signupformik.errors.email}</div>
          ) : null}
        </div>
        <br />
        <div>
          <input
            type="text"
            placeholder="OTP"
            name="otp"
            value={signupformik.values.otp}
            onChange={signupformik.handleChange}
            onBlur={signupformik.handleBlur}
          />
          {signupformik.errors.otp && signupformik.touched.otp ? (
            <div className="error-msg">{signupformik.errors.otp}</div>
          ) : null}
        </div>
        <br />
        <button
          className="signup-btn signup-btn-container"
          onClick={signupformik.handleSubmit}
        >
          CREATE ACCOUNT
        </button>
      </form>
    </div>
  );
};
