import "./Header.scss";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { Breakpoint } from "react-socks";
import { myBagToggle } from "../../redux/slices/myBagToggleSlice";
import useGetUser from "../../hooks/useGetUser";
import INPUT from "../Master/INPUT";
import {
  ADDRESS_LINK,
  BAG_IC,
  cart_icon,
  CIRCLE_CLOSE_IC,
  CLOSE_BLACK_IC,
  CLOSE_IC,
  close_square_icon,
  dagacontactus_icon,
  DGlogo,
  LIKE__IC,
  locationIcon,
  MENU_IC,
  menu_icon,
  MOBILE_NUMBER,
  PERSON_IC,
  SEARCH_IC,
  search_icon,
  userIcon,
  wishlist_icon,
} from "../../assets";

function Header({
  isRetailerPage,
  sidebarToggle,
  setSidebarToggle,
  headerLabelsData,
}) {
  const [isVisible, setIsVisible] = useState(true);
  const [searchToggle, setSearchToggle] = useState(false);
  const location = window.location;
  const dispatch = useDispatch();
  const user = useGetUser();
  const params = useParams();

  const [searchProductsVal, setSearchProductsVal] = useState(
    location?.pathname?.split("/")[1] === "search"
      ? location?.pathname?.split("/")[2]
      : ""
  );

  const wishlistData = useSelector((state) => state.wishlist.data);

  useEffect(() => {
    if (!location?.pathname.includes("search")) {
      setSearchProductsVal("");
    }
  }, [location.pathname]);
  const myBagData = useSelector((state) => state?.myBagData?.items);

  useEffect(() => {
    if (location.pathname === "/login") {
      setIsVisible(false);
      return;
    }
  }, [location]);
  useEffect(() => {
    function handleScroll() {
      if (location.pathname === "/login") {
        setIsVisible(false);
        return;
      }
      if (window.scrollY > 300) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    }

    window.addEventListener("scroll", handleScroll);
    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  const AccountIcon = () => {
    return (
      <Link
        to={`${user?.customer ? "/profile-details#your-account" : "/login"}`}
        className="material-symbols-outlined link"
      >
        <img src={PERSON_IC} alt="" className="w-9" />
      </Link>
    );
  };
  const FavouriteIcon = () => {
    return (
      <Link to="/favourites" className="material-symbols-outlined link relative">
        <img src={LIKE__IC} alt="" className="w-8" />
        {wishlistData?.length !== 0 && (
          <div className="absolute -top-1 right-1.5 bg-primary w-[20px] h-[20px] text-center flex flex-col items-center justify-center rounded-full">
            <span className="text-white text-[14px] mt-0.5">
              {wishlistData?.length > 9 ? "9+" : wishlistData?.length}
            </span>
          </div>
        )}
      </Link>
    );
  };

  const navigate = useNavigate();
  const searchProductsHandle = (value) => {
    setSearchProductsVal(value);
  };

  const searchNavigationHandle = (e) => {
    if (e.key === "Enter") {
      if (
        e.key === "Enter" &&
        !location.pathname.includes("search") &&
        searchProductsVal === ""
      ) {
        return;
      }
      if (location.pathname.includes("search") && searchProductsVal === "") {
        navigate(`/search`);
      } else {
        navigate(`/search/${searchProductsVal}`);
      }
    }
  };

  const mobileSearchNavigationHandle = (e) => {
    if (e.key === "Enter") {
      if (
        e.key === "Enter" &&
        !location.pathname.includes("search") &&
        searchProductsVal === ""
      ) {
        return;
      }
      if (location.pathname.includes("search") && searchProductsVal === "") {
        navigate(`/search`);
        setSearchToggle(false);
        document.body.style.overflow = "auto";
        document.body.style.height = "auto";
      } else {
        navigate(`/search/${searchProductsVal}`);
        setSearchToggle(false);
        document.body.style.overflow = "auto";
        document.body.style.height = "auto";
      }
    }
  };

  const [showBrowse, setShowBrowse] = useState(false);

  return (
    <>
      {/* ========================New header=================== */}
      <div>
        <DagaHeader
          user={user}
          searchProductsVal={searchProductsVal}
          searchProductsHandle={searchProductsHandle}
          searchNavigationHandle={searchNavigationHandle} />
        <DagaHeaderMobile
          user={user}
          searchProductsVal={searchProductsVal}
          searchProductsHandle={searchProductsHandle}
          searchNavigationHandle={searchNavigationHandle} />
      </div>
      {/* ========================old header============== */}
      {/* <Breakpoint large up>
        <div className="header p-f t-0 l-0 w-100 ">
          <div
            className="header-top bg-1"
            onMouseOver={() => {
              setIsVisible(true);
            }}
          >
            <div className="d-grid grid-3 header-top-child">
              <div className="d-flex a-c">
                <INPUT
                  isNotValidate
                  placeHolder={"Search"}
                  className={"py-0 px-0 w-full  border-0"}
                  mainClass={"p-0 m-0 w-[200px] "}
                  val={searchProductsVal}
                  onChange={(s) => searchProductsHandle(s.target?.value)}
                  onKeyDown={(e) => searchNavigationHandle(e)}
                  lComp={
                    <div className="material-symbols-outlined text-2xl me-1">
                      <img src={SEARCH_IC} alt="" className="w-8" />
                    </div>
                  }
                />
              </div>
              <div className="d-flex j-c a-c">
                <Link to="/">
                  <img
                    className="netre-logo"
                    src="/Netre-logo.svg"
                    alt="Netre"
                  />
                </Link>
              </div>
              <div className="d-flex j-e a-c header-right-icons">
                <FavouriteIcon />
                <div className="vertical-rule"></div>
                <AccountIcon />
                <div className="vertical-rule"></div>
                <button
                  className="material-symbols-outlined relative"
                  onClick={() => {
                    dispatch(myBagToggle());
                    document.body.style.overflow = "hidden";
                    document.body.style.height = "100vh";
                  }}
                >
                  <img src={BAG_IC} alt="" className="w-8" />
                  {myBagData?.length !== 0 && (
                    <div className="absolute -top-1 -right-1 bg-primary w-[20px] h-[20px] text-center flex flex-col items-center justify-center rounded-full">
                      <span className="text-white text-[14px] mt-0.5">
                        {myBagData?.length > 9 ? "9+" : myBagData?.length}
                      </span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div
            className="header-bottom d-flex j-c bg-1"
            style={{
              display: isVisible ? "flex" : "none",
              transition: "0.5s all ease",
            }}
          >
            <div
              onClick={() => {
                navigate(`/aboutus`);
              }}
              className="items mont-regular"
            >
              About Us
            </div>
            <div
              className="w-fit h-fit relative"
              onMouseLeave={() => setShowBrowse(false)}
              onMouseEnter={() => setShowBrowse(true)}
            >
              <div className="items mont-regular">Browse Collection</div>
              {showBrowse && (
                <div className="absolute cursor-pointer bg-white flex flex-col px-8 py-4 left-[50%] -translate-x-1/2 shadow-md">
                  {[
                    { title: "Optical Frames", id: "668540397db56839146d6f82" },
                    { title: "Sunglasses", id: "668540567db56839146d6f8e" },
                    {
                      title: "Reading Glasses",
                      id: "668540397db56839146d6f82",
                    },
                  ]?.map((item, index) => {
                    return (
                      <span
                        onClick={() => {
                          navigate(`/categories/${item?.id}`);
                          setShowBrowse(false);
                        }}
                        className="cursor-pointer text-nowrap my-2"
                      >
                        {item?.title}
                      </span>
                    );
                  })}
                </div>
              )}
            </div>

            <div
              onClick={() => {
                navigate(`/search`);
              }}
              className="items mont-regular color-2"
            >
              Offers %
            </div>
            <div
              onClick={() => {
                navigate(`/become-retailer`);
              }}
              className="items mont-regular"
            >
              For Business
            </div>
          </div>
        </div>
      </Breakpoint> */}

      {/* =========================== FOR MOBILE AND TABLET =============================  */}

      {/* <Breakpoint
        className={`${searchToggle ? "z-9999999 bg-green" : ""}`}
        medium
        down
      >
        <div className={`header p-f t-0 l-0 w-100`}>
          <div
            className="header-top bg-1"
            onMouseOver={() => {
              setIsVisible(true);
            }}
          >
            <div className="d-grid grid-3 header-top-child">
              <div className="d-flex a-c">
                <div
                  className="material-symbols-outlined"
                  onClick={() => setSidebarToggle(true)}
                >
                  <img src={MENU_IC} alt="" className="w-8" />
                </div>
                <div className="vertical-rule"></div>
                <div
                  className="material-symbols-outlined"
                  onClick={() => {
                    setSearchToggle(true);
                    setSearchProductsVal("");
                  }}
                >
                  <img
                    src={!searchToggle ? SEARCH_IC : CLOSE_IC}
                    alt=""
                    className="w-8"
                  />
                </div>
              </div>
              <div className="d-flex j-c a-c">
                <Link to="/">
                  <img
                    className="netre-logo"
                    src="/Netre-logo.svg"
                    alt="Netre"
                  />
                </Link>
              </div>
              <div className="d-flex j-e a-c header-right-icons">
                <AccountIcon />
                <div className="vertical-rule"></div>
                <button
                  className="material-symbols-outlined relative me-2"
                  onClick={() => {
                    dispatch(myBagToggle());
                    document.body.style.overflow = "hidden";
                    document.body.style.height = "100vh";
                  }}
                >
                  <img src={BAG_IC} alt="" className="w-8" />
                  {myBagData?.length !== 0 && (
                    <div className="absolute -top-1 -right-1 bg-primary w-[20px] h-[20px] text-center flex flex-col items-center justify-center rounded-full">
                      <span className="text-white text-[14px] mt-0.5">
                        {myBagData?.length > 9 ? "9+" : myBagData?.length}
                      </span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
          {searchToggle ? (
            <div className="absolute bg-white p-5 top-16 w-full z-9999 flex items-center justify-center">
              <INPUT
                placeHolder="Search..."
                val={searchProductsVal}
                onChange={(s) => setSearchProductsVal(s?.target?.value)}
                onKeyDown={(e) => mobileSearchNavigationHandle(e)}
              />
            </div>
          ) : (
            <></>
          )}
          {searchToggle && (
            <div
              className={`fixed top-0 left-0 w-full h-full transition-all duration-75 z-999 ${
                searchToggle ? "opacity-50 " : ""
              }`}
              style={{
                background:
                  "linear-gradient(to bottom, transparent, #0000004c)",
              }}
              onClick={() => {
                setSearchToggle(false);
                document.body.style.overflow = "auto";
                document.body.style.height = "auto";
              }}
            ></div>
          )}
        </div>
      </Breakpoint> */}
    </>
  );
}
export default Header;

{
  /* {headerLabelsData?.labels?.homepage_category_to_show &&
              [...headerLabelsData?.labels?.homepage_category_to_show]
                .reverse()
                ?.splice(1)
                ?.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        navigate(`/categories/${item?.id}`);
                      }}
                      key={index}
                      className="items mont-regular"
                    >
                      {item?.name}
                    </div>
                  );
                })} */
}

const DagaHeader = ({
  user,
  searchProductsVal,
  searchProductsHandle,
  searchNavigationHandle
}) => {
  return (
    <div className="hidden md:block whitespace-nowrap">
      {/* ---------------------------------------------------------------- */}
      <div className="bg-primary hidden sm:flex justify-between px-4 py-1">
        <div className="flex flex-row gap-2 items-center">
          <div className="text-text_light"><img src={dagacontactus_icon} className="h-45 w-5"></img></div>
          <div className="text-text_light">+91 {MOBILE_NUMBER}</div>
        </div>
        <div className="flex justify-between gap-[0.5em]">
          <div className="text-text_light">Enter Pincode</div>
          <div className="text-text_medium opacity-65">to check delivery</div>
        </div>
      </div>
      <div className="bg-secondary grid grid-cols-[minmax(0,_1fr)_100px_minmax(0,_1fr)] py-2">
        <div className="flex flex-col justify-between">
          <div className="p-2 px-4">
            <INPUT
              isNotValidate
              placeHolder={"Search ..."}
              className={"py-0 px-0 w-1/2  border-b"}
              mainClass={"p-0 m-0 w-[200px] "}
              val={searchProductsVal}
              onChange={(s) => searchProductsHandle(s.target?.value)}
              onKeyDown={(e) => searchNavigationHandle(e)}
              rComp={
                <div className="me-1">
                  <img src={search_icon} alt="" className="p-1 h-[30px] w-[30px]" />
                </div>
              }
            />
          </div>
          <div className="text-text_dark_2 flex justify-end items-end gap-5 lg:gap-10 text-sm lg:text-base pe-6 lg:pe-10">
            <Link className="uppercase " to="/#coming-soon">
              Jewels
            </Link>
            <Link className="uppercase" to="/#coming-soon">
              Bridal
            </Link>
            <Link className="uppercase" to="/#coming-soon">
              collections
            </Link>
          </div>
        </div>
        <div className="">
          <div className="flex justify-around">
            <Link className="uppercase object-cover" to="/">
              <img src={DGlogo} alt="logo" className="h-[100px] w-[100px] object-contain" />
            </Link>
          </div>
        </div>
        <div className="flex flex-col justify-between">
          <div className="p-2 px-4 flex justify-end gap-6">
            <Link
              to={`${user?.customer ? "/profile-details#your-account" : "/login"}`}
            ><img className="h-[30px] w-[30px] p-1 object-contain" src={userIcon} alt="Cart" /></Link >
            <Link to="/#coming-soon"><img className="h-[30px] w-[30px] p-1 object-contain" src={cart_icon} alt="Cart" /></Link >
            <Link to="/favourites"><img className="h-[30px] w-[30px] p-1 object-contain" src={wishlist_icon} alt="Wishlist" /></Link >
            <Link to={ADDRESS_LINK} target="_blank"><img className="h-[30px] w-[30px] p-1 object-contain" src={locationIcon} alt="Address" /></Link >
          </div>
          <div className="text-text_dark_2 flex justify-start items-end gap-5 lg:gap-10 text-sm lg:text-base ps-6 lg:ps-10">
            <Link className="uppercase" to="/aboutus">
              about us
            </Link>
            <Link className="uppercase" to="/#coming-soon">
              best sellers
            </Link>
            <Link className="uppercase" to="/#coming-soon">
              news/events
            </Link>
          </div>
        </div>
      </div>
      {/* --------------------------------------------------------------------------------- */}
      {/* <div className="bg-secondary">
        <div className="flex justify-center py-2 font-[Cormorant]">
          <div className="flex justify-around flex-grow items-end">
            <Link className="uppercase " to="/">
              Jewels
            </Link>
            <Link className="uppercase" to="/">
              Bridal
            </Link>
            <Link className="uppercase" to="/">
              collections
            </Link>
          </div>
          <div className="flex justify-around">
            <Link className="uppercase" to="/">
              <img src={DGlogo} alt="logo" className="" />
            </Link>
          </div>
          <div className="flex justify-around flex-grow  items-end ">
            <Link className="uppercase" to="/aboutus">
              about us
            </Link>
            <Link className="uppercase" to="/">
              best sellers
            </Link>
            <Link className="uppercase" to="/">
              news/events
            </Link>
          </div>
        </div>
      </div> */}
    </div>
  )
}

const DagaHeaderMobile = ({
  user,
  searchProductsVal,
  searchProductsHandle,
  searchNavigationHandle
}) => {

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="relative md:hidden">
      <div className="bg-secondary grid grid-rows-2 px-4 py-2 max-w-[767px]">
        <div className="grid grid-cols-[minmax(0,_1fr)_55px_minmax(0,_1fr)]">
          <div className="flex">
            <button onClick={toggleSidebar} className="flex items-center">
              <img src={menu_icon} alt="" className="h-8 w-8" />
            </button>
          </div>
          <div className="">
            <img src={DGlogo} alt="menu" className="h-[65px] w-[55px]" />
          </div>
          <div className="flex gap-4 justify-end items-center">
            <Link to={`${user?.customer ? "/profile-details#your-account" : "/login"}`}>
              <img src={userIcon} alt="" className="h-7 w-7" />
            </Link>
            <Link to="/#coming-soon">
              <img src={cart_icon} alt="" className="h-7 w-7" />
            </Link>
          </div>
        </div>
        <div className="relative flex justify-center items-center w-full -mb-3">
          <INPUT
            isNotValidate
            placeHolder={"Search ..."}
            className={"py-0 px-0 w-full  border-b"}
            mainClass={"p-0 m-0 w-[200px] "}
            val={searchProductsVal}
            onChange={(s) => searchProductsHandle(s.target?.value)}
            onKeyDown={(e) => searchNavigationHandle(e)}
            lComp={
              <div className="me-1">
                <img src={search_icon} alt="" className="p-1 h-[30px] w-[30px]" />
              </div>
            }
          />
        </div>
      </div>

      <div
        className={`fixed top-0 left-0 h-full w-64 bg-secondary transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out z-999999`}
      >
        <button
          onClick={toggleSidebar}
          className="absolute top-4 right-4 text-primary"
        >
          <img src={close_square_icon} alt="" className="h-9 w-9" />
        </button>
        <nav className="mt-16 z-999999 px-2 ">
          <ul className="space-y-4">
            <li className="border-b-2 border-b-primary">
              <Link to="/#coming-soon" className="block px-2 pb-2 text-primary " onClick={toggleSidebar}>
                Jewels
              </Link>
            </li>
            <li className="border-b-2 border-b-primary">
              <Link to="/#coming-soon" className="block px-2 pb-2 text-primary" onClick={toggleSidebar}>
                Bridal
              </Link>
            </li>
            <li className="border-b-2 border-b-primary">
              <Link to="/#coming-soon" className="block px-2 pb-2 text-primary" onClick={toggleSidebar}>
                Collections
              </Link>
            </li>
            <li className="border-b-2 border-b-primary">
              <Link to="/aboutus" className="block px-2 pb-2 text-primary" onClick={toggleSidebar}>
                About Us
              </Link>
            </li>
            <li className="border-b-2 border-b-primary">
              <Link to="/#coming-soon" className="block px-2 pb-2 text-primary" onClick={toggleSidebar}>
                Bestsellers
              </Link>
            </li>
            <li className="border-b-2 border-b-primary">
              <Link to="/#coming-soon" className="block px-2 pb-2 text-primary" onClick={toggleSidebar}>
                News/Events
              </Link>
            </li>
            <li className="border-b-2 border-b-primary">
              <Link to="/favourites" className=" flex flex-row px-1 pb-2 text-primary items-center gap-1" onClick={toggleSidebar}>
                <img className="h-[30px] w-[30px] p-1 object-contain border-r border-r-primary" src={wishlist_icon} alt="Wishlist" />
                <span>Wishlist</span>
              </Link >
            </li>

          </ul>
        </nav>
      </div>

      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-5 z-99999"
          onClick={toggleSidebar}
        ></div>
      )}
    </div>
  );
};